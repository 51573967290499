import { getDefaultCompilerOptions } from "typescript";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

const bindPart = "bindPart";
const unbind = "unbind";

const hideModestiesRules = [
  {
    type: "hide",
    node: ["modesty_wood_left", "modesty_perforated_left"],
  },
];
export const ConfData3 = Object.freeze([
  //basically for linear workstations
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Two Seater",
            size: 2,
            "Table Top Options": "Plain(CS)",
            Category: "C_Cluster",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/ccluster/sharing/2.png",
            productId: "669fd38b8da89748811ccd4a",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            enableOptions: [],
            rules: [
              {
                type: hide,
                node: [
                  "glass_end",
                  "fabric_end",
                ],
              },
            ],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
              "Intermediate Panels (S)",
              "Intermediate Panel Height(S)"
            ],
            tableTop: [],
            intermediateLegs: [],
          },

          {
            name: "Four Seater",
            size: 4,
            "Table Top Options": "Plain(CS)",
            Length: 1200,
            Depth: 600,
            Category: "C_Cluster",
            thumbnail: "assets/system/thumbnails/ccluster/sharing/4.png",
            productId: "6662f47696f4e6a375413381",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            enableOptions: [],
            rules: [
              {
                type: hide,
                node: [
                  "prelamleft",
                  "prelamright",
                  "metalmodestyleft",
                  "metalmodestyright",
                  "glass_end",
                  "fabric_end",
                ],
              },
            ],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
              "Intermediate Panels (S)",
              "Intermediate Panel Height(S)",
            ],
            tableTop: [],
            intermediateLegs: [],
          },

          {
            name: "Six Seater",
            size: 6,
            "Table Top Options": "Plain(CS)",
            Length: 1200,
            Depth: 600,
            Category: "C_Cluster",
            thumbnail: "assets/system/thumbnails/ccluster/sharing/6.png",
            productId: "669dd4b21d82bf9fec53f4c3",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            enableOptions: [],
            rules: [
              {
                type: hide,
                node: [
                  "prelamleft",
                  "prelamright",
                  "metalmodestyleft",
                  "metalmodestyright",
                  "glass_end",
                  "fabric_end",
                ],
              },
            ],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            tableTop: [],
            intermediateLegs: [],
          },

          {
            name: "Eight Seater",
            size: 8,
            "Table Top Options": "Plain(CS)",
            Length: 1200,
            Depth: 600,
            Category: "C_Cluster",
            thumbnail: "assets/system/thumbnails/ccluster/sharing/8.png",
            productId: "669fc9242e4d5ffd24106ef3",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            rules: [
              {
                type: hide,
                node: [
                  "prelamleft",
                  "prelamright",
                  "metalmodestyleft",
                  "metalmodestyright",
                  "glass_end",
                  "fabric_end",
                ],
              },
            ],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "Ten Seater",
            size: 10,
            "Table Top Options": "Plain(CS)",
            Length: 1200,
            Depth: 600,
            Category: "C_Cluster",
            thumbnail: "assets/system/thumbnails/ccluster/sharing/10.png",
            productId: "66a0e70c59a4d9dd61e58566",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            enableOptions: [],
            rules: [
              {
                type: hide,
                node: [
                  "prelamleft",
                  "prelamright",
                  "metalmodestyleft",
                  "metalmodestyright",
                  "glass_end",
                  "fabric_end",
                ],
              },
            ],
            tableTop: [],
            intermediateLegs: [],
          },

          {
            name: "Twelve Seater",
            size: 12,
            "Table Top Options": "Plain(CS)",
            Length: 1200,
            Depth: 600,
            Category: "C_Cluster",
            thumbnail: "assets/system/thumbnails/ccluster/sharing/12.png",
            productId: "66a07eb6e1258c38161ea8dd",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            rules: [
              {
                type: hide,
                node: [
                  "prelamleft",
                  "prelamright",
                  "metalmodestyleft",
                  "metalmodestyright",
                  "glass_end",
                  "fabric_end",
                ],
              },
            ],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },

          // {
          //   name: "Fourteen Seater",
          //   size: 14,
          //   "Table Top Options": "Plain(CS)",
          //   Length: 1200,
          //   Depth: 600,
          //   Category: "C_Cluster",
          //   thumbnail: "assets/system/thumbnails/ccluster/sharing/14.png",
          //   productId: "66a0e16f389fe6a8676f4762",
          //   disableOptions: ["Mid Prelam", "Mid Metal"],
          //   disableFeatures: [
          //     "Aluminium Panel Options (S)",
          //     "Fabric Panel Accessories (S)",
          //     "End to End Panel Options (S)",
          //     "Privacy Panel Height(S)",
          //     "Return Panel Height(S)",
          //   ],
          //   enableOptions: [],
          //   tableTop: [],
          //   intermediateLegs: [],
          // },
          // {
          //   name: "Sixteen Seater",
          //   size: 16,
          //   "Table Top Options": "Plain(CS)",
          //   Length: 1200,
          //   Depth: 600,
          //   Category: "C_Cluster",
          //   thumbnail: "assets/system/thumbnails/ccluster/sharing/16.png",
          //   productId: "66a0e3a88e43c6ad69b1d532",
          //   disableOptions: ["Mid Prelam", "Mid Metal"],
          //   disableFeatures: [
          //     "Aluminium Panel Options (S)",
          //     "Fabric Panel Accessories (S)",
          //     "End to End Panel Options (S)",
          //     "Privacy Panel Height(S)",
          //     "Return Panel Height(S)",
          //   ],
          //   enableOptions: [],
          //   tableTop: [],
          //   intermediateLegs: [],
          // },
          // {
          //   name: "Eighteen Seater",
          //   size: 18,
          //   "Table Top Options": "Plain(CS)",
          //   Length: 1200,
          //   Depth: 600,
          //   Category: "C_Cluster",
          //   thumbnail: "assets/system/thumbnails/ccluster/sharing/18.png",
          //   productId: "66a117164a6a5cd1274d99a7",
          //   disableOptions: ["Mid Prelam", "Mid Metal"],
          //   disableFeatures: [
          //     "Aluminium Panel Options (S)",
          //     "Fabric Panel Accessories (S)",
          //     "End to End Panel Options (S)",
          //     "Privacy Panel Height(S)",
          //     "Return Panel Height(S)",
          //   ],
          //   enableOptions: [],
          //   tableTop: [],
          //   intermediateLegs: [],
          // },
          // {
          //   name: "Twenty Seater",
          //   size: 20,
          //   "Table Top Options": "Plain(CS)",
          //   Length: 1200,
          //   Depth: 600,
          //   Category: "C_Cluster",
          //   thumbnail: "assets/system/thumbnails/ccluster/sharing/20.png",
          //   productId: "66a111393d93339d29cb9ed8",
          //   disableOptions: ["Mid Prelam", "Mid Metal"],
          //   disableFeatures: [
          //     "Aluminium Panel Options (S)",
          //     "Fabric Panel Accessories (S)",
          //     "End to End Panel Options (S)",
          //     "Privacy Panel Height(S)",
          //     "Return Panel Height(S)",
          //   ],
          //   enableOptions: [],
          //   tableTop: [],
          //   intermediateLegs: [],
          // },
          // {
          //   name: "Twentytwo Seater",
          //   size: 22,
          //   "Table Top Options": "Plain(CS)",
          //   Length: 1200,
          //   Depth: 600,
          //   Category: "C_Cluster",
          //   thumbnail: "assets/system/thumbnails/ccluster/sharing/22.png",
          //   productId: "66a103331de11ddacd9ca92c",
          //   disableOptions: ["Mid Prelam", "Mid Metal"],
          //   disableFeatures: [
          //     "Aluminium Panel Options (S)",
          //     "Fabric Panel Accessories (S)",
          //     "End to End Panel Options (S)",
          //     "Privacy Panel Height(S)",
          //     "Return Panel Height(S)",
          //   ],
          //   enableOptions: [],
          //   tableTop: [],
          //   intermediateLegs: [],
          // },

          // {
          //   name: "Twenty Four Seater",
          //   size: 24,
          //   "Table Top Options": "Plain(CS)",
          //   Length1: 1200,
          //   Depth1: 600,
          //   Category: "C_Cluster",
          //   thumbnail: "assets/system/thumbnails/ccluster/sharing/24.png",
          //   productId: "66a0fc34451fc84372b731ab",
          //   disableOptions: ["Mid Prelam", "Mid Metal"],
          //   disableFeatures: [
          //     "Aluminium Panel Options (S)",
          //     "Fabric Panel Accessories (S)",
          //     "End to End Panel Options (S)",
          //     "Privacy Panel Height(S)",
          //     "Return Panel Height(S)",
          //   ],
          //   enableOptions: [],
          //   tableTop: [],
          //   intermediateLegs: [],
          // },
        ],
      },

      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "One Seater",
            size: 1,
            "Table Top Options": "Plain(CS)",
            Length: 1200,
            Depth: 600,
            Category: "C_Cluster",
            thumbnail: "assets/system/thumbnails/ccluster/nonsharing/1.png",
            productId: "66621f91be74e82ed91fc099",
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: ["intermediate_wireentry"],
            rules: [
              {
                type: "hide",
                node: ["fabric_end", "glass_end"],
              },
            ],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
              "Intermediate Panels (NS)",
              "Intermediate Panel Height(NS)"
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
          {
            name: "Two Seater",
            size: 2,
            "Table Top Options": "Plain(CS)",
            Length: 1200,
            Depth: 600,
            Category: "C_Cluster",
            thumbnail: "assets/system/thumbnails/ccluster/nonsharing/2.png",
            productId: "669f9597f74bef03058d7241",
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            rules: [
              {
                type: "hide",
                node: ["fabric_end", "glass_end"],
              },
            ],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
              "Intermediate Panels (NS)",
              "Intermediate Panel Height(NS)"
            ],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
          {
            name: "Three Seater",
            size: 3,
            "Table Top Options": "Plain(CS)",
            Length: 1200,
            Depth: 600,
            Category: "C_Cluster",
            thumbnail: "assets/system/thumbnails/ccluster/nonsharing/3.png",
            productId: "669fac78e4d7e0d3b9299a6e",
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rules: [
              {
                type: "hide",
                node: ["fabric_end", "glass_end"],
              },
            ],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
              "Intermediate Panels (NS)",
              "Intermediate Panel Height(NS)"
            ],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
          {
            name: "Four Seater",
            size: 4,
            "Table Top Options": "Plain(CS)",
            Length: 1200,
            Depth: 600,
            Category: "C_Cluster",
            thumbnail: "assets/system/thumbnails/ccluster/nonsharing/4.png",
            productId: "66a0825333ace41bc20f5538",
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
            rules: [
              {
                type: "hide",
                node: ["fabric_end", "glass_end"],
              },
              
            ],
          },
          {
            name: "Five Seater",
            size: 5,
            "Table Top Options": "Plain(CS)",
            Length: 1200,
            Depth: 600,
            Category: "C_Cluster",
            thumbnail: "assets/system/thumbnails/ccluster/nonsharing/5.png",
            productId: "66a1d29bb89e8a0c937dda68",
            tableTop: ["table_top_nonshared"],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
            rules: [
              {
                type: "hide",
                node: ["fabric_end", "glass_end"],
              },
              
            ],
          },
          {
            name: "Six Seater",
            size: 6,
            "Table Top Options": "Plain(CS)",
            Length: 1200,
            Depth: 600,
            Category: "C_Cluster",
            thumbnail: "assets/system/thumbnails/ccluster/nonsharing/6.png",
            productId: "66a1c9412580f335fa756320",
            tableTop: ["table_top_nonshared"],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
            rules: [
              {
                type: "hide",
                node: ["fabric_end", "glass_end"],
              },
              
            ],
          },
          // {
          //   name: "Seven Seater",
          //   size: 7,
          //   "Table Top Options": "Plain(CNS)",
          //   Length: 1200,
          //   Depth: 600,
          //   Category: "C_Cluster",
          //   thumbnail: "assets/system/thumbnails/ccluster/nonsharing/7.png",
          //   productId: "66a12a59d38560ee0ac22def",
          //   tableTop: ["table_top_nonshared"],
          //   disableFeatures: [
          //     "Aluminium Panel Options (NS)",
          //     "Fabric Panel Accessories (NS)",
          //     "End to End Panel Options (NS)",
          //     "Privacy Panel Height(NS)",
          //     "Return Panel Height(NS)",
          //   ],
          //   leftLegs: ["left_leg"],
          //   rightLegs: ["right_leg"],
          //   intermediateLegs: [
          //     "intermediate_wireentry",
          //     "intermediate_wireentry",
          //   ],
          //   disableOptions: ["Mid Prelam", "Mid Metal"],
          // },
          // {
          //   name: "Eight Seater",
          //   size: 8,
          //   "Table Top Options": "Plain(CNS)",
          //   Length: 1200,
          //   Depth: 600,
          //   Category: "C_Cluster",
          //   thumbnail: "assets/system/thumbnails/ccluster/nonsharing/8.png",
          //   productId: "66a11e4e2a7ebad471320c63",
          //   tableTop: ["table_top_nonshared"],
          //   disableFeatures: [
          //     "Aluminium Panel Options (NS)",
          //     "Fabric Panel Accessories (NS)",
          //     "End to End Panel Options (NS)",
          //     "Privacy Panel Height(NS)",
          //     "Return Panel Height(NS)",
          //   ],
          //   leftLegs: ["left_leg"],
          //   rightLegs: ["right_leg"],
          //   intermediateLegs: [
          //     "intermediate_wireentry",
          //     "intermediate_wireentry",
          //   ],
          //   disableOptions: ["Mid Prelam", "Mid Metal"],
          // },
          // {
          //   name: "Nine Seater",
          //   size: 9,
          //   "Table Top Options": "Plain(CNS)",
          //   Length: 1200,
          //   Depth: 600,
          //   Category: "C_Cluster",
          //   thumbnail: "assets/system/thumbnails/ccluster/nonsharing/9.png",
          //   productId: "66a1d88fc1cbac30a26f5932",
          //   tableTop: ["table_top_nonshared"],
          //   disableFeatures: [
          //     "Aluminium Panel Options (NS)",
          //     "Fabric Panel Accessories (NS)",
          //     "End to End Panel Options (NS)",
          //     "Privacy Panel Height(NS)",
          //     "Return Panel Height(NS)",
          //   ],
          //   leftLegs: ["left_leg"],
          //   rightLegs: ["right_leg"],
          //   intermediateLegs: [
          //     "intermediate_wireentry",
          //     "intermediate_wireentry",
          //   ],
          //   disableOptions: ["Mid Prelam", "Mid Metal"],
          // },
          // {
          //   name: "Ten Seater",
          //   size: 10,
          //   "Table Top Options": "Plain(CNS)",
          //   Length: 1200,
          //   Depth: 600,
          //   Category: "C_Cluster",
          //   thumbnail: "assets/system/thumbnails/ccluster/nonsharing/10.png",
          //   productId: "66a1d83d8a888460a3957da6",
          //   tableTop: ["table_top_nonshared"],
          //   disableFeatures: [
          //     "Aluminium Panel Options (NS)",
          //     "Fabric Panel Accessories (NS)",
          //     "End to End Panel Options (NS)",
          //     "Privacy Panel Height(NS)",
          //     "Return Panel Height(NS)",
          //   ],
          //   leftLegs: ["left_leg"],
          //   rightLegs: ["right_leg"],
          //   intermediateLegs: [
          //     "intermediate_wireentry",
          //     "intermediate_wireentry",
          //   ],
          //   disableOptions: ["Mid Prelam", "Mid Metal"],
          // },
          // {
          //   name: "Eleven Seater",
          //   size: 11,
          //   "Table Top Options": "Plain(CNS)",
          //   Length: 1200,
          //   Depth: 600,
          //   Category: "C_Cluster",
          //   thumbnail: "assets/system/thumbnails/ccluster/nonsharing/11.png",
          //   productId: "66a1d7477fbc9810757c5791",
          //   tableTop: ["table_top_nonshared"],
          //   disableFeatures: [
          //     "Aluminium Panel Options (NS)",
          //     "Fabric Panel Accessories (NS)",
          //     "End to End Panel Options (NS)",
          //     "Privacy Panel Height(NS)",
          //     "Return Panel Height(NS)",
          //   ],
          //   leftLegs: ["left_leg"],
          //   rightLegs: ["right_leg"],
          //   intermediateLegs: [
          //     "intermediate_wireentry",
          //     "intermediate_wireentry",
          //   ],
          //   disableOptions: ["Mid Prelam", "Mid Metal"],
          // },
          // {
          //   name: "Twelve Seater",
          //   size: 12,
          //   Category: "C_Cluster",
          //   "Table Top Options": "Plain(CNS)",
          //   Length: 1200,
          //   Depth: 600,
          //   thumbnail: "assets/system/thumbnails/ccluster/nonsharing/12.png",
          //   productId: "66a1d44efeaa44fd151620ff",
          //   disableFeatures: [
          //     "Aluminium Panel Options (NS)",
          //     "Fabric Panel Accessories (NS)",
          //     "End to End Panel Options (NS)",
          //     "Privacy Panel Height(NS)",
          //     "Return Panel Height(NS)",
          //   ],
          //   tableTop: ["table_top_nonshared"],
          //   leftLegs: ["left_leg"],
          //   rightLegs: ["right_leg"],
          //   intermediateLegs: [
          //     "intermediate_wireentry",
          //     "intermediate_wireentry",
          //   ],
          //   disableOptions: ["Mid Prelam", "Mid Metal"],
          // },
        ],
      },
    ],
  },
  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Length2",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "500 mm",
                value: 500,
              },
              {
                name: "600 mm",
                value: 600,
              },
            ],
          },
          {
            name: "Depth2",
            required: false,
            dropDown: true,
            options: [
              {
                name: "500 mm",
                value: 500,
              },
              {
                name: "600 mm",
                value: 600,
              },
            ],
          },
          // {
          //   name: "Height",
          //   required: false,
          //   dropDown: true,
          //   options: [
          //     {
          //       name: "1050 mm",
          //       value: 1050,
          //     },
          //     {
          //       name: "1200 mm",
          //       value: 1200,
          //     },
          //   ],
          // },
        ],
      },
      {
        name: "NON SHARING",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Length2",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "500 mm",
                value: 500,
              },
              {
                name: "600 mm",
                value: 600,
              },
            ],
          },
          {
            name: "Depth2",
            required: false,
            dropDown: true,
            options: [
              {
                name: "500 mm",
                value: 500,
              },
              {
                name: "600 mm",
                value: 600,
              },
            ],
          },
          // {
          //   name: "Height",
          //   required: false,
          //   dropDown: true,
          //   options: [
          //     {
          //       name: "1050 mm",
          //       value: 1050,
          //     },
          //     {
          //       name: "1200 mm",
          //       value: 1200,
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
  {
    name: "Legs",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Legs",
            required: false,
            options: [
              {
                name: "Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                // enableFeatures: ["Color Options"],
                enableFeatures: ["Eco Size Options"],

                rules: [
                  {
                    type: "replacePart",
                    node: ["l1", "l3", "l5", "l7", "l9", "l11"],
                    // pid: "66387f0930180877c5875bb1",
                    pid: "https://content.helloviewer.io/uploads/43fc33a5-856f-46f2-93be-5f971a1d3a08/18_6_2024/1721313592542/Ecol1.glb",
                  },
                  {
                    type: "replacePart",
                    node: ["l2", "l4", "l6", "l8", "l10", "l12"],
                    pid: "https://content.helloviewer.io/uploads/0f78dd68-0f1d-4e9d-a0a3-361e22a813da/18_6_2024/1721313632739/Ecol2.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "r1",
                    pid: "https://content.helloviewer.io/uploads/819a33bb-12f3-47ba-9ec1-4a512b35143c/11_5_2024/1718101501387/Ecor1.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "r2",
                    pid: "https://content.helloviewer.io/uploads/b81890e1-364b-4447-a00d-b8c43f486cd4/11_5_2024/1718101523151/Ecor2.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: ["t1", "t11", "t12", "t13", "t14"],
                    pid: "https://content.helloviewer.io/uploads/ab3b6f1a-d038-46ed-9c8c-4b58bba60c0a/22_6_2024/1721622905716/Ecot1.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: ["t2", "t22", "t23", "t24", "t25"],
                    pid: "https://content.helloviewer.io/uploads/2d433e36-6777-43d5-b451-d89fce6a1c0d/22_6_2024/1721622945145/Ecot2.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "t3",
                    pid: "https://content.helloviewer.io/uploads/b5602e34-8825-48d9-8c0f-aa00ceaa026c/24_6_2024/1721789947601/Ecot3.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },

                  ...hideModestiesRules,
                  {
                    type: "hide",
                    node: [
                      "metalr1",
                      "metalr2",
                      "prelamr1",
                      "prelamr2",
                      "metalt1",
                      "metalt2",
                      "metalt3",
                      "prelamt1",
                      "prelamt2",
                      "prelamt3",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "gorment2@work",
                      "FlipUp@work",
                      "ioconnect",
                    ],
                  },
                ],
              },
              {
                name: "NOVA",
                thumbnail: "assets/system/thumbnails/Parts/nova.png",
                // enableFeatures: ["Color Options"],
                disableFeatures: ["Eco Size Options"],

                rules: [
                  {
                    type: "replacePart",
                    node: ["l1", "l3", "l5", "l7", "l9", "l11"],
                    // pid: "66387f0930180877c5875bb1",
                    pid: "https://content.helloviewer.io/uploads/46d2c864-f2cc-4d9a-adc0-a1a09b9a0856/25_5_2024/1719298222156/L1noah.glb",
                  },
                  {
                    type: "replacePart",
                    node: ["l2", "l4", "l6", "l8", "l10", "l12"],
                    pid: "https://content.helloviewer.io/uploads/6c053e5a-35e3-4a7d-bcd1-d252f2ee15a5/25_5_2024/1719298276973/L2noah.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "r1",
                    pid: "https://content.helloviewer.io/uploads/8a47459e-392b-47f7-b57b-e788e464257d/25_5_2024/1719296712832/r1noah.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "r2",
                    pid: "https://content.helloviewer.io/uploads/9be199ab-d908-41bf-9962-3413f6f995c8/25_5_2024/1719298666394/r2noah.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: ["t1", "t11", "t12", "t13", "t14"],
                    pid: "https://content.helloviewer.io/uploads/f9413818-0768-44ea-a101-8c8b73430636/23_6_2024/1721715802114/noaht1.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: ["t2", "t22", "t23", "t24", "t25"],
                    pid: "https://content.helloviewer.io/uploads/f88a87f0-3e75-45f1-8776-5a5f58363cf8/23_6_2024/1721715837247/noaht2.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "t3",
                    pid: "https://content.helloviewer.io/uploads/e5108696-6684-4b67-a191-319a0e96fe70/24_6_2024/1721790433812/noaht3.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "hide",
                    node: [
                      "metalr1",
                      "metalr2",
                      "prelamr1",
                      "prelamr2",
                      "metalt1",
                      "metalt2",
                      "prelamt1",
                      "prelamt2",
                      "workconnect",
                      "gorment2@work",
                      "ioconnect",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                  {
                    type: show,
                    node: ["connecter"],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "@work",
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                // enableFeatures: ["Color Options"],
                disableFeatures: ["Eco Size Options"],

                rules: [
                  {
                    type: "replacePart",
                    node: ["l1", "l3", "l5", "l7", "l9", "l11"],
                    // pid: "66387f0930180877c5875bb1",
                    pid: "https://content.helloviewer.io/uploads/683089d9-787e-49bd-9b13-bd260f665c46/18_6_2024/1721308626509/l1-work.glb",
                  },
                  {
                    type: "replacePart",
                    node: ["l2", "l4", "l6", "l8", "l10", "l12"],
                    pid: "https://content.helloviewer.io/uploads/013371a0-4336-44cb-88a9-f0726b783ddf/23_6_2024/1721718882950/l2-work.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "r1",
                    pid: "https://content.helloviewer.io/uploads/4ec4e7bc-c14c-4e8f-aa0c-ce6237c059dc/18_6_2024/1721308521694/r1-work.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "r2",
                    pid: "https://content.helloviewer.io/uploads/b2dc91e9-d748-461c-886b-cc5591576dde/23_6_2024/1721718785160/r2-work.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: ["t1", "t11", "t12", "t13", "t14"],
                    pid: "https://content.helloviewer.io/uploads/c3e0012b-5478-4791-baaa-e7e0bee10c7b/23_6_2024/1721716893962/-workt1.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: ["t2", "t22", "t23", "t24", "t25"],
                    pid: "https://content.helloviewer.io/uploads/a1be5967-6b0a-42ea-aec5-f66a7bf0c6a9/23_6_2024/1721716929490/-workt2.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "t3",
                    pid: "https://content.helloviewer.io/uploads/0c830575-364f-49c4-a847-a887b1de452e/24_6_2024/1721790982728/-workt3.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "hide",
                    node: [
                      "metalr1",
                      "metalr2",
                      "prelamr1",
                      "prelamr2",
                      "metalt1",
                      "metalt2",
                      "prelamt1",
                      "prelamt2",
                      "workconnect",
                      "connecter",
                      "ioconnect",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                  {
                    type: "show",
                    node: ["workconnect"],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "IO",
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                // enableFeatures: ["Color Options"],
                disableFeatures: ["Eco Size Options"],

                rules: [
                  {
                    type: "replacePart",
                    node: ["l1", "l3", "l5", "l7", "l9", "l11"],
                    // pid: "66387f0930180877c5875bb1",
                    pid: "https://content.helloviewer.io/uploads/1b6066de-b1ae-4513-a971-019b131408e0/18_6_2024/1721309299260/l1io.glb",
                  },
                  {
                    type: "replacePart",
                    node: ["l2", "l4", "l6", "l8", "l10", "l12"],
                    pid: "https://content.helloviewer.io/uploads/82712ad6-f447-4799-a644-dfa07999800a/18_6_2024/1721309342096/l2io.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "r1",
                    pid: "https://content.helloviewer.io/uploads/39412fdf-20a0-4b46-b8b8-b79d65737677/18_6_2024/1721309171749/r1io.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "r2",
                    pid: "https://content.helloviewer.io/uploads/4f52d41c-144d-47ab-b5cf-c14dbbbd231a/18_6_2024/1721309217269/r2io.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: ["t1", "t11", "t12", "t13", "t14"],
                    pid: "https://content.helloviewer.io/uploads/877f3a9a-f417-4adf-aa79-f258b31b47f7/23_6_2024/1721717664684/iot1.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: ["t2", "t22", "t23", "t24", "t25"],
                    pid: "https://content.helloviewer.io/uploads/3ba54a9c-ff34-44bb-9a56-fbdf5a98f027/23_6_2024/1721717705038/iot2.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "t3",
                    pid: "https://content.helloviewer.io/uploads/1415d9f5-cf7a-42dc-9953-8880aa107270/24_6_2024/1721790837327/iot3.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "hide",
                    node: [
                      "metalr1",
                      "metalr2",
                      "prelamr1",
                      "prelamr2",
                      "metalt1",
                      "metalt2",
                      "prelamt1",
                      "prelamt2",
                      "connecter",
                      "workconnect",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                  {
                    type: "show",
                    node: ["ioconnect"],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "Scope",
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                disableFeatures: ["Eco Size Options"],

                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: ["l1", "l3", "l5", "l7", "l9", "l11"],
                    // pid: "66387f0930180877c5875bb1",
                    pid: "https://content.helloviewer.io/uploads/4820572e-c3e8-4c36-b071-ab7fe4114fee/25_5_2024/1719299339367/l1scope.glb",
                  },
                  {
                    type: "replacePart",
                    node: ["l2", "l4", "l6", "l8", "l10", "l12"],
                    pid: "https://content.helloviewer.io/uploads/fd732136-6233-4a60-a9c0-a1e267cd5061/25_5_2024/1719299382719/l2scope.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "r1",
                    pid: "https://content.helloviewer.io/uploads/3f856d65-f81f-4f46-99b6-32c76dee355d/25_5_2024/1719299205080/r1scope.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "r2",
                    pid: "https://content.helloviewer.io/uploads/fee8435f-44ef-4326-8156-bf9a63751fa1/25_5_2024/1719299269363/r2scope.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: ["t1", "t11", "t12", "t13", "t14"],
                    pid: "https://content.helloviewer.io/uploads/d56fba27-51ae-4f84-9b9f-e72c77112e7d/23_6_2024/1721716273266/scopet1.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: ["t2", "t22", "t23", "t24", "t25"],
                    pid: "https://content.helloviewer.io/uploads/f9d975dd-4901-4577-8024-5062b7c2caa1/23_6_2024/1721716304813/scopet2.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "t3",
                    pid: "https://content.helloviewer.io/uploads/240f9d08-6ddc-4f8f-9f69-9e990649f8d4/24_6_2024/1721790588239/scopet3.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "hide",
                    node: [
                      "metalr1",
                      "metalr2",
                      "prelamr1",
                      "prelamr2",
                      "metalt1",
                      "metalt2",
                      "prelamt1",
                      "prelamt2",
                      "workconnect",
                      "gorment2@work",
                      "ioconnect",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                  {
                    type: show,
                    node: ["connecter"],
                  },
                  ...hideModestiesRules,
                ],
              },
            ],
          },
          {
            name: "Eco Size Options",
            required: false,
            options: [
              {
                name: "40 mm",
              },
              {
                name: "50 mm",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Legs",
            required: false,
            options: [
              {
                name: "Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                // enableFeatures: ["Color Options"],
                enableFeatures: ["Eco Size Options"],

                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    // pid: "66387f0930180877c5875bb1",
                    pid: "https://content.helloviewer.io/uploads/8df0bdd8-cdfa-4c18-a96f-fe4eaaaa8820/21_6_2024/1721571533987/ecoleftglb.glb",
                  },
                  {
                    type: "replacePart",
                    node: ["right_leg", "right_leg1"],
                    pid: "https://content.helloviewer.io/uploads/0f877ef7-610e-4032-b5e3-479bc32221d6/21_6_2024/1721571406394/ecoright.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: ["t2", "t21", "t22", "t23", "t24"],
                    pid: "https://content.helloviewer.io/uploads/2d433e36-6777-43d5-b451-d89fce6a1c0d/22_6_2024/1721622945145/Ecot2.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalt2",

                      "prelamt1",
                      "prelamt2",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "gorment2@work",
                      "FlipUp@work",
                      "ioconnect",
                    ],
                  },

                  ...hideModestiesRules,
                ],
              },
              {
                name: "Noah",
                thumbnail: "assets/system/thumbnails/Parts/nova.png",
                // enableFeatures: ["Color Options"],
                disableFeatures: ["Eco Size Options"],

                rules: [
                  {
                    type: "replacePart",
                    node: ["right_leg", "right_leg1"],
                    pid: "https://content.helloviewer.io/uploads/637ad8f6-dbbb-4c55-9a98-968150459001/21_6_2024/1721572330780/noahright.glb", //"6638ca9ccb40de82b2da90bb",//
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/b2ffdec5-1a2d-4c0c-ad73-39f2e1625a58/21_6_2024/1721572169660/noahleft.glb", //"6638e25215077125003fdfc9",//
                  },
                  {
                    type: "replacePart",
                    node: ["t2", "t21", "t22", "t23", "t24"],
                    pid: "https://content.helloviewer.io/uploads/f88a87f0-3e75-45f1-8776-5a5f58363cf8/23_6_2024/1721715837247/noaht2.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "gorment2@work",
                      "FlipUp@work",
                      "ioconnect",
                    ],
                  },

                  ...hideModestiesRules,
                ],
              },
              {
                name: "@work",
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                // enableFeatures: ["Color Options"],
                disableFeatures: ["Eco Size Options"],

                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/10165354-497e-4ae0-9277-5f30daa7cb24/21_6_2024/1721575387216/-workleft.glb",
                  },
                  {
                    type: "replacePart",
                    node: ["right_leg", "right_leg1"],
                    pid: "https://content.helloviewer.io/uploads/4147bb6b-de6e-4696-b153-408852844389/21_6_2024/1721575334782/-workright.glb",
                  },
                  {
                    type: "replacePart",
                    node: ["t2", "t21", "t22", "t23", "t24"],
                    pid: "https://content.helloviewer.io/uploads/a1be5967-6b0a-42ea-aec5-f66a7bf0c6a9/23_6_2024/1721716929490/-workt2.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                      "ioconnect",
                      "workconnect",
                    ],
                  },
                  {
                    type: "show",
                    node: ["workconnect"],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "IO",
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                // enableFeatures: ["Color Options"],
                disableFeatures: ["Eco Size Options"],

                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/cb187cc0-2418-4d9a-b27b-2f575c4b8958/21_6_2024/1721574885441/ioleft.glb",
                  },
                  {
                    type: "replacePart",
                    node: ["right_leg", "right_leg1"],
                    pid: "https://content.helloviewer.io/uploads/f9e9ba92-1304-4569-bde3-a4f3d7ad2d04/21_6_2024/1721574802022/ioright.glb",
                  },
                  {
                    type: "replacePart",
                    node: ["t2", "t21", "t22", "t23", "t24"],
                    pid: "https://content.helloviewer.io/uploads/3ba54a9c-ff34-44bb-9a56-fbdf5a98f027/23_6_2024/1721717705038/iot2.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "t2",
                    pid: "https://content.helloviewer.io/uploads/3ba54a9c-ff34-44bb-9a56-fbdf5a98f027/23_6_2024/1721717705038/iot2.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                      "workconnect",
                    ],
                  },
                  {
                    type: "show",
                    node: ["ioconnect"],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "Scope",
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                disableFeatures: ["Eco Size Options"],

                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/22cfa231-9c7b-4ee5-823b-59cb389c771f/21_6_2024/1721572735663/scopeleft.glb",
                  },
                  {
                    type: "replacePart",
                    node: ["right_leg", "right_leg1"],
                    pid: "https://content.helloviewer.io/uploads/82c6dd50-1717-493a-abbf-082a32b27861/21_6_2024/1721572698026/scoperight.glb",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "replacePart",
                    node: ["t2", "t21", "t22", "t23", "t24"],
                    pid: "https://content.helloviewer.io/uploads/f9d975dd-4901-4577-8024-5062b7c2caa1/23_6_2024/1721716304813/scopet2.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "gorment2@work",
                      "ioconnect",
                    ],
                  },
                  {
                    type: "hide",
                    node: ["FlipUp@work"],
                  },
                  ...hideModestiesRules,
                ],
              },
            ],
          },
          {
            name: "Eco Size Options",
            required: false,
            options: [
              {
                name: "40 mm",
              },
              {
                name: "50 mm",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Wire Management",
    multiSelect: true,
    optional: false,
    default: "FlipUp",
    options: [
      {
        name: "SHARING",
        required: false,

        options: [
          {
            name: "Raceway",
            required: false,

            thumbnail: "/assets/components/ColorsSharing.png",

            rules: [
              {
                type: hide,
                node: ["FlipUp", "gorment", "Raceway"],
              },
            ],
            options: [
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                // options: [...boxraceway],
                enableFeatures: ["Wire Management's Dimensions Sharing"],

                default: true,
                enableOptions: [
                  "Mid Prelam",
                  "Mid Metal",
                  "300 x 145",
                  "450 x 145",
                  "500 x 145",
                ],

                rules: [
                  {
                    type: show,

                    node: ["FlipUp", "mid_leg_cover"],
                  },
                  {
                    type: hide,

                    node: [
                      "Table_top",
                      "gorment2",
                      "gorment",
                      "gorment@work",
                      "mid_leg",
                      "gorment2@work",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Eco", "Noah", "Scope"],
                      },
                    ],
                    node: [
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "gorment@work",
                      "gorment2@work",
                    ],
                  },

                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["FlipUp", "gorment2@work", "connecter"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["FlipUp@work"],
                  },
                ],
              },

              {
                name: "Gromet with vertical raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                disaleFeatures: ["Wire Management's Dimensions Sharing"],
                disableOptions: [
                  "Mid Prelam",
                  "Mid Metal",
                  "300 x 145",
                  "450 x 145",
                  "500 x 145",
                ],
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment2",
                      "FlipUp@work",
                      "gorment@work",
                      "mid_leg_cover",
                    ],
                  },
                  {
                    type: show,
                    node: ["gorment", "Table_top", "mid_leg"],
                  },

                  {
                    type: hide,
                    node: [
                      "prelammid",
                      "prelammid@work",
                      "metalmodestymid",
                      "metalmodestymid@work",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment", "gorment2@work"],
                  },
                  {
                    type: applyMaterial,
                    node: ["GORMET"],
                    condition: [
                      {
                        feature: "Laminate",
                        values: [
                          "Mangfall Beech",
                          "Acacia",
                          "Urban Teak",
                          "SILVER GREY DIFFUSE",
                          "Cairo Walnut BaseColor",
                          "Maldau Acacia BaseColor",
                          "plain DefaultMaterial BaseColor",
                          "Thansau Maple BaseColor",
                        ],
                      },
                    ],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
                  },

                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment@work"],
                  },
                ],
              },
              {
                name: "Gromet With faceplate Cutting",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                disaleFeatures: ["Wire Management's Dimensions Sharing"],
                enableOptions: ["Mid Prelam", "Mid Metal"],
                disableOptions: ["300 x 145", "450 x 145", "500 x 145"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "FlipUp@work",
                      "gorment@work",
                      "mid_leg",
                    ],
                  },
                  {
                    type: show,
                    node: ["gorment2", "Table_top", "mid_leg_cover"],
                  },
                  {
                    type: show,
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: [
                      "gorment2",
                      "Flipup@work",
                      "gorment@work",
                      "connecter",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment2@work"],
                  },
                ],
              },
            ],
          },
          {
            name: "Wire Management's Dimensions Sharing",
            multiSelect: true,
            options: [
              {
                name: "300 x 145",
                condition: [
                  {
                    feature: "Raceway",
                    values: ["Flip Up"],
                  },
                ],
                required: false,
              },
              // {
              //   name: " 400 x 145mm ",
              //   required: false,
              // },
              {
                name: "450 x 145",
                condition: [
                  {
                    feature: "Raceway",
                    values: ["Flip Up"],
                  },
                ],
                required: false,
              },
              {
                name: "500 x 145",
                condition: [
                  {
                    feature: "Raceway",
                    values: ["Flip Up"],
                  },
                ],
                required: false,
              },
            ],
          },
        ],
      },

      {
        name: "NON SHARING",
        required: false,

        options: [
          {
            name: "Raceway",
            required: false,

            thumbnail: "/assets/components/ColorsSharing.png",

            rules: [
              {
                type: hide,
                node: ["FlipUp", "gorment", "Raceway"],
              },
            ],
            options: [
              // {
              //   name: "None",
              //   thumbnail: "/assets/components/none.jpg",
              //   // options: [...boxraceway],
              //   // enableFeatures: ["Main Modesty"],
              //   rules: [
              //     {
              //       type: hide,
              //       node: [
              //         "Flip Up",
              //         "Raceway",
              //         "gorment2",
              //         "gorment2@work",
              //         "gorment@work",
              //         "FlipUp@work",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["Table_top"],
              //     },
              //   ],
              // },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                enableFeatures: ["Wire Management's Dimensions Non Sharing"],
                default: true,
                enableOptions: [
                  "Mid Prelam",
                  "Mid Metal",
                  "300 x 145",
                  "450 x 145",
                  "500 x 145",
                ],

                rules: [
                  {
                    type: show,

                    node: ["FlipUp", "mid_leg_cover"],
                  },
                  {
                    type: hide,

                    node: [
                      "Table_top",
                      "gorment2",
                      "gorment",
                      "gorment@work",
                      "mid_leg",
                      "gorment2@work",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Eco", "Noah", "Scope"],
                      },
                    ],
                    node: [
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "gorment@work",
                      "gorment2@work",
                    ],
                  },

                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["FlipUp", "gorment2@work", "connecter"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["FlipUp@work"],
                  },
                ],
              },

              {
                name: "Gromet with vertical raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                disaleFeatures: ["Wire Management's Dimensions Non Sharing"],
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                disableOptions: [
                  "Mid Prelam",
                  "Mid Metal",
                  "300 x 145",
                  "450 x 145",
                  "500 x 145",
                ],
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment2",
                      "FlipUp@work",
                      "gorment@work",
                      "mid_leg_cover",
                    ],
                  },
                  {
                    type: show,
                    node: ["gorment", "Table_top", "mid_leg"],
                  },

                  {
                    type: hide,
                    node: [
                      "prelammid",
                      "prelammid@work",
                      "metalmodestymid",
                      "metalmodestymid@work",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment", "gorment2@work"],
                  },
                  {
                    type: applyMaterial,
                    node: ["GORMET"],
                    condition: [
                      {
                        feature: "Laminate",
                        values: [
                          "Mangfall Beech",
                          "Acacia",
                          "Urban Teak",
                          "SILVER GREY DIFFUSE",
                          "Cairo Walnut BaseColor",
                          "Maldau Acacia BaseColor",
                          "plain DefaultMaterial BaseColor",
                          "Thansau Maple BaseColor",
                        ],
                      },
                    ],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
                  },

                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment@work"],
                  },
                ],
              },
              {
                name: "Gromet With faceplate Cutting",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                disaleFeatures: ["Wire Management's Dimensions Non Sharing"],
                enableOptions: ["Mid Prelam", "Mid Metal"],
                disableOptions: ["300 x 145", "450 x 145", "500 x 145"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "FlipUp@work",
                      "gorment@work",
                      "mid_leg",
                    ],
                  },
                  {
                    type: show,
                    node: ["gorment2", "Table_top", "mid_leg_cover"],
                  },
                  {
                    type: show,
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: [
                      "gorment2",
                      "Flipup@work",
                      "gorment@work",
                      "connecter",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment2@work"],
                  },
                ],
              },
            ],
          },
          {
            name: "Wire Management's Dimensions Non Sharing",
            multiSelect: true,
            options: [
              {
                name: "300 x 145",
                condition: [
                  {
                    feature: "Raceway",
                    values: ["Flip Up"],
                  },
                ],
                required: false,
              },
              // {
              //   name: " 400 x 145mm ",
              //   required: false,
              // },
              {
                name: "450 x 145",
                condition: [
                  {
                    feature: "Raceway",
                    values: ["Flip Up"],
                  },
                ],
                required: false,
              },
              {
                name: "500 x 145",
                condition: [
                  {
                    feature: "Raceway",
                    values: ["Flip Up"],
                  },
                ],
                required: false,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Privacy",
    multiSelect: false,
    disableFeatures: ["Aluminium Panel Options", "Fabric Panel Accessories"],
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        disableFeatures: [
          "Aluminium Panel Options",
          "Fabric Panel Accessories",
          "End to End Panel Options",
        ],
        options: [
          {
            name: "Side Modesty (S)",
            nodeName: [
              "prelamleft",
              "metalmodestyleft",
              "prelamright",
              "metalmodestyright",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelammidside",
                      "prelammid@workside",
                      "metalmodestymid@workside",
                      "metalmodestyleftside",
                      "metalmodestyright",
                      "prelamright",
                    ],
                  },
                ],
              },
              {
                name: "Prelam",
                thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
                rules: [
                  {
                    type: "show",

                    node: ["prelammidside", "prelamright"],
                  },
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@workside",
                      "metalmodestyleftside",
                      "metalmodestyright",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["prelammidside"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["prelammid@workside"],
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "show",

                    node: ["metalmodestyleftside", "metalmodestyright"],
                  },
                  {
                    type: "hide",
                    node: [
                      "prelammid@workside",
                      "prelammidside",
                      "prelamright",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["metalmodestyleftside"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["metalmodestymid@workside"],
                  },
                ],
              },
            ],
          },
          {
            name: "Mid Modesty (S)",
            nodeName: [
              "prelammid",
              "metalmodestymid",
              "metalmodestymid@work",
              "prelammid@work",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelammid",
                      "metalmodestymid",
                      "metalmodestymid@work",
                      "prelammid@work",
                    ],
                  },
                ],
              },
              {
                name: "Mid Prelam",
                thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
                rules: [
                  {
                    type: "show",
                    node: ["prelammid"],
                  },
                  {
                    type: "hide",
                    node: ["metalmodestymid", "gorment"],
                  },
                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["prelammid", "metalmodestymid@work"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["prelammid@work"],
                  },
                ],
              },
              {
                name: "Mid Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "show",
                    node: ["metalmodestymid"],
                  },
                  {
                    type: "hide",
                    node: ["prelammid", "gorment"],
                  },
                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["prelammid@work", "metalmodestymid"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["metalmodestymid@work"],
                  },
                ],
              },
            ],
          },

          {
            name: "Privacy Panels (S)",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                disableFeatures: [
                  "Aluminium Panel Options (S)",
                  "Fabric Panel Accessories (S)",
                  "End to End Panel Options (S)",
                  "Privacy Panel Height(S)",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "Alluminiumname_board",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "aluminium",
                      "alumfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                disableOptions: ["300G", "300A", "450G", "450A", "300MF", "450MF"],
                enableOptions: ["300F", "450F"],
                disableFeatures: [
                  "Aluminium Panel Options (S)",
                  "End to End Panel Options (S)",
                ],
                enableFeatures: [
                  "Fabric Panel Accessories (S)",
                  "Privacy Panel Height(S)",
                ],

                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["fabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              {
                name: "Magnetic Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                disableOptions: ["300G", "300A", "450G", "450A", "300F", "450F"],
                enableOptions: ["300MF", "450MF"],
                disableFeatures: [
                  "Aluminium Panel Options (S)",
                  "End to End Panel Options (S)",
                ],
                enableFeatures: [
                  "Fabric Panel Accessories (S)",
                  "Privacy Panel Height(S)",
                ],

                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["fabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              {
                name: "Glass",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: ["300F", "300A", "450F", "450A","300MF", "450MF"],
                enableOptions: ["300G", "450G"],
                disableFeatures: [
                  "Aluminium Panel Options (S)",
                  "Fabric Panel Accessories (S)",
                  "End to End Panel Options (S)",
                ],
                enableFeatures: ["Privacy Panel Height(S)"],
                rules: [
                  {
                    type: "show",
                    node: ["glass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                disableOptions: ["300F", "300G", "450F", "450G", "300MF", "450MF"],
                enableOptions: ["300A", "450A"],
                //pid: "6122f35afe408b1279847325",
                enableFeatures: ["Privacy Panel Height(S)"],
                disableFeatures: [
                  "Fabric Panel Accessories (S)",
                  "End to End Panel Options (S)",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["aluminium", "alumfullfabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              // {
              //   name: "end to end panels",
              //   thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableFeatures: ["End to End Panel Options (S)"],
              //   disableFeatures: [
              //     "Fabric Panel Accessories (S)",
              //     "Aluminium Panel Options (S)",
              //   ],
              //   rules: [
              //     {
              //       type: replacePart,
              //       node: [
              //         "End1",
              //         "End2",
              //         "End3",
              //         "End4",
              //         "End5",
              //         "End6",
              //         "End7",
              //         "End8",
              //         "End9",
              //         "End10",
              //         "End11",
              //         "End12",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/68f3c295-7cee-4c0a-93ca-bd71fc652169/4_5_2024/1717499694262/end-to-end-panels-.glb",
              //     },
              //     {
              //       type: replacePart,
              //       condition: [
              //         {
              //           feature: "SHARING",
              //           values: ["Two Seater"],
              //         },
              //       ],
              //       node: [
              //         "End1",
              //         "End2",
              //         "End3",
              //         "End4",
              //         "End5",
              //         "End6",
              //         "End7",
              //         "End8",
              //         "End9",
              //         "End10",
              //         "End11",
              //         "End12",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/13e0c646-8b9c-4974-82a8-2f19c1fce3fc/24_5_2024/1719233596450/2seaterendtoendpane-.glb",
              //     },
              //     {
              //       type: hide,
              //       node: [
              //         "aluminium",
              //         "alumfullfabric",
              //         "fabric",
              //         "alumfullfabric",
              //         "glass",
              //         "alumglass",
              //         "fabric",
              //         "aluminium",
              //         "alumfullfabric",
              //         "alumfulllaminate",
              //         "alumhalfandhalf",
              //         "alumglass",
              //         "endfulllaminate008",
              //         "endglass008",
              //         "endhalfandhalf008",
              //         "endfulllaminate011",
              //         "endglassendhalfandhalf011",
              //         "endglass011",
              //         "endhalfandhalf011",
              //       ],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Privacy Panel Height(S)",
            multiSelect: true,
            options: [
              {
                name: "300F",
                required: false,
              },

              {
                name: "450F",
                required: false,
              },
              {
                name: "300MF",
                required: false,
              },

              {
                name: "450MF",
                required: false,
              },
              {
                name: "300G",
                required: false,
              },

              {
                name: "450G",
                required: false,
              },
              {
                name: "300A",
                enableFeatures: ["Aluminium Panel Options (S)"],
                enableOptions: [
                  "Aluminium Glass3",
                  "Full laminate3",
                  "Half Fabric half laminate3",
                  "Half Pin up half laminate3",
                  "Full Pin up3",
                  "Full Fabric3",
                ],
                disableOptions: [
                  "Aluminium Glass4",
                  "Full laminate4",
                  "Half Fabric half laminate4",
                  "Half Pin up half laminate4",
                  "Full Pin up4",
                  "Full Fabric4",
                ],
                required: false,
              },

              {
                name: "450A",
                disableOptions: [
                  "Aluminium Glass3",
                  "Full laminate3",
                  "Half Fabric half laminate3",
                  "Half Pin up half laminate3",
                  "Full Pin up3",
                  "Full Fabric3",
                ],
                enableOptions: [
                  "Aluminium Glass4",
                  "Full laminate4",
                  "Half Fabric half laminate4",
                  "Half Pin up half laminate4",
                  "Full Pin up4",
                  "Full Fabric4",
                ],
                enableFeatures: ["Aluminium Panel Options (S)"],
                required: false,
              },
            ],
          },
          {
            name: "Return Panels (S)",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                ],
                enableFeatures: ["Side Modesty (S)"],
                enableOptions: ["Prelam", "Metal"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fabric001",
                      "glass001",
                      "Alluminiumname_board001",
                      "hangingwhiteboard001",
                      "metal_nameplate001",
                      "aluminium001",
                      "alumfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhal001",
                      "alumglass001",
                      "alumfullfabric001",
                      "alumfulllaminate",
                      "alumhalfandhalf001",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel",
                      "glass_end",
                      "fabric_end",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                enableFeatures: ["Side Modesty (S)", "Return Panel Height(S)"],
                disableOptions: ["300RG", "450RG", "300RH", "450RH", "300RA", "450RA", "300RMF", "450RMF"],
                enableOptions: ["300RF", "450RF", "Prelam", "Metal"],
                rules: [
                  {
                    type: "show",
                    node: ["fabric_end"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium001",
                      "alumfullfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhalf001",
                      "alumglass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass001",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel",
                    ],
                  },
                ],
              },
              {
                name: "Magnetic Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                enableFeatures: ["Side Modesty (S)", "Return Panel Height(S)"],
                disableOptions: ["300RG", "450RG", "300RH", "450RH", "300RA", "450RA", "300RF", "450RF"],
                enableOptions: ["300RMF", "450RMF", "Prelam", "Metal"],
                rules: [
                  {
                    type: "show",
                    node: ["fabric_end"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium001",
                      "alumfullfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhalf001",
                      "alumglass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass001",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel",
                    ],
                  },
                ],
              },
              {
                name: "Glass",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                enableFeatures: ["Side Modesty (S)", "Return Panel Height(S)"],
                disableOptions: ["300RF", "450RF", "300RH", "450RH","300RA", "450RA", "300RMF", "450RMF"],
                enableOptions: ["300RG", "450RG", "Prelam", "Metal"],
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["glass_end"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric001",
                      "aluminium001",
                      "alumfullfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhalf001",
                      "alumglass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                enableFeatures: ["Side Modesty (S)", "Return Panel Height(S)"],
                disableOptions: ["300RG", "450RG", "300RH", "450RH", "300RF", "450RF", "300RMF", "450RMF"],
                enableOptions: ["300RA", "450RA"],

                disableFeatures: [
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["aluminium001", "alumfullfabric001"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric001",
                      "glass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel",
                    ],
                  },
                ],
              },

              {
                name: "Hung Panel",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                enableFeatures: ["Side Modesty (S)", "Return Panel Height(S)"],
                disableOptions: [
                  "300RG",
                  "450RG",
                  "300RF",
                  "450RF",
                  "Prelam",
                  "Metal", "300RA", "450RA", "300RMF", "450RMF"
                ],
                enableOptions: ["300RH", "450RH"],
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                  "Side Modesty (S)",
                ],
                rules: [
                  {
                    type: hide,
                    node: [
                      // "fabric001",
                      "aluminium001",
                      "alumfullfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhalf001",
                      "alumglass001",
                      // "glass001",
                      "prelammidside",
                      "prelammid@workside",
                      "metalmodestymid@workside",
                      "metalmodestyleftside",
                      "metalmodestyright",
                      "prelamright",
                      "glass_end",
                      "fabric_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["hungpanel"],
                  },
                ],
              },
              // {
              //   name: "Aluminium",
              //   thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableFeatures: ["Aluminium Panel Options"],
              //   disableFeatures: ["Fabric Panel Accessories"],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["aluminium", "alumfullfabric"],
              //     },
              //     {
              //       type: "hide",
              //       node: ["fabric", "glass"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Return Panel Height(S)",
            multiSelect: true,
            options: [
              {
                name: "300RF",
                required: false,
              },

              {
                name: "450RF",
                required: false,
              },

              {
                name: "300RMF",
                required: false,
              },

              {
                name: "450RMF",
                required: false,
              },
              {
                name: "300RG",
                required: false,
              },

              {
                name: "450RG",
                required: false,
              },
              {
                name: "300RA",
                required: false,
              },

              {
                name: "450RA",
                required: false,
              },
              {
                name: "300RH",
                required: false,
              },

              {
                name: "450RH",
                required: false,
              },
            ],
          },

          {
            name: "Intermediate Panels (S)",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                ],
                enableFeatures: ["Side Modesty (S)"],
                enableOptions: ["Prelam", "Metal"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fabric001",
                      "glass001",
                      "Alluminiumname_board001",
                      "hangingwhiteboard001",
                      "metal_nameplate001",
                      "aluminium001",
                      "alumfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhal001",
                      "alumglass001",
                      "alumfullfabric001",
                      "alumfulllaminate",
                      "alumhalfandhalf001",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      // "hungpanel",
                    ],
                  },
                ],
              },
              {
                name: "Fabric1",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                enableFeatures: ["Side Modesty (S)", "Return Panel Height(S)"],
                // disableOptions: ["300RG", "450RG", "300RH", "450RH"],
                // enableOptions: ["300RF", "450RF", "Prelam", "Metal"],
                disableOptions: ["300IRG", "450IRG", "300IRA", "450IRA", "300IRMF", "450IRMF"],
                enableOptions: ["300IRF", "450IRF"],
                rules: [
                  {
                    type: "show",
                    node: ["fabric001"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium001",
                      "alumfullfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhalf001",
                      "alumglass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass001",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      // "hungpanel",
                    ],
                  },
                ],
              },
              {
                name: "Magnetic Fabric1",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                enableFeatures: ["Side Modesty (S)", "Return Panel Height(S)"],
                disableOptions: ["300IRG", "450IRG", "300IRA", "450IRA", "300IRF", "450IRF"],
                enableOptions: ["300IRMF", "450IRMF"],
                rules: [
                  {
                    type: "show",
                    node: ["fabric001"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium001",
                      "alumfullfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhalf001",
                      "alumglass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass001",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      // "hungpanel",
                    ],
                  },
                ],
              },
              {
                name: "Glass1",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                enableFeatures: ["Side Modesty (S)", "Return Panel Height(S)"],
                disableOptions: ["300IRF", "450IRF", "300IRA", "450IRA", "300IRMF", "450IRMF"],
                enableOptions: ["300IRG", "450IRG"],
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["glass001"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric001",
                      "aluminium001",
                      "alumfullfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhalf001",
                      "alumglass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      // "hungpanel",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium1",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                enableFeatures: ["Side Modesty (S)", "Return Panel Height(S)"],
                disableOptions: ["300IRG", "450IRG", "300IRF", "450IRF", "300IRMF", "450IRMF"],
                enableOptions: ["300IRA", "450IRA"],

                disableFeatures: [
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["aluminium001", "alumfullfabric001"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric001",
                      "glass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel",
                    ],
                  },
                ],
              },

              // {
              //   name: "Hung Panel",
              //   thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
              //   enableFeatures: ["Side Modesty (S)", "Return Panel Height(S)"],
              //   disableOptions: [
              //     "300RG",
              //     "450RG",
              //     "300RF",
              //     "450RF",
              //     "Prelam",
              //     "Metal",
              //   ],
              //   enableOptions: ["300RH", "450RH"],
              //   disableFeatures: [
              //     "Aluminium Panel Options",
              //     "Fabric Panel Accessories",
              //     "End to End Panel Options",
              //     "Side Modesty (S)",
              //   ],
              //   rules: [
              //     {
              //       type: hide,
              //       node: [
              //         // "fabric001",
              //         "aluminium001",
              //         "alumfullfabric001",
              //         "alumfulllaminate001",
              //         "alumhalfandhalf001",
              //         "alumglass001",
              //         // "glass001",
              //         "prelammidside",
              //         "prelammid@workside",
              //         "metalmodestymid@workside",
              //         "metalmodestyleftside",
              //         "metalmodestyright",
              //         "prelamright",
              //         "glass_end",
              //         "fabric_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["hungpanel"],
              //     },
              //   ],
              // },
              // {
              //   name: "Aluminium",
              //   thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableFeatures: ["Aluminium Panel Options"],
              //   disableFeatures: ["Fabric Panel Accessories"],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["aluminium", "alumfullfabric"],
              //     },
              //     {
              //       type: "hide",
              //       node: ["fabric", "glass"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Intermediate Panel Height(S)",
            multiSelect: true,
            options: [
              {
                name: "300IRF",
                required: false
              },
              {
                name: "450IRF",
                required: false
              },
              {
                name: "300IRMF",
                required: false
              },
              {
                name: "450IRMF",
                required: false
              },
              {
                name: "300IRG",
                required: false
              },
              {
                name: "450IRG",
                required: false
              },
              {
                name: "300IRA",
                required: false
              },
              {
                name: "450IRA",
                required: false
              }
            ]
          },

          {
            name: "Aluminium Panel Options (S)",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "Full Fabric3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Full Pin up3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Full laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Aluminium Glass3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                    ],
                  },
                ],
              },
              {
                name: "Full Fabric4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Full Pin up4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Full laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Aluminium Glass4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                    ],
                  },
                ],
              },
            ],
          },

          {
            name: "Fabric Panel Accessories (S)",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                    ],
                  },
                ],
              },
              {
                name: "Accessories Rail",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["rail"],
                  },
                ],
              },
              {
                name: "Metal Nameplate",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["metal_nameplate"],
                  },
                  {
                    type: "hide",
                    node: ["Alluminiumname_board"],
                  },
                ],
              },
              {
                name: "Alluminiumname_board",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["Alluminiumname_board"],
                  },
                  {
                    type: "hide",
                    node: ["metal_nameplate"],
                  },
                ],
              },
              {
                name: "Hanging whiteboard",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["hangingwhiteboard"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Side Modesty (NS)",
            nodeName: [
              "prelamleft",
              "metalmodestyleft",
              "prelamright",
              "metalmodestyright",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelammidside",
                      "prelammid@workside",
                      "metalmodestymid@workside",
                      "metalmodestyleftside",
                      "metalmodestyright",
                      "prelamright",
                    ],
                  },
                ],
              },
              {
                name: "Prelam",
                thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
                rules: [
                  {
                    type: "show",

                    node: ["prelammidside", "prelamright"],
                  },
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@workside",
                      "metalmodestyleftside",
                      "metalmodestyright",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["prelammidside"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["prelammid@workside"],
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "show",

                    node: ["metalmodestyleftside", "metalmodestyright"],
                  },
                  {
                    type: "hide",
                    node: [
                      "prelammid@workside",
                      "prelammidside",
                      "prelamright",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["metalmodestyleftside"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["metalmodestymid@workside"],
                  },
                ],
              },
            ],
          },
          {
            name: "Mid Modesty (NS)",
            nodeName: [
              "prelammid",
              "metalmodestymid",
              "metalmodestymid@work",
              "prelammid@work",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelammid",
                      "metalmodestymid",
                      "metalmodestymid@work",
                      "prelammid@work",
                    ],
                  },
                ],
              },
              {
                name: "Mid Prelam",
                thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
                rules: [
                  {
                    type: "show",
                    node: ["prelammid"],
                  },
                  {
                    type: "hide",
                    node: ["metalmodestymid", "gorment"],
                  },
                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["prelammid", "metalmodestymid@work"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["prelammid@work"],
                  },
                ],
              },
              {
                name: "Mid Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "show",
                    node: ["metalmodestymid"],
                  },
                  {
                    type: "hide",
                    node: ["prelammid", "gorment"],
                  },
                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["prelammid@work", "metalmodestymid"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "C Cluster Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["metalmodestymid@work"],
                  },
                ],
              },
            ],
          },

          {
            name: "Privacy Panels (NS)",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                disableFeatures: [
                  "Aluminium Panel Options (NS)",
                  "Fabric Panel Accessories (NS)",
                  "End to End Panel Options (NS)",
                  "Privacy Panel Height(NS)",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "Alluminiumname_board",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "aluminium",
                      "alumfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                disableOptions: [
                  "300G",
                  "300A",
                  "450G",
                  "450A",
                  "300H",
                  "450H", "300MF", "450MF"
                ],
                enableOptions: ["300F", "450F"],
                disableFeatures: [
                  "Aluminium Panel Options (NS)",
                  "End to End Panel Options (NS)",
                ],
                enableFeatures: [
                  "Fabric Panel Accessories (NS)",
                  "Privacy Panel Height(NS)",
                ],

                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["fabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel",
                    ],
                  },
                ],
              },
              {
                name: "Magnetic Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                disableOptions: [
                  "300G",
                  "300A",
                  "450G",
                  "450A",
                  "300H",
                  "450H", "300F", "450F"
                ],
                enableOptions: ["300MF", "450MF"],
                disableFeatures: [
                  "Aluminium Panel Options (NS)",
                  "End to End Panel Options (NS)",
                ],
                enableFeatures: [
                  "Fabric Panel Accessories (NS)",
                  "Privacy Panel Height(NS)",
                ],

                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["fabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel",
                    ],
                  },
                ],
              },
              {
                name: "Glass",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "300F",
                  "300A",
                  "450F",
                  "450A",
                  "300H",
                  "450H", "300MF", "450MF"
                ],
                enableOptions: ["300G", "450G"],
                disableFeatures: [
                  "Aluminium Panel Options (NS)",
                  "Fabric Panel Accessories (NS)",
                  "End to End Panel Options (NS)",
                ],
                enableFeatures: ["Privacy Panel Height(NS)"],
                rules: [
                  {
                    type: "show",
                    node: ["glass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                disableOptions: [
                  "300F",
                  "300G",
                  "450F",
                  "450G",
                  "300H",
                  "450H", "300MF", "450MF"
                ],
                enableOptions: ["300A", "450A"],
                //pid: "6122f35afe408b1279847325",
                enableFeatures: ["Privacy Panel Height(NS)"],
                disableFeatures: [
                  "Fabric Panel Accessories (S)",
                  "End to End Panel Options (S)",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["aluminium", "alumfullfabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel",
                    ],
                  },
                ],
              },
              {
                name: "Hung Panel Main",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                enableFeatures: [
                  "Side Modesty (NS)",
                  "Privacy Panel Height(NS)",
                ],
                disableOptions: [
                  "300G",
                  "450G",
                  "300F",
                  "450F",
                  "300A",
                  "450A",
                  "Prelam",
                  "Metal", "300MF", "450MF"
                ],
                enableOptions: ["300H", "450H"],
                disableFeatures: [
                  "Aluminium Panel Options (NS)",
                  "Fabric Panel Accessories (NS)",
                  "End to End Panel Options (NS)",
                ],
                rules: [
                  {
                    type: hide,
                    node: [
                      "fabric",
                      "glass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "fabric",
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["hungpanel"],
                  },
                ],
              },
              // {
              //   name: "end to end panels",
              //   thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableFeatures: ["End to End Panel Options (S)"],
              //   disableFeatures: [
              //     "Fabric Panel Accessories (S)",
              //     "Aluminium Panel Options (S)",
              //   ],
              //   rules: [
              //     {
              //       type: replacePart,
              //       node: [
              //         "End1",
              //         "End2",
              //         "End3",
              //         "End4",
              //         "End5",
              //         "End6",
              //         "End7",
              //         "End8",
              //         "End9",
              //         "End10",
              //         "End11",
              //         "End12",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/68f3c295-7cee-4c0a-93ca-bd71fc652169/4_5_2024/1717499694262/end-to-end-panels-.glb",
              //     },
              //     {
              //       type: replacePart,
              //       condition: [
              //         {
              //           feature: "SHARING",
              //           values: ["Two Seater"],
              //         },
              //       ],
              //       node: [
              //         "End1",
              //         "End2",
              //         "End3",
              //         "End4",
              //         "End5",
              //         "End6",
              //         "End7",
              //         "End8",
              //         "End9",
              //         "End10",
              //         "End11",
              //         "End12",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/13e0c646-8b9c-4974-82a8-2f19c1fce3fc/24_5_2024/1719233596450/2seaterendtoendpane-.glb",
              //     },
              //     {
              //       type: hide,
              //       node: [
              //         "aluminium",
              //         "alumfullfabric",
              //         "fabric",
              //         "alumfullfabric",
              //         "glass",
              //         "alumglass",
              //         "fabric",
              //         "aluminium",
              //         "alumfullfabric",
              //         "alumfulllaminate",
              //         "alumhalfandhalf",
              //         "alumglass",
              //         "endfulllaminate008",
              //         "endglass008",
              //         "endhalfandhalf008",
              //         "endfulllaminate011",
              //         "endglassendhalfandhalf011",
              //         "endglass011",
              //         "endhalfandhalf011",
              //       ],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Privacy Panel Height(NS)",
            multiSelect: true,
            options: [
              {
                name: "300F",
                required: false,
              },

              {
                name: "450F",
                required: false,
              },

              {
                name: "300MF",
                required: false,
              },

              {
                name: "450MF",
                required: false,
              },
              {
                name: "300G",
                required: false,
              },

              {
                name: "450G",
                required: false,
              },
              {
                name: "300A",
                enableFeatures: ["Aluminium Panel Options (NS)"],
                enableOptions: [
                  "Aluminium Glass3",
                  "Full laminate3",
                  "Half Fabric half laminate3",
                  "Half Pin up half laminate3",
                  "Full Pin up3",
                  "Full Fabric3",
                ],
                disableOptions: [
                  "Aluminium Glass4",
                  "Full laminate4",
                  "Half Fabric half laminate4",
                  "Half Pin up half laminate4",
                  "Full Pin up4",
                  "Full Fabric4",
                ],
                required: false,
              },

              {
                name: "450A",
                disableOptions: [
                  "Aluminium Glass3",
                  "Full laminate3",
                  "Half Fabric half laminate3",
                  "Half Pin up half laminate3",
                  "Full Pin up3",
                  "Full Fabric3",
                ],
                enableOptions: [
                  "Aluminium Glass4",
                  "Full laminate4",
                  "Half Fabric half laminate4",
                  "Half Pin up half laminate4",
                  "Full Pin up4",
                  "Full Fabric4",
                ],
                enableFeatures: ["Aluminium Panel Options (NS)"],
                required: false,
              },

              {
                name: "450H",
                required: false,
              },
              {
                name: "300H",
                required: false,
              },
            ],
          },
          {
            name: "Return Panels (NS)",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                  "Return Panel Height(NS)",
                ],
                enableFeatures: ["Side Modesty (NS)"],
                enableOptions: ["Prelam", "Metal"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      // "fabric001",
                      // "glass001",
                      "Alluminiumname_board001",
                      "hangingwhiteboard001",
                      "metal_nameplate001",
                      "aluminium001",
                      "alumfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhal001",
                      "alumglass001",
                      "alumfullfabric001",
                      "alumfulllaminate",
                      "alumhalfandhalf001",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel001",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                enableFeatures: [
                  "Side Modesty (NS)",
                  "Return Panel Height(NS)",
                ],
                disableOptions: ["300RG", "450RG", "300RH", "450RH","300RMF", "450RMF", "300RA", "450RA"],
                enableOptions: ["300RF", "450RF", "Prelam", "Metal",],
                rules: [
                  {
                    type: "show",
                    node: ["fabric_end"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium001",
                      "alumfullfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhalf001",
                      "alumglass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass001",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel001",
                    ],
                  },
                ],
              },
              {
                name: "Magnetic Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                enableFeatures: [
                  "Side Modesty (NS)",
                  "Return Panel Height(NS)",
                ],
                disableOptions: ["300RG", "450RG", "300RH", "450RH", "300RF", "450RF", "300RA", "450RA"],
                enableOptions: ["300RMF", "450RMF", "Prelam", "Metal"],
                rules: [
                  {
                    type: "show",
                    node: ["fabric_end"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium001",
                      "alumfullfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhalf001",
                      "alumglass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass001",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel001",
                    ],
                  },
                ],
              },
              {
                name: "Glass",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                enableFeatures: [
                  "Side Modesty (NS)",
                  "Return Panel Height(NS)",
                ],
                disableOptions: ["300RF", "450RF", "300RH", "450RH","300RMF", "450RMF", "300RA", "450RA"],
                enableOptions: ["300RG", "450RG", "Prelam", "Metal"],
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["glass_end"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric001",
                      "aluminium001",
                      "alumfullfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhalf001",
                      "alumglass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel001",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                enableFeatures: ["Side Modesty (S)", "Return Panel Height(S)"],
                disableOptions: ["300RG", "450RG", "300RH", "450RH", "300RF", "450RF", "300RMF", "450RMF"],
                enableOptions: ["300RA", "450RA"],

                disableFeatures: [
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["aluminium001", "alumfullfabric001"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric001",
                      "glass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanel",
                    ],
                  },
                ],
              },

              {
                name: "Hung Panel",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                enableFeatures: [
                  "Side Modesty (NS)",
                  "Return Panel Height(NS)",
                ],
                disableOptions: [
                  "300RG",
                  "450RG",
                  "300RF",
                  "450RF",
                  "Prelam",
                  "Metal","300RMF", "450RMF", "300RA", "450RA"
                ],
                enableOptions: ["300RH", "450RH"],
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                  "Side Modesty (S)",
                ],
                rules: [
                  {
                    type: hide,
                    node: [
                      "fabric001",
                      "aluminium001",
                      "alumfullfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhalf001",
                      "alumglass001",
                      "glass001",
                      "prelammidside",
                      "prelammid@workside",
                      "metalmodestymid@workside",
                      "metalmodestyleftside",
                      "metalmodestyright",
                      "prelamright",
                      "fabric_end",
                      "glass_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["hungpanel001"],
                  },
                ],
              },
              // {
              //   name: "Aluminium",
              //   thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableFeatures: ["Aluminium Panel Options"],
              //   disableFeatures: ["Fabric Panel Accessories"],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["aluminium", "alumfullfabric"],
              //     },
              //     {
              //       type: "hide",
              //       node: ["fabric", "glass"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Return Panel Height(NS)",
            multiSelect: true,
            options: [
              {
                name: "300RF",
                required: false,
              },

              {
                name: "450RF",
                required: false,
              },

              {
                name: "300RMF",
                required: false,
              },

              {
                name: "450RMF",
                required: false,
              },
              {
                name: "300RG",
                required: false,
              },

              {
                name: "450RG",
                required: false,
              },
              {
                name: "300RA",
                required: false,
              },

              {
                name: "450RA",
                required: false,
              },
              {
                name: "300RH",
                required: false,
              },

              {
                name: "450RH",
                required: false,
              },
            ],
          },

          {
            name: "Intermediate Panels (NS)",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                  "Return Panel Height(NS)",
                ],
                enableFeatures: ["Side Modesty (NS)"],
                enableOptions: ["Prelam", "Metal"],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fabric001",
                      "glass001",
                      "Alluminiumname_board001",
                      "hangingwhiteboard001",
                      "metal_nameplate001",
                      "aluminium001",
                      "alumfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhal001",
                      "alumglass001",
                      "alumfullfabric001",
                      "alumfulllaminate",
                      "alumhalfandhalf001",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      // "hungpanel001",
                    ],
                  },
                ],
              },
              {
                name: "Fabric1",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                enableFeatures: [
                  "Side Modesty (NS)",
                  "Return Panel Height(NS)",
                ],
                disableOptions: ["300IRG", "450IRG", "300IRH", "450IRH", "300IRMF", "450IRMF", "300IRA", "450IRA"],
                enableOptions: ["300IRF", "450IRF"],
                rules: [
                  {
                    type: "show",
                    node: ["fabric001"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium001",
                      "alumfullfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhalf001",
                      "alumglass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass001",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      // "hungpanel001",
                      // "fabric_end",
                      // "glass_end",
                    ],
                  },
                ],
              },
              {
                name: "Magnetic Fabric1",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                enableFeatures: [
                  "Side Modesty (NS)",
                  "Return Panel Height(NS)",
                ],
                disableOptions: ["300IRG", "450IRG", "300IRH", "450IRH", "300IRF", "450IRF","300IRA", "450IRA"],
                enableOptions: ["300IRMF", "450IRMF"],
                rules: [
                  {
                    type: "show",
                    node: ["fabric001"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium001",
                      "alumfullfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhalf001",
                      "alumglass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass001",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      // "hungpanel001",
                      // "fabric_end",
                      // "glass_end",
                    ],
                  },
                ],
              },
              
              {
                name: "Glass1",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                enableFeatures: [
                  "Side Modesty (NS)",
                  "Return Panel Height(NS)",
                ],
                disableOptions: ["300IRF", "450IRF", "300IRH", "450IRH", "300IRMF", "450IRMF", "300IRA", "450IRA"],
                enableOptions: ["300IRG", "450IRG"],
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["glass001"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric001",
                      "aluminium001",
                      "alumfullfabric001",
                      "alumfulllaminate001",
                      "alumhalfandhalf001",
                      "alumglass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      // "hungpanel001",
                      // "fabric_end",
                      // "glass_end",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium1",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                enableFeatures: ["Side Modesty (S)", "Return Panel Height(S)"],
                disableOptions: ["300IRG", "450IRG", "300IRF", "450IRF", "300IRMF", "450IRMF"],
                enableOptions: ["300IRA", "450IRA"],

                disableFeatures: [
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["aluminium001", "alumfullfabric001"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric001",
                      "glass001",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      // "hungpanel",
                    ],
                  },
                ],
              },

              // {
              //   name: "Hung Panel",
              //   thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
              //   enableFeatures: [
              //     "Side Modesty (NS)",
              //     "Return Panel Height(NS)",
              //   ],
              //   disableOptions: [
              //     "300RG",
              //     "450RG",
              //     "300RF",
              //     "450RF",
              //     "Prelam",
              //     "Metal",
              //   ],
              //   enableOptions: ["300RH", "450RH"],
              //   disableFeatures: [
              //     "Aluminium Panel Options",
              //     "Fabric Panel Accessories",
              //     "End to End Panel Options",
              //     "Side Modesty (S)",
              //   ],
              //   rules: [
              //     {
              //       type: hide,
              //       node: [
              //         "fabric001",
              //         "aluminium001",
              //         "alumfullfabric001",
              //         "alumfulllaminate001",
              //         "alumhalfandhalf001",
              //         "alumglass001",
              //         "glass001",
              //         "prelammidside",
              //         "prelammid@workside",
              //         "metalmodestymid@workside",
              //         "metalmodestyleftside",
              //         "metalmodestyright",
              //         "prelamright",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["hungpanel001"],
              //     },
              //   ],
              // },
              // {
              //   name: "Aluminium",
              //   thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableFeatures: ["Aluminium Panel Options"],
              //   disableFeatures: ["Fabric Panel Accessories"],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["aluminium", "alumfullfabric"],
              //     },
              //     {
              //       type: "hide",
              //       node: ["fabric", "glass"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Intermediate Panel Height(NS)",
            multiSelect: true,
            options: [
              {
                name: "300IRF",
                required: false
              },
              {
                name: "450IRF",
                required: false
              },
              {
                name: "300IRMF",
                required: false
              },
              {
                name: "450IRMF",
                required: false
              },
              {
                name: "300IRG",
                required: false
              },
              {
                name: "450IRG",
                required: false
              },
              {
                name: "300IRA",
                required: false
              },
              {
                name: "450IRA",
                required: false
              }
            ]
          },

          {
            name: "Aluminium Panel Options (NS)",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "Full Fabric3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Full Pin up3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Full laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Aluminium Glass3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                    ],
                  },
                ],
              },
              {
                name: "Full Fabric4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Full Pin up4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Full laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Aluminium Glass4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                    ],
                  },
                ],
              },
            ],
          },

          {
            name: "Fabric Panel Accessories (NS)",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                    ],
                  },
                ],
              },
              {
                name: "Accessories Rail",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["rail"],
                  },
                ],
              },
              {
                name: "Metal Nameplate",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["metal_nameplate"],
                  },
                  {
                    type: "hide",
                    node: ["Alluminiumname_board"],
                  },
                ],
              },
              {
                name: "Alluminiumname_board",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["Alluminiumname_board"],
                  },
                  {
                    type: "hide",
                    node: ["metal_nameplate"],
                  },
                ],
              },
              {
                name: "Hanging whiteboard",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["hangingwhiteboard"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Glass Color",
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "SKY BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
              },
            ],
          },
          {
            name: "SOLAR YELLOW",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
              },
            ],
          },
          {
            name: "OCEAN GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
              },
            ],
          },
          {
            name: "ORANGE MIST",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
              },
            ],
          },
          {
            name: "FLAME RED",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
              },
            ],
          },
          {
            name: "CASIS PURPLE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
              },
            ],
          },
          {
            name: "AZURE BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
              },
            ],
          },
          {
            name: "APPLE GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
              },
            ],
          },
          {
            name: "SUNNY ORANGE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
              },
            ],
          },
          {
            name: "SUPER WHITE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
              },
            ],
          },
          {
            name: "GREEN LIME",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "SKY BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
              },
            ],
          },
          {
            name: "SOLAR YELLOW",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
              },
            ],
          },
          {
            name: "OCEAN GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
              },
            ],
          },
          {
            name: "ORANGE MIST",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
              },
            ],
          },
          {
            name: "FLAME RED",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
              },
            ],
          },
          {
            name: "CASIS PURPLE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
              },
            ],
          },
          {
            name: "AZURE BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
              },
            ],
          },
          {
            name: "APPLE GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
              },
            ],
          },
          {
            name: "SUNNY ORANGE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
              },
            ],
          },
          {
            name: "SUPER WHITE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
              },
            ],
          },
          {
            name: "GREEN LIME",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fabric Color",
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Tangerine",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
              },
            ],
          },
          {
            name: "Acid",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
              },
            ],
          },
          {
            name: "Acorn",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
              },
            ],
          },
          {
            name: "Amethyst",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
              },
            ],
          },
          {
            name: "Apollo",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
              },
            ],
          },
          {
            name: "Azure",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
              },
            ],
          },

          {
            name: "Blush",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
              },
            ],
          },
          {
            name: "Brown",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
              },
            ],
          },
          {
            name: "Burnt Orange",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
              },
            ],
          },
          {
            name: "Corn Flower",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
              },
            ],
          },
          {
            name: "Magenta",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
              },
            ],
          },

          {
            name: "Pink",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
              },
            ],
          },
          {
            name: "Purple",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
              },
            ],
          },
          {
            name: "Raspberry",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
              },
            ],
          },
          {
            name: "Ruby",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
              },
            ],
          },
          {
            name: "Salmon",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
              },
            ],
          },
          {
            name: "Shimmer",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
              },
            ],
          },

          {
            name: "Electric",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
              },
            ],
          },
          {
            name: "Frengia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
              },
            ],
          },
          {
            name: "Baby Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Tangerine",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
              },
            ],
          },
          {
            name: "Acid",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
              },
            ],
          },
          {
            name: "Acorn",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
              },
            ],
          },
          {
            name: "Amethyst",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
              },
            ],
          },
          {
            name: "Apollo",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
              },
            ],
          },
          {
            name: "Azure",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
              },
            ],
          },

          {
            name: "Blush",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
              },
            ],
          },
          {
            name: "Brown",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
              },
            ],
          },
          {
            name: "Burnt Orange",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
              },
            ],
          },
          {
            name: "Corn Flower",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
              },
            ],
          },
          {
            name: "Magenta",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
              },
            ],
          },

          {
            name: "Pink",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
              },
            ],
          },
          {
            name: "Purple",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
              },
            ],
          },
          {
            name: "Raspberry",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
              },
            ],
          },
          {
            name: "Ruby",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
              },
            ],
          },
          {
            name: "Salmon",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
              },
            ],
          },
          {
            name: "Shimmer",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
              },
            ],
          },

          {
            name: "Electric",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
              },
            ],
          },
          {
            name: "Frengia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
              },
            ],
          },
          {
            name: "Baby Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Accessories",

    options: [
      {
        name: "SHARING",
        required: false,
        multiSelect: false,
        options: [
          {
            name: "Keyboard Trays (S)",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray", "Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard Tray",
                thumbnail: "assets/system/thumbnails/Parts/keyboardtray.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray"],
                  },
                  {
                    type: "show",
                    node: ["Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard and Mouse Tray",
                thumbnail: "assets/system/thumbnails/Parts/keyandmousetray.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key_board_tray"],
                  },
                  {
                    type: "show",
                    node: ["Key&mousetray"],
                  },
                ],
              },
            ],
          },
          {
            name: "CPU Stands (S)",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand", "CPU_stand"],
                  },
                ],
              },
              {
                name: "CPU Stand",
                thumbnail: "assets/system/thumbnails/Parts/cpustand.png",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand"],
                  },
                  {
                    type: "show",
                    node: ["CPU_stand"],
                  },
                ],
              },
              {
                name: "Hanging CPU Stand",
                thumbnail:
                  "assets/system/thumbnails/Parts/hangingcpustand .png",
                rules: [
                  {
                    type: "hide",
                    node: ["CPU_stand"],
                  },
                  {
                    type: "show",
                    node: ["hangingcpustand"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        multiSelect: false,
        options: [
          {
            name: "Keyboard Trays (NS)",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray", "Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard Tray",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray"],
                  },
                  {
                    type: "show",
                    node: ["Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard and Mouse Tray",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key_board_tray"],
                  },
                  {
                    type: "show",
                    node: ["Key&mousetray"],
                  },
                ],
              },
            ],
          },
          {
            name: "CPU Stands (NS)",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand", "CPU_stand"],
                  },
                ],
              },
              {
                name: "CPU Stand",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand"],
                  },
                  {
                    type: "show",
                    node: ["CPU_stand"],
                  },
                ],
              },
              {
                name: "Hanging CPU Stand",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["CPU_stand"],
                  },
                  {
                    type: "show",
                    node: ["hangingcpustand"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;