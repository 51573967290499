import { rules } from "@typescript-eslint/eslint-plugin";
import { getDefaultCompilerOptions } from "typescript";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

const bindPart = "bindPart";
const unbind = "unbind";

// const hideModestiesRules = [
//   {
//     // type: "hide",
//     // node: ["modesty_wood_left", "modesty_perforated_left"],
//   },
// ];
export const ConfData3 = Object.freeze([
  //basically for linear workstations
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Two Seater",
            size: 2,
            "Table Top Options": "Main Screen",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/plan/sharing/2.png",
            productId: "66a3a1786ad66073c4f8e3ce",
            Category: "Plan System Linear",
            disableFeatures: [
              "Metal Option",
              "Wood Option",
              "Return Frame Pannel Dimension",
              "Return  Pannel Dimension",
            ],
            rules: [
              {
                type: "replacePart",
                node: "right_leg",
                pid: "https://content.helloviewer.io/uploads/1b19f409-6879-4752-90d5-6e2dc15105d0/26_6_2024/1721976304187/rightleggamble.glb",
              },
              {
                type: "replacePart",
                node: [
                  "mid_leg",
                  "mid_leg1",
                  ,
                  "mid_leg10",
                  "mid_leg11",
                  "mid_leg12",
                  "mid_leg13",
                  "mid_leg14",
                  "mid_leg15",
                  "mid_leg16",
                  "mid_leg17",
                  "mid_leg18",
                  "mid_leg19",
                ],
                pid: "https://content.helloviewer.io/uploads/0a8e3cf8-61f8-4167-afdc-a868e0ed01f0/25_6_2024/1721913216694/midleggamble.glb",
              },
              {
                type: "replacePart",
                node: "left_leg",
                pid: "https://content.helloviewer.io/uploads/5bdffc46-3cdb-47dd-b97c-59a20ef15b34/26_6_2024/1721976327824/leftleggamble.glb",
              },
              {
                type: hide,
                node: [
                  // "right_leg",
                  // "left_leg",
                  // "mid_leg",
                  "FlipUp",
                  "FlipU_reverse",
                  "Table_top_reverse",
                  "FlipU_radious",
                  "Table_top_radious",
                  "CPU_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "alumfulllaminate_mid",
                  "alumglass_mid",
                  "alumhalfandhalf_mid",
                  "glasshalfandhalflam_fabric",
                  "glasshalfandhalflam_mid",
                  "gorment",
                  "gorment2",
                  "mainscreenmetal",
                  "seprator",
                  "single",
                  "alumfullfabric_right001",
                  "alumfulllaminate_right001",
                  "alumglass_right001",
                  "midscreenmetal",
                  "midscreenwood",
                  "returnfabric001",
                  "returnglass001",
                  "returnpanelsmetal001",
                  "wooden_return001",
                  "wooden_return",
                  "returnglass",
                  "returnfabric",
                  "alumfullfabric_right",
                  "alumfulllaminate_right",
                  "alumglass_right",
                  "returnpanelsmetal",
                  "returnpanelsmetal55",
                  "sidescreenmetal",
                  "sidescreenwood",
                  "returnpanelsmetals55",
                ],
              },
              
            ],
          },
          {
            name: "Four Seater",
            size: 4,
            "Table Top Options": "Main Screen",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/plan/sharing/4.png",
            productId: "6671895ddddefe6688d18379",
            Category: "Plan System Linear",
            disableFeatures: [
              "Metal Option",
              "Wood Option",
              "Return Frame Pannel Dimension",
              "Return  Pannel Dimension",
            ],
            rules: [
              {
                type: "replacePart",
                node: "right_leg",
                pid: "https://content.helloviewer.io/uploads/1b19f409-6879-4752-90d5-6e2dc15105d0/26_6_2024/1721976304187/rightleggamble.glb",
              },
              {
                type: "replacePart",
                node: [
                  "mid_leg",
                  "mid_leg1",
                  ,
                  "mid_leg10",
                  "mid_leg11",
                  "mid_leg12",
                  "mid_leg13",
                  "mid_leg14",
                  "mid_leg15",
                  "mid_leg16",
                  "mid_leg17",
                  "mid_leg18",
                  "mid_leg19",
                ],
                pid: "https://content.helloviewer.io/uploads/0a8e3cf8-61f8-4167-afdc-a868e0ed01f0/25_6_2024/1721913216694/midleggamble.glb",
              },
              {
                type: "replacePart",
                node: "left_leg",
                pid: "https://content.helloviewer.io/uploads/5bdffc46-3cdb-47dd-b97c-59a20ef15b34/26_6_2024/1721976327824/leftleggamble.glb",
              },
              {
                type: hide,
                node: [
                  // "right_leg",
                  // "left_leg",
                  // "mid_leg",
                  "FlipUp",
                  "FlipU_reverse",
                  "Table_top_reverse",
                  "FlipU_radious",
                  "Table_top_radious",
                  "CPU_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "alumfulllaminate_mid",
                  "alumglass_mid",
                  "alumhalfandhalf_mid",
                  "glasshalfandhalflam_fabric",
                  "glasshalfandhalflam_mid",
                  "gorment",
                  "gorment2",
                  "mainscreenmetal",
                  "seprator",
                  "single",
                  "alumfullfabric_right001",
                  "alumfulllaminate_right001",
                  "alumglass_right001",
                  "midscreenmetal",
                  "midscreenwood",
                  "returnfabric001",
                  "returnglass001",
                  "returnpanelsmetal001",
                  "wooden_return001",
                  "wooden_return",
                  "returnglass",
                  "returnfabric",
                  "alumfullfabric_right",
                  "alumfulllaminate_right",
                  "alumglass_right",
                  "returnpanelsmetal",
                  "returnpanelsmetal55",
                  "sidescreenmetal",
                  "sidescreenwood",
                  "returnpanelsmetals55",
                ],
              },
              
            ],
            
          },
          {
            name: "Six Seater",
            size: 6,
            "Table Top Options": "Main Screen",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/plan/sharing/6.png",
            productId: "66a386c502419cd39832c6e0",
            Category: "Plan System Linear",
            disableFeatures: [
              "Metal Option",
              "Wood Option",
              "Return Frame Pannel Dimension",
              "Return  Pannel Dimension",
            ],
            rules: [
              {
                type: "replacePart",
                node: "right_leg",
                pid: "https://content.helloviewer.io/uploads/1b19f409-6879-4752-90d5-6e2dc15105d0/26_6_2024/1721976304187/rightleggamble.glb",
              },
              {
                type: "replacePart",
                node: [
                  "mid_leg",
                  "mid_leg1",
                  ,
                  "mid_leg10",
                  "mid_leg11",
                  "mid_leg12",
                  "mid_leg13",
                  "mid_leg14",
                  "mid_leg15",
                  "mid_leg16",
                  "mid_leg17",
                  "mid_leg18",
                  "mid_leg19",
                ],
                pid: "https://content.helloviewer.io/uploads/0a8e3cf8-61f8-4167-afdc-a868e0ed01f0/25_6_2024/1721913216694/midleggamble.glb",
              },
              {
                type: "replacePart",
                node: "left_leg",
                pid: "https://content.helloviewer.io/uploads/5bdffc46-3cdb-47dd-b97c-59a20ef15b34/26_6_2024/1721976327824/leftleggamble.glb",
              },
              {
                type: hide,
                node: [
                  // "right_leg",
                  // "left_leg",
                  // "mid_leg",
                  "FlipUp",
                  "FlipU_reverse",
                  "Table_top_reverse",
                  "FlipU_radious",
                  "Table_top_radious",
                  "CPU_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "alumfulllaminate_mid",
                  "alumglass_mid",
                  "alumhalfandhalf_mid",
                  "glasshalfandhalflam_fabric",
                  "glasshalfandhalflam_mid",
                  "gorment",
                  "gorment2",
                  "mainscreenmetal",
                  "seprator",
                  "single",
                  "alumfullfabric_right001",
                  "alumfulllaminate_right001",
                  "alumglass_right001",
                  "midscreenmetal",
                  "midscreenwood",
                  "returnfabric001",
                  "returnglass001",
                  "returnpanelsmetal001",
                  "wooden_return001",
                  "wooden_return",
                  "returnglass",
                  "returnfabric",
                  "alumfullfabric_right",
                  "alumfulllaminate_right",
                  "alumglass_right",
                  "returnpanelsmetal",
                  "returnpanelsmetal55",
                  "sidescreenmetal",
                  "sidescreenwood",
                  "returnpanelsmetals55",
                ],
              },
              
            ],
          },
          {
            name: "Eight Seater",
            size: 8,
            "Table Top Options": "Main Screen",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/plan/sharing/8.png",
            productId: "66a37d28d46ff4ac061a9e88",
            Category: "Plan System Linear",
            disableFeatures: [
              "Metal Option",
              "Wood Option",
              "Return Frame Pannel Dimension",
              "Return  Pannel Dimension",
            ],
            rules: [
              {
                type: "replacePart",
                node: "right_leg",
                pid: "https://content.helloviewer.io/uploads/1b19f409-6879-4752-90d5-6e2dc15105d0/26_6_2024/1721976304187/rightleggamble.glb",
              },
              {
                type: "replacePart",
                node: [
                  "mid_leg",
                  "mid_leg1",
                  ,
                  "mid_leg10",
                  "mid_leg11",
                  "mid_leg12",
                  "mid_leg13",
                  "mid_leg14",
                  "mid_leg15",
                  "mid_leg16",
                  "mid_leg17",
                  "mid_leg18",
                  "mid_leg19",
                ],
                pid: "https://content.helloviewer.io/uploads/0a8e3cf8-61f8-4167-afdc-a868e0ed01f0/25_6_2024/1721913216694/midleggamble.glb",
              },
              {
                type: "replacePart",
                node: "left_leg",
                pid: "https://content.helloviewer.io/uploads/5bdffc46-3cdb-47dd-b97c-59a20ef15b34/26_6_2024/1721976327824/leftleggamble.glb",
              },
              {
                type: hide,
                node: [
                  // "right_leg",
                  // "left_leg",
                  // "mid_leg",
                  "FlipUp",
                  "FlipU_reverse",
                  "Table_top_reverse",
                  "FlipU_radious",
                  "Table_top_radious",
                  "CPU_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "alumfulllaminate_mid",
                  "alumglass_mid",
                  "alumhalfandhalf_mid",
                  "glasshalfandhalflam_fabric",
                  "glasshalfandhalflam_mid",
                  "gorment",
                  "gorment2",
                  "mainscreenmetal",
                  "seprator",
                  "single",
                  "alumfullfabric_right001",
                  "alumfulllaminate_right001",
                  "alumglass_right001",
                  "midscreenmetal",
                  "midscreenwood",
                  "returnfabric001",
                  "returnglass001",
                  "returnpanelsmetal001",
                  "wooden_return001",
                  "wooden_return",
                  "returnglass",
                  "returnfabric",
                  "alumfullfabric_right",
                  "alumfulllaminate_right",
                  "alumglass_right",
                  "returnpanelsmetal",
                  "returnpanelsmetal55",
                  "sidescreenmetal",
                  "sidescreenwood",
                  "returnpanelsmetals55",
                ],
              },
              
            ],
          },
          {
            name: "Ten Seater",
            size: 10,
            "Table Top Options": "Main Screen",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/plan/sharing/10.png",
            productId: "66a3b05a0e3af2923d8b1d06",
            Category: "Plan System Linear",
            disableFeatures: [
              "Metal Option",
              "Wood Option",
              "Return Frame Pannel Dimension",
              "Return  Pannel Dimension",
            ],
            rules: [
              {
                type: "replacePart",
                node: "right_leg",
                pid: "https://content.helloviewer.io/uploads/1b19f409-6879-4752-90d5-6e2dc15105d0/26_6_2024/1721976304187/rightleggamble.glb",
              },
              {
                type: "replacePart",
                node: [
                  "mid_leg",
                  "mid_leg1",
                  ,
                  "mid_leg10",
                  "mid_leg11",
                  "mid_leg12",
                  "mid_leg13",
                  "mid_leg14",
                  "mid_leg15",
                  "mid_leg16",
                  "mid_leg17",
                  "mid_leg18",
                  "mid_leg19",
                ],
                pid: "https://content.helloviewer.io/uploads/0a8e3cf8-61f8-4167-afdc-a868e0ed01f0/25_6_2024/1721913216694/midleggamble.glb",
              },
              {
                type: "replacePart",
                node: "left_leg",
                pid: "https://content.helloviewer.io/uploads/5bdffc46-3cdb-47dd-b97c-59a20ef15b34/26_6_2024/1721976327824/leftleggamble.glb",
              },
              {
                type: hide,
                node: [
                  // "right_leg",
                  // "left_leg",
                  // "mid_leg",
                  "FlipUp",
                  "FlipU_reverse",
                  "Table_top_reverse",
                  "FlipU_radious",
                  "Table_top_radious",
                  "CPU_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "alumfulllaminate_mid",
                  "alumglass_mid",
                  "alumhalfandhalf_mid",
                  "glasshalfandhalflam_fabric",
                  "glasshalfandhalflam_mid",
                  "gorment",
                  "gorment2",
                  "mainscreenmetal",
                  "seprator",
                  "single",
                  "alumfullfabric_right001",
                  "alumfulllaminate_right001",
                  "alumglass_right001",
                  "midscreenmetal",
                  "midscreenwood",
                  "returnfabric001",
                  "returnglass001",
                  "returnpanelsmetal001",
                  "wooden_return001",
                  "wooden_return",
                  "returnglass",
                  "returnfabric",
                  "alumfullfabric_right",
                  "alumfulllaminate_right",
                  "alumglass_right",
                  "returnpanelsmetal",
                  "returnpanelsmetal55",
                  "sidescreenmetal",
                  "sidescreenwood",
                  "returnpanelsmetals55",
                ],
              },
              
            ],
          },
          {
            name: "Twelve Seater",
            size: 12,
            "Table Top Options": "Main Screen",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/plan/sharing/12.png",
            productId: "66a3897e45bdcf8017e231ab",
            Category: "Plan System Linear",
            disableFeatures: [
              "Metal Option",
              "Wood Option",
              "Return Frame Pannel Dimension",
              "Return  Pannel Dimension",
            ],
            rules: [
              {
                type: "replacePart",
                node: "right_leg",
                pid: "https://content.helloviewer.io/uploads/1b19f409-6879-4752-90d5-6e2dc15105d0/26_6_2024/1721976304187/rightleggamble.glb",
              },
              {
                type: "replacePart",
                node: [
                  "mid_leg",
                  "mid_leg1",
                  ,
                  "mid_leg10",
                  "mid_leg11",
                  "mid_leg12",
                  "mid_leg13",
                  "mid_leg14",
                  "mid_leg15",
                  "mid_leg16",
                  "mid_leg17",
                  "mid_leg18",
                  "mid_leg19",
                ],
                pid: "https://content.helloviewer.io/uploads/0a8e3cf8-61f8-4167-afdc-a868e0ed01f0/25_6_2024/1721913216694/midleggamble.glb",
              },
              {
                type: "replacePart",
                node: "left_leg",
                pid: "https://content.helloviewer.io/uploads/5bdffc46-3cdb-47dd-b97c-59a20ef15b34/26_6_2024/1721976327824/leftleggamble.glb",
              },
              {
                type: hide,
                node: [
                  // "right_leg",
                  // "left_leg",
                  // "mid_leg",
                  "FlipUp",
                  "FlipU_reverse",
                  "Table_top_reverse",
                  "FlipU_radious",
                  "Table_top_radious",
                  "CPU_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "alumfulllaminate_mid",
                  "alumglass_mid",
                  "alumhalfandhalf_mid",
                  "glasshalfandhalflam_fabric",
                  "glasshalfandhalflam_mid",
                  "gorment",
                  "gorment2",
                  "mainscreenmetal",
                  "seprator",
                  "single",
                  "alumfullfabric_right001",
                  "alumfulllaminate_right001",
                  "alumglass_right001",
                  "midscreenmetal",
                  "midscreenwood",
                  "returnfabric001",
                  "returnglass001",
                  "returnpanelsmetal001",
                  "wooden_return001",
                  "wooden_return",
                  "returnglass",
                  "returnfabric",
                  "alumfullfabric_right",
                  "alumfulllaminate_right",
                  "alumglass_right",
                  "returnpanelsmetal",
                  "returnpanelsmetal55",
                  "sidescreenmetal",
                  "sidescreenwood",
                  "returnpanelsmetals55",
                ],
              },
              
            ],
          },
          // {
          //   name: "Fourteen Seater",
          //   size: 14,
          //   Length: 1200,
          //   Depth: 600,
          //   thumbnail: "assets/system/thumbnails/plan/sharing/14.png",
          //   productId: "66a3ce2693a6a486d8a21b55",
          //   Category: "Plan System Linear",
          //   disableFeatures: [
          //     "Metal Option",
          //     "Wood Option",
          //     "Return Frame Pannel Dimension",
          //     "Return  Pannel Dimension",
          //   ],
          //   rules: [
          //     {
          //       type: hide,
          //       node: [
          //         "FlipUp",
          //         "FlipU_reverse",
          //         "Table_top_reverse",
          //         "FlipU_radious",
          //         "Table_top_radious",
          //         "CPU_stand",
          //         "hangingcpustand",
          //         "Key&mousetray",
          //         "Key_board_tray",
          //         "alumfulllaminate_mid",
          //         "alumglass_mid",
          //         "alumhalfandhalf_mid",
          //         "glasshalfandhalflam_fabric",
          //         "glasshalfandhalflam_mid",
          //         "gorment",
          //         "gorment2",
          //         "mainscreenmetal",
          //         "seprator",
          //         "single",
          //         "alumfullfabric_right001",
          //         "alumfulllaminate_right001",
          //         "alumglass_right001",
          //         "midscreenmetal",
          //         "midscreenwood",
          //         "returnfabric001",
          //         "returnglass001",
          //         "returnpanelsmetal001",
          //         "wooden_return001",
          //         "wooden_return",
          //         "returnglass",
          //         "returnfabric",
          //         "alumfullfabric_right",
          //         "alumfulllaminate_right",
          //         "alumglass_right",
          //         "returnpanelsmetal",
          //         "returnpanelsmetal55",
          //         "sidescreenmetal",
          //         "sidescreenwood",
          //         "returnpanelsmetals55",
          //       ],
          //     },
          //   ],
          // },
          // {
          //   name: "Sixteen Seater",
          //   size: 16,
          //   Length: 1200,
          //   Depth: 600,
          //   thumbnail: "assets/system/thumbnails/plan/sharing/16.png",
          //   productId: "66a381ded16eab24abdd4bbc",
          //   Category: "Plan System Linear",
          //   disableFeatures: [
          //     "Metal Option",
          //     "Wood Option",
          //     "Return Frame Pannel Dimension",
          //     "Return  Pannel Dimension",
          //   ],
          //   rules: [
          //     {
          //       type: hide,
          //       node: [
          //         "FlipUp",
          //         "FlipU_reverse",
          //         "Table_top_reverse",
          //         "FlipU_radious",
          //         "Table_top_radious",
          //         "CPU_stand",
          //         "hangingcpustand",
          //         "Key&mousetray",
          //         "Key_board_tray",
          //         "alumfulllaminate_mid",
          //         "alumglass_mid",
          //         "alumhalfandhalf_mid",
          //         "glasshalfandhalflam_fabric",
          //         "glasshalfandhalflam_mid",
          //         "gorment",
          //         "gorment2",
          //         "mainscreenmetal",
          //         "seprator",
          //         "single",
          //         "alumfullfabric_right001",
          //         "alumfulllaminate_right001",
          //         "alumglass_right001",
          //         "midscreenmetal",
          //         "midscreenwood",
          //         "returnfabric001",
          //         "returnglass001",
          //         "returnpanelsmetal001",
          //         "wooden_return001",
          //         "wooden_return",
          //         "returnglass",
          //         "returnfabric",
          //         "alumfullfabric_right",
          //         "alumfulllaminate_right",
          //         "alumglass_right",
          //         "returnpanelsmetal",
          //         "returnpanelsmetal55",
          //         "sidescreenmetal",
          //         "sidescreenwood",
          //         "returnpanelsmetals55",
          //       ],
          //     },
          //   ],
          // },
          // {
          //   name: "Eighteen Seater",
          //   size: 18,
          //   Length: 1200,
          //   Depth: 600,
          //   thumbnail: "assets/system/thumbnails/plan/sharing/18.png",
          //   productId: "66a3b7d05d0b205398db06d3",
          //   Category: "Plan System Linear",
          //   disableFeatures: [
          //     "Metal Option",
          //     "Wood Option",
          //     "Return Frame Pannel Dimension",
          //     "Return  Pannel Dimension",
          //   ],
          //   rules: [
          //     {
          //       type: hide,
          //       node: [
          //         "FlipUp",
          //         "FlipU_reverse",
          //         "Table_top_reverse",
          //         "FlipU_radious",
          //         "Table_top_radious",
          //         "CPU_stand",
          //         "hangingcpustand",
          //         "Key&mousetray",
          //         "Key_board_tray",
          //         "alumfulllaminate_mid",
          //         "alumglass_mid",
          //         "alumhalfandhalf_mid",
          //         "glasshalfandhalflam_fabric",
          //         "glasshalfandhalflam_mid",
          //         "gorment",
          //         "gorment2",
          //         "mainscreenmetal",
          //         "seprator",
          //         "single",
          //         "alumfullfabric_right001",
          //         "alumfulllaminate_right001",
          //         "alumglass_right001",
          //         "midscreenmetal",
          //         "midscreenwood",
          //         "returnfabric001",
          //         "returnglass001",
          //         "returnpanelsmetal001",
          //         "wooden_return001",
          //         "wooden_return",
          //         "returnglass",
          //         "returnfabric",
          //         "alumfullfabric_right",
          //         "alumfulllaminate_right",
          //         "alumglass_right",
          //         "returnpanelsmetal",
          //         "returnpanelsmetal55",
          //         "sidescreenmetal",
          //         "sidescreenwood",
          //         "returnpanelsmetals55",
          //       ],
          //     },
          //   ],
          // },
          // {
          //   name: "Twenty Seater",
          //   size: 20,
          //   Length: 1200,
          //   Depth: 600,
          //   thumbnail: "assets/system/thumbnails/plan/sharing/20.png",
          //   productId: "66a3ccdc60b68e6067259cec",
          //   Category: "Plan System Linear",
          //   disableFeatures: [
          //     "Metal Option",
          //     "Wood Option",
          //     "Return Frame Pannel Dimension",
          //     "Return  Pannel Dimension",
          //   ],
          //   rules: [
          //     {
          //       type: hide,
          //       node: [
          //         "FlipUp",
          //         "FlipU_reverse",
          //         "Table_top_reverse",
          //         "FlipU_radious",
          //         "Table_top_radious",
          //         "CPU_stand",
          //         "hangingcpustand",
          //         "Key&mousetray",
          //         "Key_board_tray",
          //         "alumfulllaminate_mid",
          //         "alumglass_mid",
          //         "alumhalfandhalf_mid",
          //         "glasshalfandhalflam_fabric",
          //         "glasshalfandhalflam_mid",
          //         "gorment",
          //         "gorment2",
          //         "mainscreenmetal",
          //         "seprator",
          //         "single",
          //         "alumfullfabric_right001",
          //         "alumfulllaminate_right001",
          //         "alumglass_right001",
          //         "midscreenmetal",
          //         "midscreenwood",
          //         "returnfabric001",
          //         "returnglass001",
          //         "returnpanelsmetal001",
          //         "wooden_return001",
          //         "wooden_return",
          //         "returnglass",
          //         "returnfabric",
          //         "alumfullfabric_right",
          //         "alumfulllaminate_right",
          //         "alumglass_right",
          //         "returnpanelsmetal",
          //         "returnpanelsmetal55",
          //         "sidescreenmetal",
          //         "sidescreenwood",
          //         "returnpanelsmetals55",
          //       ],
          //     },
          //   ],
          // },
          // {
          //   name: "Twenty Two Seater",
          //   size: 22,
          //   Length: 1200,
          //   Depth: 600,
          //   thumbnail: "assets/system/thumbnails/plan/sharing/22.png",
          //   productId: "66a3caf9680dcf923538c0eb",
          //   Category: "Plan System Linear",
          //   disableFeatures: [
          //     "Metal Option",
          //     "Wood Option",
          //     "Return Frame Pannel Dimension",
          //     "Return  Pannel Dimension",
          //   ],
          //   rules: [
          //     {
          //       type: hide,
          //       node: [
          //         "FlipUp",
          //         "FlipU_reverse",
          //         "Table_top_reverse",
          //         "FlipU_radious",
          //         "Table_top_radious",
          //         "CPU_stand",
          //         "hangingcpustand",
          //         "Key&mousetray",
          //         "Key_board_tray",
          //         "alumfulllaminate_mid",
          //         "alumglass_mid",
          //         "alumhalfandhalf_mid",
          //         "glasshalfandhalflam_fabric",
          //         "glasshalfandhalflam_mid",
          //         "gorment",
          //         "gorment2",
          //         "mainscreenmetal",
          //         "seprator",
          //         "single",
          //         "alumfullfabric_right001",
          //         "alumfulllaminate_right001",
          //         "alumglass_right001",
          //         "midscreenmetal",
          //         "midscreenwood",
          //         "returnfabric001",
          //         "returnglass001",
          //         "returnpanelsmetal001",
          //         "wooden_return001",
          //         "wooden_return",
          //         "returnglass",
          //         "returnfabric",
          //         "alumfullfabric_right",
          //         "alumfulllaminate_right",
          //         "alumglass_right",
          //         "returnpanelsmetal",
          //         "returnpanelsmetal55",
          //         "sidescreenmetal",
          //         "sidescreenwood",
          //         "returnpanelsmetals55",
          //       ],
          //     },
          //   ],
          // },
          // {
          //   name: "Twenty Four Seater",
          //   size: 24,
          //   Length: 1200,
          //   Depth: 600,
          //   thumbnail: "assets/system/thumbnails/plan/sharing/24.png",
          //   productId: "66a39f46233d6cb0b48af521",
          //   Category: "Plan System Linear",
          //   disableFeatures: [
          //     "Metal Option",
          //     "Wood Option",
          //     "Return Frame Pannel Dimension",
          //     "Return  Pannel Dimension",
          //   ],
          //   rules: [
          //     {
          //       type: hide,
          //       node: [
          //         "FlipUp",
          //         "FlipU_reverse",
          //         "Table_top_reverse",
          //         "FlipU_radious",
          //         "Table_top_radious",
          //         "CPU_stand",
          //         "hangingcpustand",
          //         "Key&mousetray",
          //         "Key_board_tray",
          //         "alumfulllaminate_mid",
          //         "alumglass_mid",
          //         "alumhalfandhalf_mid",
          //         "glasshalfandhalflam_fabric",
          //         "glasshalfandhalflam_mid",
          //         "gorment",
          //         "gorment2",
          //         "mainscreenmetal",
          //         "seprator",
          //         "single",
          //         "alumfullfabric_right001",
          //         "alumfulllaminate_right001",
          //         "alumglass_right001",
          //         "midscreenmetal",
          //         "midscreenwood",
          //         "returnfabric001",
          //         "returnglass001",
          //         "returnpanelsmetal001",
          //         "wooden_return001",
          //         "wooden_return",
          //         "returnglass",
          //         "returnfabric",
          //         "alumfullfabric_right",
          //         "alumfulllaminate_right",
          //         "alumglass_right",
          //         "returnpanelsmetal",
          //         "returnpanelsmetal55",
          //         "sidescreenmetal",
          //         "sidescreenwood",
          //         "returnpanelsmetals55",
          //       ],
          //     },
          //   ],
          // },
        ],
      },

      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "One Seater",
            "Table Top Options": "Main Screen",
            size: 1,
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/plan/non/1.png",
            productId: "66a60ccbbcd028deff4ab668",
            tableTop: ["table_top_nonshared"],
            Category: "Plan System Linear",
            rules: [
              {
                type: "replacePart",
                node: "right_leg",
                pid: "https://content.helloviewer.io/uploads/0db4292b-e9c5-4760-a5c6-578e4290bb28/26_6_2024/1721979096104/gablenonsahringright.glb",
              },
              {
                type: "replacePart",
                node: [
                  "mid_leg",
                  "mid_leg1",
                  ,
                  "mid_leg10",
                  "mid_leg11",
                  "mid_leg12",
                  "mid_leg13",
                  "mid_leg14",
                  "mid_leg15",
                  "mid_leg16",
                  "mid_leg17",
                  "mid_leg18",
                  "mid_leg19",
                ],
                pid: "https://content.helloviewer.io/uploads/59663df0-1dce-4732-a5b3-fc28eb55280f/26_6_2024/1721979062801/gablenonsahringmid.glb",
              },
              {
                type: "replacePart",
                node: "left_leg",
                pid: "https://content.helloviewer.io/uploads/189065e3-6af0-42fd-b88d-9cea7a980aaa/26_6_2024/1721979274176/gablenonsahringleft.glb",
              },
              {
                type: hide,
                node: [
                  // "right_leg",
                  // "left_leg",
                  // "mid_leg",
                  "FlipUp",
                  "FlipU_reverse",
                  "Table_top_reverse",
                  "FlipU_radious",
                  "Table_top_radious",
                  "CPU_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "alumfulllaminate_mid",
                  "alumglass_mid",
                  "alumhalfandhalf_mid",
                  "glasshalfandhalflam_fabric",
                  "glasshalfandhalflam_mid",
                  "gorment",
                  "gorment2",
                  "mainscreenmetal",
                  "seprator",
                  "single",
                  "alumfullfabric_right001",
                  "alumfulllaminate_right001",
                  "alumglass_right001",
                  "midscreenmetal",
                  "midscreenwood",
                  "returnfabric001",
                  "returnglass001",
                  "returnpanelsmetal001",
                  "wooden_return001",
                  "wooden_return",
                  "returnglass",
                  "returnfabric",
                  "alumfullfabric_right",
                  "alumfulllaminate_right",
                  "alumglass_right",
                  "returnpanelsmetal",
                  "returnpanelsmetal55",
                  "sidescreenmetal",
                  "sidescreenwood",
                  "returnpanelsmetals55",
                ],
              },
            ],
            disableFeatures: ["Metal Option", "Wood Option"],
          },
          {
            name: "Two Seater",
            "Table Top Options": "Main Screen",
            size: 2,
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/plan/non/2.png",
            productId: "666ab27e4845c115a352ab38",
            tableTop: ["table_top_nonshared"],
            Category: "Plan System Linear",
            rules: [
              {
                type: "replacePart",
                node: "right_leg",
                pid: "https://content.helloviewer.io/uploads/0db4292b-e9c5-4760-a5c6-578e4290bb28/26_6_2024/1721979096104/gablenonsahringright.glb",
              },
              {
                type: "replacePart",
                node: [
                  "mid_leg",
                  "mid_leg1",
                  ,
                  "mid_leg10",
                  "mid_leg11",
                  "mid_leg12",
                  "mid_leg13",
                  "mid_leg14",
                  "mid_leg15",
                  "mid_leg16",
                  "mid_leg17",
                  "mid_leg18",
                  "mid_leg19",
                ],
                pid: "https://content.helloviewer.io/uploads/59663df0-1dce-4732-a5b3-fc28eb55280f/26_6_2024/1721979062801/gablenonsahringmid.glb",
              },
              {
                type: "replacePart",
                node: "left_leg",
                pid: "https://content.helloviewer.io/uploads/189065e3-6af0-42fd-b88d-9cea7a980aaa/26_6_2024/1721979274176/gablenonsahringleft.glb",
              },
              {
                type: hide,
                node: [
                  // "right_leg",
                  // "left_leg",
                  // "mid_leg",
                  "FlipUp",
                  "FlipU_reverse",
                  "Table_top_reverse",
                  "FlipU_radious",
                  "Table_top_radious",
                  "CPU_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "alumfulllaminate_mid",
                  "alumglass_mid",
                  "alumhalfandhalf_mid",
                  "glasshalfandhalflam_fabric",
                  "glasshalfandhalflam_mid",
                  "gorment",
                  "gorment2",
                  "mainscreenmetal",
                  "seprator",
                  "single",
                  "alumfullfabric_right001",
                  "alumfulllaminate_right001",
                  "alumglass_right001",
                  "midscreenmetal",
                  "midscreenwood",
                  "returnfabric001",
                  "returnglass001",
                  "returnpanelsmetal001",
                  "wooden_return001",
                  "wooden_return",
                  "returnglass",
                  "returnfabric",
                  "alumfullfabric_right",
                  "alumfulllaminate_right",
                  "alumglass_right",
                  "returnpanelsmetal",
                  "returnpanelsmetal55",
                  "sidescreenmetal",
                  "sidescreenwood",
                  "returnpanelsmetals55",
                ],
              },
            ],
            disableFeatures: ["Metal Option", "Wood Option"],
          },
          {
            name: "Three Seater",
            size: 3,
            "Table Top Options": "Main Screen",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/plan/non/3.png",
            productId: "66a60f3ec8a2d0e3a27547a7",
            tableTop: ["table_top_nonshared"],
            Category: "Plan System Linear",
            rules: [
              {
                type: "replacePart",
                node: "right_leg",
                pid: "https://content.helloviewer.io/uploads/0db4292b-e9c5-4760-a5c6-578e4290bb28/26_6_2024/1721979096104/gablenonsahringright.glb",
              },
              {
                type: "replacePart",
                node: [
                  "mid_leg",
                  "mid_leg1",
                  ,
                  "mid_leg10",
                  "mid_leg11",
                  "mid_leg12",
                  "mid_leg13",
                  "mid_leg14",
                  "mid_leg15",
                  "mid_leg16",
                  "mid_leg17",
                  "mid_leg18",
                  "mid_leg19",
                ],
                pid: "https://content.helloviewer.io/uploads/59663df0-1dce-4732-a5b3-fc28eb55280f/26_6_2024/1721979062801/gablenonsahringmid.glb",
              },
              {
                type: "replacePart",
                node: "left_leg",
                pid: "https://content.helloviewer.io/uploads/189065e3-6af0-42fd-b88d-9cea7a980aaa/26_6_2024/1721979274176/gablenonsahringleft.glb",
              },
              {
                type: hide,
                node: [
                  // "right_leg",
                  // "left_leg",
                  // "mid_leg",
                  "FlipUp",
                  "FlipU_reverse",
                  "Table_top_reverse",
                  "FlipU_radious",
                  "Table_top_radious",
                  "CPU_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "alumfulllaminate_mid",
                  "alumglass_mid",
                  "alumhalfandhalf_mid",
                  "glasshalfandhalflam_fabric",
                  "glasshalfandhalflam_mid",
                  "gorment",
                  "gorment2",
                  "mainscreenmetal",
                  "seprator",
                  "single",
                  "alumfullfabric_right001",
                  "alumfulllaminate_right001",
                  "alumglass_right001",
                  "midscreenmetal",
                  "midscreenwood",
                  "returnfabric001",
                  "returnglass001",
                  "returnpanelsmetal001",
                  "wooden_return001",
                  "wooden_return",
                  "returnglass",
                  "returnfabric",
                  "alumfullfabric_right",
                  "alumfulllaminate_right",
                  "alumglass_right",
                  "returnpanelsmetal",
                  "returnpanelsmetal55",
                  "sidescreenmetal",
                  "sidescreenwood",
                  "returnpanelsmetals55",
                ],
              },
            ],
            disableFeatures: ["Metal Option", "Wood Option"],
          },
          {
            name: "Four Seater",
            size: 4,
            "Table Top Options": "Main Screen",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/plan/non/4.png",
            productId: "66a6145516aacd9b1b8279df",
            tableTop: ["table_top_nonshared"],
            Category: "Plan System Linear",
            rules: [
              {
                type: "replacePart",
                node: "right_leg",
                pid: "https://content.helloviewer.io/uploads/0db4292b-e9c5-4760-a5c6-578e4290bb28/26_6_2024/1721979096104/gablenonsahringright.glb",
              },
              {
                type: "replacePart",
                node: [
                  "mid_leg",
                  "mid_leg1",
                  ,
                  "mid_leg10",
                  "mid_leg11",
                  "mid_leg12",
                  "mid_leg13",
                  "mid_leg14",
                  "mid_leg15",
                  "mid_leg16",
                  "mid_leg17",
                  "mid_leg18",
                  "mid_leg19",
                ],
                pid: "https://content.helloviewer.io/uploads/59663df0-1dce-4732-a5b3-fc28eb55280f/26_6_2024/1721979062801/gablenonsahringmid.glb",
              },
              {
                type: "replacePart",
                node: "left_leg",
                pid: "https://content.helloviewer.io/uploads/189065e3-6af0-42fd-b88d-9cea7a980aaa/26_6_2024/1721979274176/gablenonsahringleft.glb",
              },
              {
                type: hide,
                node: [
                  // "right_leg",
                  // "left_leg",
                  // "mid_leg",
                  "FlipUp",
                  "FlipU_reverse",
                  "Table_top_reverse",
                  "FlipU_radious",
                  "Table_top_radious",
                  "CPU_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "alumfulllaminate_mid",
                  "alumglass_mid",
                  "alumhalfandhalf_mid",
                  "glasshalfandhalflam_fabric",
                  "glasshalfandhalflam_mid",
                  "gorment",
                  "gorment2",
                  "mainscreenmetal",
                  "seprator",
                  "single",
                  "alumfullfabric_right001",
                  "alumfulllaminate_right001",
                  "alumglass_right001",
                  "midscreenmetal",
                  "midscreenwood",
                  "returnfabric001",
                  "returnglass001",
                  "returnpanelsmetal001",
                  "wooden_return001",
                  "wooden_return",
                  "returnglass",
                  "returnfabric",
                  "alumfullfabric_right",
                  "alumfulllaminate_right",
                  "alumglass_right",
                  "returnpanelsmetal",
                  "returnpanelsmetal55",
                  "sidescreenmetal",
                  "sidescreenwood",
                  "returnpanelsmetals55",
                ],
              },
            ],
            disableFeatures: ["Metal Option", "Wood Option"],
          },
          {
            name: "Five Seater",
            size: 5,
            "Table Top Options": "Main Screen",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/plan/non/5.png",
            productId: "66a615f351f3376a6465bcc3",
            tableTop: ["table_top_nonshared"],
            Category: "Plan System Linear",
            rules: [
              {
                type: "replacePart",
                node: "right_leg",
                pid: "https://content.helloviewer.io/uploads/0db4292b-e9c5-4760-a5c6-578e4290bb28/26_6_2024/1721979096104/gablenonsahringright.glb",
              },
              {
                type: "replacePart",
                node: [
                  "mid_leg",
                  "mid_leg1",
                  ,
                  "mid_leg10",
                  "mid_leg11",
                  "mid_leg12",
                  "mid_leg13",
                  "mid_leg14",
                  "mid_leg15",
                  "mid_leg16",
                  "mid_leg17",
                  "mid_leg18",
                  "mid_leg19",
                ],
                pid: "https://content.helloviewer.io/uploads/59663df0-1dce-4732-a5b3-fc28eb55280f/26_6_2024/1721979062801/gablenonsahringmid.glb",
              },
              {
                type: "replacePart",
                node: "left_leg",
                pid: "https://content.helloviewer.io/uploads/189065e3-6af0-42fd-b88d-9cea7a980aaa/26_6_2024/1721979274176/gablenonsahringleft.glb",
              },
              {
                type: hide,
                node: [
                  // "right_leg",
                  // "left_leg",
                  // "mid_leg",
                  "FlipUp",
                  "FlipU_reverse",
                  "Table_top_reverse",
                  "FlipU_radious",
                  "Table_top_radious",
                  "CPU_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "alumfulllaminate_mid",
                  "alumglass_mid",
                  "alumhalfandhalf_mid",
                  "glasshalfandhalflam_fabric",
                  "glasshalfandhalflam_mid",
                  "gorment",
                  "gorment2",
                  "mainscreenmetal",
                  "seprator",
                  "single",
                  "alumfullfabric_right001",
                  "alumfulllaminate_right001",
                  "alumglass_right001",
                  "midscreenmetal",
                  "midscreenwood",
                  "returnfabric001",
                  "returnglass001",
                  "returnpanelsmetal001",
                  "wooden_return001",
                  "wooden_return",
                  "returnglass",
                  "returnfabric",
                  "alumfullfabric_right",
                  "alumfulllaminate_right",
                  "alumglass_right",
                  "returnpanelsmetal",
                  "returnpanelsmetal55",
                  "sidescreenmetal",
                  "sidescreenwood",
                  "returnpanelsmetals55",
                ],
              },
            ],
            disableFeatures: ["Metal Option", "Wood Option"],
          },
          {
            name: "Six Seater",
            size: 6,
            "Table Top Options": "Main Screen",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/plan/non/6.png",
            productId: "66a617bcaff3f872b5bd273d",
            tableTop: ["table_top_nonshared"],
            Category: "Plan System Linear",
            rules: [
              {
                type: "replacePart",
                node: "right_leg",
                pid: "https://content.helloviewer.io/uploads/0db4292b-e9c5-4760-a5c6-578e4290bb28/26_6_2024/1721979096104/gablenonsahringright.glb",
              },
              {
                type: "replacePart",
                node: [
                  "mid_leg",
                  "mid_leg1",
                  ,
                  "mid_leg10",
                  "mid_leg11",
                  "mid_leg12",
                  "mid_leg13",
                  "mid_leg14",
                  "mid_leg15",
                  "mid_leg16",
                  "mid_leg17",
                  "mid_leg18",
                  "mid_leg19",
                ],
                pid: "https://content.helloviewer.io/uploads/59663df0-1dce-4732-a5b3-fc28eb55280f/26_6_2024/1721979062801/gablenonsahringmid.glb",
              },
              {
                type: "replacePart",
                node: "left_leg",
                pid: "https://content.helloviewer.io/uploads/189065e3-6af0-42fd-b88d-9cea7a980aaa/26_6_2024/1721979274176/gablenonsahringleft.glb",
              },
              {
                type: hide,
                node: [
                  // "right_leg",
                  // "left_leg",
                  // "mid_leg",
                  "FlipUp",
                  "FlipU_reverse",
                  "Table_top_reverse",
                  "FlipU_radious",
                  "Table_top_radious",
                  "CPU_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "alumfulllaminate_mid",
                  "alumglass_mid",
                  "alumhalfandhalf_mid",
                  "glasshalfandhalflam_fabric",
                  "glasshalfandhalflam_mid",
                  "gorment",
                  "gorment2",
                  "mainscreenmetal",
                  "seprator",
                  "single",
                  "alumfullfabric_right001",
                  "alumfulllaminate_right001",
                  "alumglass_right001",
                  "midscreenmetal",
                  "midscreenwood",
                  "returnfabric001",
                  "returnglass001",
                  "returnpanelsmetal001",
                  "wooden_return001",
                  "wooden_return",
                  "returnglass",
                  "returnfabric",
                  "alumfullfabric_right",
                  "alumfulllaminate_right",
                  "alumglass_right",
                  "returnpanelsmetal",
                  "returnpanelsmetal55",
                  "sidescreenmetal",
                  "sidescreenwood",
                  "returnpanelsmetals55",
                ],
              },
            ],
            disableFeatures: ["Metal Option", "Wood Option"],
          },
          // {
          //   name: "Seven Seater",
          //   size: 7,
          //   Length: 1200,
          //   Depth: 600,
          //   thumbnail: "assets/system/thumbnails/plan/non/7.png",
          //   productId: "66a61afa197ecd77e374dca2",
          //   tableTop: ["table_top_nonshared"],
          //   Category: "Plan System Linear",
          //   rules: [
          //     {
          //       type: hide,
          //       node: [
          //         "FlipUp",
          //         "FlipU_reverse",
          //         "Table_top_reverse",
          //         "FlipU_radious",
          //         "Table_top_radious",
          //         "CPU_stand",
          //         "hangingcpustand",
          //         "Key&mousetray",
          //         "Key_board_tray",
          //         "alumfulllaminate_mid",
          //         "alumglass_mid",
          //         "alumhalfandhalf_mid",
          //         "glasshalfandhalflam_fabric",
          //         "glasshalfandhalflam_mid",
          //         "gorment",
          //         "gorment2",
          //         "mainscreenmetal",
          //         "seprator",
          //         "single",
          //         "alumfullfabric_right001",
          //         "alumfulllaminate_right001",
          //         "alumglass_right001",
          //         "midscreenmetal",
          //         "midscreenwood",
          //         "returnfabric001",
          //         "returnglass001",
          //         "returnpanelsmetal001",
          //         "wooden_return001",
          //         "wooden_return",
          //         "returnglass",
          //         "returnfabric",
          //         "alumfullfabric_right",
          //         "alumfulllaminate_right",
          //         "alumglass_right",
          //         "returnpanelsmetal",
          //         "returnpanelsmetal55",
          //         "sidescreenmetal",
          //         "sidescreenwood",
          //         "returnpanelsmetals55",
          //       ],
          //     },
          //   ],
          //   disableFeatures: ["Metal Option", "Wood Option"],
          // },
          // {
          //   name: "Eight Seater",
          //   size: 8,
          //   Length: 1200,
          //   Depth: 600,
          //   thumbnail: "assets/system/thumbnails/plan/non/8.png",
          //   productId: "66a61e06d483bfa467d95357",
          //   tableTop: ["table_top_nonshared"],
          //   Category: "Plan System Linear",
          //   rules: [
          //     {
          //       type: hide,
          //       node: [
          //         "FlipUp",
          //         "FlipU_reverse",
          //         "Table_top_reverse",
          //         "FlipU_radious",
          //         "Table_top_radious",
          //         "CPU_stand",
          //         "hangingcpustand",
          //         "Key&mousetray",
          //         "Key_board_tray",
          //         "alumfulllaminate_mid",
          //         "alumglass_mid",
          //         "alumhalfandhalf_mid",
          //         "glasshalfandhalflam_fabric",
          //         "glasshalfandhalflam_mid",
          //         "gorment",
          //         "gorment2",
          //         "mainscreenmetal",
          //         "seprator",
          //         "single",
          //         "alumfullfabric_right001",
          //         "alumfulllaminate_right001",
          //         "alumglass_right001",
          //         "midscreenmetal",
          //         "midscreenwood",
          //         "returnfabric001",
          //         "returnglass001",
          //         "returnpanelsmetal001",
          //         "wooden_return001",
          //         "wooden_return",
          //         "returnglass",
          //         "returnfabric",
          //         "alumfullfabric_right",
          //         "alumfulllaminate_right",
          //         "alumglass_right",
          //         "returnpanelsmetal",
          //         "returnpanelsmetal55",
          //         "sidescreenmetal",
          //         "sidescreenwood",
          //         "returnpanelsmetals55",
          //       ],
          //     },
          //   ],
          //   disableFeatures: ["Metal Option", "Wood Option"],
          // },
          // {
          //   name: "Nine Seater",
          //   size: 9,
          //   Length: 1200,
          //   Depth: 600,
          //   thumbnail: "assets/system/thumbnails/plan/non/9.png",
          //   productId: "66a61f6724bca88a46ee4216",
          //   tableTop: ["table_top_nonshared"],
          //   Category: "Plan System Linear",
          //   rules: [
          //     {
          //       type: hide,
          //       node: [
          //         "FlipUp",
          //         "FlipU_reverse",
          //         "Table_top_reverse",
          //         "FlipU_radious",
          //         "Table_top_radious",
          //         "CPU_stand",
          //         "hangingcpustand",
          //         "Key&mousetray",
          //         "Key_board_tray",
          //         "alumfulllaminate_mid",
          //         "alumglass_mid",
          //         "alumhalfandhalf_mid",
          //         "glasshalfandhalflam_fabric",
          //         "glasshalfandhalflam_mid",
          //         "gorment",
          //         "gorment2",
          //         "mainscreenmetal",
          //         "seprator",
          //         "single",
          //         "alumfullfabric_right001",
          //         "alumfulllaminate_right001",
          //         "alumglass_right001",
          //         "midscreenmetal",
          //         "midscreenwood",
          //         "returnfabric001",
          //         "returnglass001",
          //         "returnpanelsmetal001",
          //         "wooden_return001",
          //         "wooden_return",
          //         "returnglass",
          //         "returnfabric",
          //         "alumfullfabric_right",
          //         "alumfulllaminate_right",
          //         "alumglass_right",
          //         "returnpanelsmetal",
          //         "returnpanelsmetal55",
          //         "sidescreenmetal",
          //         "sidescreenwood",
          //         "returnpanelsmetals55",
          //       ],
          //     },
          //   ],
          //   disableFeatures: ["Metal Option", "Wood Option"],
          // },
          // {
          //   name: "Ten Seater",
          //   size: 10,
          //   Length: 1200,
          //   Depth: 600,
          //   thumbnail: "assets/system/thumbnails/plan/non/10.png",
          //   productId: "66a62222011b95f3804fff2e",
          //   tableTop: ["table_top_nonshared"],
          //   Category: "Plan System Linear",
          //   rules: [
          //     {
          //       type: hide,
          //       node: [
          //         "FlipUp",
          //         "FlipU_reverse",
          //         "Table_top_reverse",
          //         "FlipU_radious",
          //         "Table_top_radious",
          //         "CPU_stand",
          //         "hangingcpustand",
          //         "Key&mousetray",
          //         "Key_board_tray",
          //         "alumfulllaminate_mid",
          //         "alumglass_mid",
          //         "alumhalfandhalf_mid",
          //         "glasshalfandhalflam_fabric",
          //         "glasshalfandhalflam_mid",
          //         "gorment",
          //         "gorment2",
          //         "mainscreenmetal",
          //         "seprator",
          //         "single",
          //         "alumfullfabric_right001",
          //         "alumfulllaminate_right001",
          //         "alumglass_right001",
          //         "midscreenmetal",
          //         "midscreenwood",
          //         "returnfabric001",
          //         "returnglass001",
          //         "returnpanelsmetal001",
          //         "wooden_return001",
          //         "wooden_return",
          //         "returnglass",
          //         "returnfabric",
          //         "alumfullfabric_right",
          //         "alumfulllaminate_right",
          //         "alumglass_right",
          //         "returnpanelsmetal",
          //         "returnpanelsmetal55",
          //         "sidescreenmetal",
          //         "sidescreenwood",
          //         "returnpanelsmetals55",
          //       ],
          //     },
          //   ],
          //   disableFeatures: ["Metal Option", "Wood Option"],
          // },
          // {
          //   name: "Eleven Seater",
          //   size: 11,
          //   Length: 1200,
          //   Depth: 600,
          //   thumbnail: "assets/system/thumbnails/plan/non/11.png",
          //   productId: "66a624c9cbf80d4c5066170d",
          //   tableTop: ["table_top_nonshared"],
          //   Category: "Plan System Linear",
          //   rules: [
          //     {
          //       type: hide,
          //       node: [
          //         "FlipUp",
          //         "FlipU_reverse",
          //         "Table_top_reverse",
          //         "FlipU_radious",
          //         "Table_top_radious",
          //         "CPU_stand",
          //         "hangingcpustand",
          //         "Key&mousetray",
          //         "Key_board_tray",
          //         "alumfulllaminate_mid",
          //         "alumglass_mid",
          //         "alumhalfandhalf_mid",
          //         "glasshalfandhalflam_fabric",
          //         "glasshalfandhalflam_mid",
          //         "gorment",
          //         "gorment2",
          //         "mainscreenmetal",
          //         "seprator",
          //         "single",
          //         "alumfullfabric_right001",
          //         "alumfulllaminate_right001",
          //         "alumglass_right001",
          //         "midscreenmetal",
          //         "midscreenwood",
          //         "returnfabric001",
          //         "returnglass001",
          //         "returnpanelsmetal001",
          //         "wooden_return001",
          //         "wooden_return",
          //         "returnglass",
          //         "returnfabric",
          //         "alumfullfabric_right",
          //         "alumfulllaminate_right",
          //         "alumglass_right",
          //         "returnpanelsmetal",
          //         "returnpanelsmetal55",
          //         "sidescreenmetal",
          //         "sidescreenwood",
          //         "returnpanelsmetals55",
          //       ],
          //     },
          //   ],
          //   disableFeatures: ["Metal Option", "Wood Option"],
          // },
          // {
          //   name: "Twelve Seater",
          //   size: 12,
          //   Length: 1200,
          //   Depth: 600,
          //   thumbnail: "assets/system/thumbnails/plan/non/12.png",
          //   productId: "66a6263e011b95f3804ffffa",
          //   tableTop: ["table_top_nonshared"],
          //   Category: "Plan System Linear",
          //   rules: [
          //     {
          //       type: hide,
          //       node: [
          //         "FlipUp",
          //         "FlipU_reverse",
          //         "Table_top_reverse",
          //         "FlipU_radious",
          //         "Table_top_radious",
          //         "CPU_stand",
          //         "hangingcpustand",
          //         "Key&mousetray",
          //         "Key_board_tray",
          //         "alumfulllaminate_mid",
          //         "alumglass_mid",
          //         "alumhalfandhalf_mid",
          //         "glasshalfandhalflam_fabric",
          //         "glasshalfandhalflam_mid",
          //         "gorment",
          //         "gorment2",
          //         "mainscreenmetal",
          //         "seprator",
          //         "single",
          //         "alumfullfabric_right001",
          //         "alumfulllaminate_right001",
          //         "alumglass_right001",
          //         "midscreenmetal",
          //         "midscreenwood",
          //         "returnfabric001",
          //         "returnglass001",
          //         "returnpanelsmetal001",
          //         "wooden_return001",
          //         "wooden_return",
          //         "returnglass",
          //         "returnfabric",
          //         "alumfullfabric_right",
          //         "alumfulllaminate_right",
          //         "alumglass_right",
          //         "returnpanelsmetal",
          //         "returnpanelsmetal55",
          //         "sidescreenmetal",
          //         "sidescreenwood",
          //         "returnpanelsmetals55",
          //       ],
          //     },
          //   ],
          //   disableFeatures: ["Metal Option", "Wood Option"],
          // },
        ],
      },
    ],
  },

  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              // {
              //   name: "750 mm",
              //   value: 750,
              //   rules: [
              //     {
              //       type: hide,

              //       node: ["seprator"],
              //     },
              //   ],
              // },
              {
                name: "900 mm",
                value: 900,
                rules: [
                  {
                    type: hide,

                    node: ["seprator"],
                  },
                ],
              },
              {
                name: "1050 mm",
                value: 1050,
                rules: [
                  {
                    type: show,

                    node: ["seprator"],
                  },
                ],
              },
              {
                name: "1200 mm",
                value: 1200,
                rules: [
                  {
                    type: show,

                    node: ["seprator"],
                  },
                ],
              },
              {
                name: "1350 mm",
                value: 1350,
                rules: [
                  {
                    type: show,

                    node: ["seprator"],
                  },
                ],
              },
              {
                name: "1500 mm",
                value: 1500,
                rules: [
                  {
                    type: show,

                    node: ["seprator"],
                  },
                ],
              },
              // {
              //   name: "1650 mm",
              //   value: 1650,
              //   rules: [
              //     {
              //       type: show,

              //       node: ["seprator"],
              //     },
              //   ],
              // },
              // {
              //   name: "1800 mm",
              //   value: 1800,
              //   rules: [
              //     {
              //       type: show,

              //       node: ["seprator"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
          // {
          //   name: "Height",
          //   required: false,
          //   dropDown: true,
          //   options: [
          //     {
          //       name: "1200 mm",
          //       value: 1200,
          //     },
          //   ],
          // },
        ],
      },
      {
        name: "NON SHARING",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              // {
              //   name: "750 mm",
              //   value: 750,
              //   rules: [
              //     {
              //       type: hide,

              //       node: ["seprator"],
              //     },
              //   ],
              // },
              {
                name: "900 mm",
                value: 900,
                rules: [
                  {
                    type: hide,

                    node: ["seprator"],
                  },
                ],
              },
              {
                name: "1050 mm",
                value: 1050,
                rules: [
                  {
                    type: show,

                    node: ["seprator"],
                  },
                ],
              },
              {
                name: "1200 mm",
                value: 1200,
                rules: [
                  {
                    type: show,

                    node: ["seprator"],
                  },
                ],
              },
              {
                name: "1350 mm",
                value: 1350,
                rules: [
                  {
                    type: show,

                    node: ["seprator"],
                  },
                ],
              },
              {
                name: "1500 mm",
                value: 1500,
                rules: [
                  {
                    type: show,

                    node: ["seprator"],
                  },
                ],
              },
              // {
              //   name: "1650 mm",
              //   value: 1650,
              //   rules: [
              //     {
              //       type: show,

              //       node: ["seprator"],
              //     },
              //   ],
              // },
              // {
              //   name: "1800 mm",
              //   value: 1800,
              //   rules: [
              //     {
              //       type: show,

              //       node: ["seprator"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
          // {
          //   name: "Height",
          //   required: false,
          //   dropDown: true,
          //   options: [
          //     {
          //       name: "1200 mm",
          //       value: 1200,
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
  {
    name: "Legs",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Plan Legs",
            required: false,
            options: [
              // {
              //   name: "Eco",
              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",

              //   enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       // pid: "66387f0930180877c5875bb1",
              //       pid: "https://content.helloviewer.io/uploads/34209c94-12d8-4bc5-b5f5-d42eb342c36d/29_6_2024/1722248443517/ecoleft.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/6bd19427-29ca-48d7-9366-385d55b431d0/29_6_2024/1722248417056/ecoright.glb", //"6638d0447f3062d784ce6ab6",
              //     },

              //     {
              //       type: "replacePart",
              //       node: [
              //         "mid_leg",
              //         "mid_leg1",
              //         ,
              //         "mid_leg10",
              //         "mid_leg11",
              //         "mid_leg12",
              //         "mid_leg13",
              //         "mid_leg14",
              //         "mid_leg15",
              //         "mid_leg16",
              //         "mid_leg17",
              //         "mid_leg18",
              //         "mid_leg19",
              //       ],

              //       pid: "https://content.helloviewer.io/uploads/41ac19f8-47ef-4173-9a02-416caa0b55f6/25_6_2024/1721913117450/mid-eco-legs.glb", //"6638d0447f3062d784ce6ab6",
              //     },
              //     {
              //       type: hide,
              //       condition: [
              //         {
              //           feature: "Return Panels",
              //           values: ["Metal", "Wood"],
              //         },
              //       ],
              //       node: ["mid_leg", "right_leg", "left_leg"],
              //     },
              //   ],
              // },
              // {
              //   name: "IO",
              //   thumbnail: "assets/system/thumbnails/Parts/Io.png",

              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/5e5c059e-79de-432a-b097-dec40e9c1aaa/21_5_2024/1718972212300/leftlegio.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/1b9ab124-3148-4106-8303-0378827ecec6/21_5_2024/1718972241616/rightlegio.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: [
              //         "mid_leg",
              //         "mid_leg1",
              //         ,
              //         "mid_leg10",
              //         "mid_leg11",
              //         "mid_leg12",
              //         "mid_leg13",
              //         "mid_leg14",
              //         "mid_leg15",
              //         "mid_leg16",
              //         "mid_leg17",
              //         "mid_leg18",
              //         "mid_leg19",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/c2ec2d7a-311f-47df-9500-fd14c37c5684/25_6_2024/1721913164420/midlegio.glb", //"6638d0447f3062d784ce6ab6",
              //     },
              //     {
              //       type: hide,
              //       condition: [
              //         {
              //           feature: "Return Panels",
              //           values: ["Metal", "Wood"],
              //         },
              //       ],
              //       node: ["mid_leg", "right_leg", "left_leg"],
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: ["FlipUp@work"],
              //     },

              //     // {
              //     //   type: "hide",
              //     //   node: [
              //     //     "metalmodestymid@work",
              //     //     "prelammid@work",
              //     //     "prelamleft",
              //     //     "prelamright",
              //     //     "metalmodestyleft",
              //     //     "metalmodestyright",
              //     //     "workconnect",
              //     //   ],
              //     // },
              //   ],
              // },
              {
                name: "Prelam",
                thumbnail: "assets/system/thumbnails/Parts/prelam.png",
                enableFeatures: "ATT Options",

                enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray", ],

                rules: [
                  // {
                  //   type: "replacePart",
                  //   node: [
                  //     "mid_leg",
                  //     "mid_leg1",
                  //     ,
                  //     "mid_leg10",
                  //     "mid_leg11",
                  //     "mid_leg12",
                  //     "mid_leg13",
                  //     "mid_leg14",
                  //     "mid_leg15",
                  //     "mid_leg16",
                  //     "mid_leg17",
                  //     "mid_leg18",
                  //     "mid_leg19",
                  //   ],
                  //   pid: "https://content.helloviewer.io/uploads/0a8e3cf8-61f8-4167-afdc-a868e0ed01f0/25_6_2024/1721913216694/midleggamble.glb",
                  // },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/1b19f409-6879-4752-90d5-6e2dc15105d0/26_6_2024/1721976304187/rightleggamble.glb",
                  },
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/5bdffc46-3cdb-47dd-b97c-59a20ef15b34/26_6_2024/1721976327824/leftleggamble.glb",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Return Panels",
                        values: ["Metal", "Wood"],
                      },
                    ],
                    node: ["mid_leg", "right_leg", "left_leg"],
                  },
                  // {
                  //   type: "hide",
                  //   node: [
                  //     "workconnect",
                  //     "metalmodestymid@work",
                  //     "prelammid@work",
                  //     "prelamleft",
                  //     "prelamright",
                  //     "metalmodestyleft",
                  //     "metalmodestyright",
                  //   ],
                  // },
                  {
                    type: "hide",
                    node: ["FlipUp@work"],
                  },

                  // {
                  //   type: "hide",
                  //   condition: [
                  //     {
                  //       feature: "Mid Panel",
                  //       values: ["With Mid Panel"],
                  //     },
                  //   ],
                  //   node: ["connecter", "workconnect"],
                  // },
                ],
              },
              {
                name: "29mm Return Frame",
                required: false,
                enableFeatures: [
                  "Metal Option",
                  "Return Frame Pannel Dimension", "None"
                ],
                disableFeatures: ["Wood Option", "Return  Pannel Dimension", "ATT Options"],
                rules: [
                  {
                    type: show,
                    node: [
                      "returnpanelsmetal",
                      "alumglass_right",
                      "sidescreenwood",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "returnglass",
                      "returnfabric",
                      "wooden_return",
                      "left_leg",
                      "right_leg",
                    ],
                  },
                ],
              },
              {
                name: "50mm Return Frame",
                required: false,
                enableFeatures: [
                  "Metal Option",
                  "Return Frame Pannel Dimension", "None"
                ],
                disableFeatures: ["Wood Option", "Return  Pannel Dimension", "ATT Options"],
                rules: [
                  {
                    type: show,
                    node: [
                      "returnpanelsmetal",
                      "alumglass_right",
                      "sidescreenwood",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "returnglass",
                      "returnfabric",
                      "wooden_return",
                      "left_leg",
                      "right_leg",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "ATT Options",
            required: false,
            options: [
              // {
              //   name: "Eco",
              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",

              //   enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       // pid: "66387f0930180877c5875bb1",
              //       pid: "https://content.helloviewer.io/uploads/34209c94-12d8-4bc5-b5f5-d42eb342c36d/29_6_2024/1722248443517/ecoleft.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/6bd19427-29ca-48d7-9366-385d55b431d0/29_6_2024/1722248417056/ecoright.glb", //"6638d0447f3062d784ce6ab6",
              //     },

              //     {
              //       type: "replacePart",
              //       node: [
              //         "mid_leg",
              //         "mid_leg1",
              //         ,
              //         "mid_leg10",
              //         "mid_leg11",
              //         "mid_leg12",
              //         "mid_leg13",
              //         "mid_leg14",
              //         "mid_leg15",
              //         "mid_leg16",
              //         "mid_leg17",
              //         "mid_leg18",
              //         "mid_leg19",
              //       ],

              //       pid: "https://content.helloviewer.io/uploads/41ac19f8-47ef-4173-9a02-416caa0b55f6/25_6_2024/1721913117450/mid-eco-legs.glb", //"6638d0447f3062d784ce6ab6",
              //     },
              //     {
              //       type: hide,
              //       condition: [
              //         {
              //           feature: "Return Panels",
              //           values: ["Metal", "Wood"],
              //         },
              //       ],
              //       node: ["mid_leg", "right_leg", "left_leg"],
              //     },
              //   ],
              // },
              // {
              //   name: "IO",
              //   thumbnail: "assets/system/thumbnails/Parts/Io.png",

              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/5e5c059e-79de-432a-b097-dec40e9c1aaa/21_5_2024/1718972212300/leftlegio.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/1b9ab124-3148-4106-8303-0378827ecec6/21_5_2024/1718972241616/rightlegio.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: [
              //         "mid_leg",
              //         "mid_leg1",
              //         ,
              //         "mid_leg10",
              //         "mid_leg11",
              //         "mid_leg12",
              //         "mid_leg13",
              //         "mid_leg14",
              //         "mid_leg15",
              //         "mid_leg16",
              //         "mid_leg17",
              //         "mid_leg18",
              //         "mid_leg19",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/c2ec2d7a-311f-47df-9500-fd14c37c5684/25_6_2024/1721913164420/midlegio.glb", //"6638d0447f3062d784ce6ab6",
              //     },
              //     {
              //       type: hide,
              //       condition: [
              //         {
              //           feature: "Return Panels",
              //           values: ["Metal", "Wood"],
              //         },
              //       ],
              //       node: ["mid_leg", "right_leg", "left_leg"],
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: ["FlipUp@work"],
              //     },

              //     // {
              //     //   type: "hide",
              //     //   node: [
              //     //     "metalmodestymid@work",
              //     //     "prelammid@work",
              //     //     "prelamleft",
              //     //     "prelamright",
              //     //     "metalmodestyleft",
              //     //     "metalmodestyright",
              //     //     "workconnect",
              //     //   ],
              //     // },
              //   ],
              // },
              {
                name: "On Desk Glass",
                required: false,
                disableFeatures: [
                  "Metal Option",
                  "Wood Option",
                  "Return Frame Pannel Dimension",
                ],
                enableFeatures: ["Return  Pannel Dimension"],
                rules: [
                  {
                    type: show,
                    node: ["returnfabric"],
                  },
                  // {
                  //   type: hide,
                  //   node: [
                  //     "returnglass",
                  //     "returnpanelsmetal",
                  //     "wooden_return",
                  //     "alumglass_right",
                  //     "sidescreenmetal",
                  //     "sidescreenwood",
                  //     "sidescreenmetal",
                  //   ],
                  // },
                ],
              },
              {
                name: "On Desk Fabric",
                required: false,
                disableFeatures: [
                  "Metal Option",
                  "Wood Option",
                  "Return Frame Pannel Dimension",
                ],
                enableFeatures: ["Return  Pannel Dimension"],
                rules: [
                  {
                    type: show,
                    node: ["returnglass"],
                  },
                  // {
                  //   type: hide,
                  //   node: [
                  //     "returnfabric",
                  //     "returnpanelsmetal",
                  //     "wooden_return",
                  //     "alumglass_right",
                  //     "sidescreenmetal",
                  //     "sidescreenwood",
                  //     "sidescreenmetal",
                  //   ],
                  // },
                ],
              },
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                required: false,
                disableFeatures: [
                  "Metal Option",
                  "Wood Option",
                  "Return Frame Pannel Dimension",
                ],
                enableFeatures: ["Return  Pannel Dimension"],
                rules: [
                  {
                    type: hide,
                    node: ["returnglass", "returnfabric"],
                  },
                  // {
                  //   type: hide,
                  //   node: [
                  //     "returnfabric",
                  //     "returnpanelsmetal",
                  //     "wooden_return",
                  //     "alumglass_right",
                  //     "sidescreenmetal",
                  //     "sidescreenwood",
                  //     "sidescreenmetal",
                  //   ],
                  // },
                ],
              },
              
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Plan Legs(NS)",
            required: false,
            options: [
              // {
              //   name: "Eco",
              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",
              //   // enableFeatures: ["Color Options"],
              //   enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       // pid: "66387f0930180877c5875bb1",
              //       pid: "https://content.helloviewer.io/uploads/c1789ba0-8920-4578-aae6-2fbbe2435060/26_6_2024/1721978736781/econonsharinglt.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/5e3df899-bcac-4627-bdaa-c799a1c4b41c/26_6_2024/1721978643702/econonsharingrt.glb", //"6638d0447f3062d784ce6ab6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: [
              //         "mid_leg",
              //         "mid_leg1",
              //         ,
              //         "mid_leg10",
              //         "mid_leg11",
              //         "mid_leg12",
              //         "mid_leg13",
              //         "mid_leg14",
              //         "mid_leg15",
              //         "mid_leg16",
              //         "mid_leg17",
              //         "mid_leg18",
              //         "mid_leg19",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/d52a4ee9-2bcb-4097-952c-55273973edd2/26_6_2024/1721978692213/econonsharingmid.glb", //"6638d0447f3062d784ce6ab6",
              //     },
              //     {
              //       type: hide,
              //       condition: [
              //         {
              //           feature: "Return Panels",
              //           values: ["Metal", "Wood"],
              //         },
              //       ],
              //       node: ["mid_leg", "right_leg", "left_leg"],
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: ["FlipUp@work"],
              //     },

              //     {
              //       type: "hide",
              //       node: [
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //         "workconnect",
              //       ],
              //     },
              //     // {
              //     //   type: "hide",
              //     //   condition: [
              //     //     {
              //     //       feature: "Mid Panel",
              //     //       values: ["With Mid Panel"],
              //     //     },
              //     //   ],
              //     //   node: ["connecter", "workconnect"],
              //     // },
              //   ],
              // },
              // {
              //   name: "IO",
              //   thumbnail: "assets/system/thumbnails/Parts/Io.png",
              //   // enableFeatures: ["Color Options"],

              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/f68ecab3-f1ad-4393-a457-1c7071b726c0/26_6_2024/1721978962629/iononsahringleft.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/f0a96c4d-81cf-4fb5-aefb-a1926464d7b9/26_6_2024/1721978885704/iononsahringright.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: [
              //         "mid_leg",
              //         "mid_leg1",
              //         ,
              //         "mid_leg10",
              //         "mid_leg11",
              //         "mid_leg12",
              //         "mid_leg13",
              //         "mid_leg14",
              //         "mid_leg15",
              //         "mid_leg16",
              //         "mid_leg17",
              //         "mid_leg18",
              //         "mid_leg19",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/3a7e3afc-3bd8-4899-bdd1-c1ba8df63642/26_6_2024/1721978922225/iononsahringmid.glb", //"6638d0447f3062d784ce6ab6",
              //     },
              //     {
              //       type: hide,
              //       condition: [
              //         {
              //           feature: "Return Panels",
              //           values: ["Metal", "Wood"],
              //         },
              //       ],
              //       node: ["mid_leg", "right_leg", "left_leg"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "connecter",
              //         "prelammid",
              //         "metalmodestymid",
              //         "prelamleft",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //         "prelamright",
              //       ],
              //     },
              //     {
              //       type: "show",
              //       node: ["workconnect"],
              //     },
              //   ],
              // },
              {
                name: "Prelam",
                thumbnail: "assets/system/thumbnails/Parts/prelam.png",
                enableFeatures: "ATT Options",

                enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: [
                      "mid_leg",
                      "mid_leg1",
                      ,
                      "mid_leg10",
                      "mid_leg11",
                      "mid_leg12",
                      "mid_leg13",
                      "mid_leg14",
                      "mid_leg15",
                      "mid_leg16",
                      "mid_leg17",
                      "mid_leg18",
                      "mid_leg19",
                    ],
                    pid: "https://content.helloviewer.io/uploads/59663df0-1dce-4732-a5b3-fc28eb55280f/26_6_2024/1721979062801/gablenonsahringmid.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/0db4292b-e9c5-4760-a5c6-578e4290bb28/26_6_2024/1721979096104/gablenonsahringright.glb",
                  },
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/189065e3-6af0-42fd-b88d-9cea7a980aaa/26_6_2024/1721979274176/gablenonsahringleft.glb",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Return Panels",
                        values: ["Metal", "Wood"],
                      },
                    ],
                    node: ["mid_leg", "right_leg", "left_leg"],
                  },
                  {
                    type: "hide",
                    node: [
                      "workconnect",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                  {
                    type: "hide",
                    node: ["FlipUp@work"],
                  },
                ],
              },
              {
                name: "29mm Return Frame",
                required: false,
                enableFeatures: [
                  "Metal Option",
                  "Return Frame Pannel Dimension",
                ],
                disableFeatures: ["Wood Option", "Return  Pannel Dimension", "ATT Options"],
                rules: [
                  {
                    type: show,
                    node: [
                      "returnpanelsmetal",
                      "alumglass_right",
                      "sidescreenwood",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "returnglass",
                      "returnfabric",
                      "wooden_return",
                      "left_leg",
                      "right_leg",
                    ],
                  },
                ],
              },
              {
                name: "50mm Return Frame",
                required: false,
                enableFeatures: [
                  "Metal Option",
                  "Return Frame Pannel Dimension",
                ],
                disableFeatures: ["Wood Option", "Return  Pannel Dimension", "ATT Options"],
                rules: [
                  {
                    type: show,
                    node: [
                      "returnpanelsmetal",
                      "alumglass_right",
                      "sidescreenwood",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "returnglass",
                      "returnfabric",
                      "wooden_return",
                      "left_leg",
                      "right_leg",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "ATT Options",
            required: false,
            options: [
              // {
              //   name: "Eco",
              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",

              //   enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       // pid: "66387f0930180877c5875bb1",
              //       pid: "https://content.helloviewer.io/uploads/34209c94-12d8-4bc5-b5f5-d42eb342c36d/29_6_2024/1722248443517/ecoleft.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/6bd19427-29ca-48d7-9366-385d55b431d0/29_6_2024/1722248417056/ecoright.glb", //"6638d0447f3062d784ce6ab6",
              //     },

              //     {
              //       type: "replacePart",
              //       node: [
              //         "mid_leg",
              //         "mid_leg1",
              //         ,
              //         "mid_leg10",
              //         "mid_leg11",
              //         "mid_leg12",
              //         "mid_leg13",
              //         "mid_leg14",
              //         "mid_leg15",
              //         "mid_leg16",
              //         "mid_leg17",
              //         "mid_leg18",
              //         "mid_leg19",
              //       ],

              //       pid: "https://content.helloviewer.io/uploads/41ac19f8-47ef-4173-9a02-416caa0b55f6/25_6_2024/1721913117450/mid-eco-legs.glb", //"6638d0447f3062d784ce6ab6",
              //     },
              //     {
              //       type: hide,
              //       condition: [
              //         {
              //           feature: "Return Panels",
              //           values: ["Metal", "Wood"],
              //         },
              //       ],
              //       node: ["mid_leg", "right_leg", "left_leg"],
              //     },
              //   ],
              // },
              // {
              //   name: "IO",
              //   thumbnail: "assets/system/thumbnails/Parts/Io.png",

              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/5e5c059e-79de-432a-b097-dec40e9c1aaa/21_5_2024/1718972212300/leftlegio.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/1b9ab124-3148-4106-8303-0378827ecec6/21_5_2024/1718972241616/rightlegio.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: [
              //         "mid_leg",
              //         "mid_leg1",
              //         ,
              //         "mid_leg10",
              //         "mid_leg11",
              //         "mid_leg12",
              //         "mid_leg13",
              //         "mid_leg14",
              //         "mid_leg15",
              //         "mid_leg16",
              //         "mid_leg17",
              //         "mid_leg18",
              //         "mid_leg19",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/c2ec2d7a-311f-47df-9500-fd14c37c5684/25_6_2024/1721913164420/midlegio.glb", //"6638d0447f3062d784ce6ab6",
              //     },
              //     {
              //       type: hide,
              //       condition: [
              //         {
              //           feature: "Return Panels",
              //           values: ["Metal", "Wood"],
              //         },
              //       ],
              //       node: ["mid_leg", "right_leg", "left_leg"],
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: ["FlipUp@work"],
              //     },

              //     // {
              //     //   type: "hide",
              //     //   node: [
              //     //     "metalmodestymid@work",
              //     //     "prelammid@work",
              //     //     "prelamleft",
              //     //     "prelamright",
              //     //     "metalmodestyleft",
              //     //     "metalmodestyright",
              //     //     "workconnect",
              //     //   ],
              //     // },
              //   ],
              // },
              {
                name: "On Desk Glass",
                required: false,
                disableFeatures: [
                  "Metal Option",
                  "Wood Option",
                  "Return Frame Pannel Dimension",
                ],
                enableFeatures: ["Return  Pannel Dimension"],
                rules: [
                  {
                    type: show,
                    node: ["returnfabric"],
                  },
                  // {
                  //   type: hide,
                  //   node: [
                  //     "returnglass",
                  //     "returnpanelsmetal",
                  //     "wooden_return",
                  //     "alumglass_right",
                  //     "sidescreenmetal",
                  //     "sidescreenwood",
                  //     "sidescreenmetal",
                  //   ],
                  // },
                ],
              },
              {
                name: "On Desk Fabric",
                required: false,
                disableFeatures: [
                  "Metal Option",
                  "Wood Option",
                  "Return Frame Pannel Dimension",
                ],
                enableFeatures: ["Return  Pannel Dimension"],
                rules: [
                  {
                    type: show,
                    node: ["returnglass"],
                  },
                  // {
                  //   type: hide,
                  //   node: [
                  //     "returnfabric",
                  //     "returnpanelsmetal",
                  //     "wooden_return",
                  //     "alumglass_right",
                  //     "sidescreenmetal",
                  //     "sidescreenwood",
                  //     "sidescreenmetal",
                  //   ],
                  // },
                ],
              },
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                required: false,
                disableFeatures: [
                  "Metal Option",
                  "Wood Option",
                  "Return Frame Pannel Dimension",
                ],
                enableFeatures: ["Return  Pannel Dimension"],
                rules: [
                  {
                    type: hide,
                    node: ["returnglass", "returnfabric"],
                  },
                  // {
                  //   type: hide,
                  //   node: [
                  //     "returnfabric",
                  //     "returnpanelsmetal",
                  //     "wooden_return",
                  //     "alumglass_right",
                  //     "sidescreenmetal",
                  //     "sidescreenwood",
                  //     "sidescreenmetal",
                  //   ],
                  // },
                ],
              },
              
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Table Top",
    multiSelect: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Table Top Options",
            required: false,

            options: [
              {
                name: "Plain(S)",
                thumbnail: "assets/system/thumbnails/Parts/plain.png",
                disableFeatures: [
                  "Reverse Waterfall Raceway Options",
                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Plain Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "FlipU",
                    ],
                  },
                ],
              },
              // {
              //   name: "Reverse waterfall(S)",
              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",
              //   disableFeatures: [
              //     "Plain Raceway Options",

              //     "Side Radius Raceway Options",
              //   ],
              //   enableFeatures: ["Reverse Waterfall Raceway Options"],
              //   rules: [
              //     {
              //       type: show,
              //       node: ["Table_top_reverse"],
              //     },
              //     {
              //       type: hide,
              //       node: ["Table_top_radious", "Table_top"],
              //     },
              //     {
              //       type: hide,
              //       node: [
              //         "Table_top_radious",

              //         "FlipU_radious",
              //         "FlipU_raser_radious",
              //         "FlipU_vertibrae_radious",
              //         "FlipU_raser",
              //         "FlipU_vertibrae",
              //         "FlipU_reverse",
              //         "FlipU_raser_reverse",
              //         "FlipU_vertibrae_reverse",
              //         "Table_top",
              //         ,
              //         "FlipU",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "Side radius(S)",
              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",
              //   disableFeatures: [
              //     "Plain Raceway Options",
              //     "Reverse Waterfall Raceway Options",
              //   ],
              //   enableFeatures: ["Side Radius Raceway Options"],
              //   rules: [
              //     {
              //       type: show,
              //       node: ["Table_top_radious"],
              //     },
              //     {
              //       type: hide,
              //       node: ["Table_top_reverse", "Table_top"],
              //     },
              //     {
              //       type: hide,
              //       node: [
              //         "Table_top_reverse",
              //         "FlipU_radious",
              //         "FlipU_raser_radious",
              //         "FlipU_vertibrae_radious",
              //         "FlipU_raser",
              //         "FlipU_vertibrae",
              //         "FlipU_reverse",
              //         "FlipU_raser_reverse",
              //         "FlipU_vertibrae_reverse",
              //         "Table_top",
              //         ,
              //         "FlipU",
              //       ],
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Table Top Options",
            required: false,

            options: [
              {
                name: "Plain(NS)",
                thumbnail: "assets/system/thumbnails/Parts/plain.png",
                disableFeatures: [
                  "Reverse Waterfall Raceway Options",
                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Plain Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "FlipU",
                    ],
                  },
                ],
              },
              // {
              //   name: "Reverse waterfall(NS)",
              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",
              //   disableFeatures: [
              //     "Plain Raceway Options",

              //     "Side Radius Raceway Options",
              //   ],
              //   enableFeatures: ["Reverse Waterfall Raceway Options"],
              //   rules: [
              //     {
              //       type: show,
              //       node: ["Table_top_reverse"],
              //     },
              //     {
              //       type: hide,
              //       node: ["Table_top_radious", "Table_top"],
              //     },
              //     {
              //       type: hide,
              //       node: [
              //         "Table_top_radious",

              //         "FlipU_radious",
              //         "FlipU_raser_radious",
              //         "FlipU_vertibrae_radious",
              //         "FlipU_raser",
              //         "FlipU_vertibrae",
              //         "FlipU_reverse",
              //         "FlipU_raser_reverse",
              //         "FlipU_vertibrae_reverse",
              //         "Table_top",
              //         "FlipU",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "Side radius(NS)",
              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",
              //   disableFeatures: [
              //     "Plain Raceway Options",
              //     "Reverse Waterfall Raceway Options",
              //   ],
              //   enableFeatures: ["Side Radius Raceway Options"],
              //   rules: [
              //     {
              //       type: show,
              //       node: ["Table_top_radious"],
              //     },
              //     {
              //       type: hide,
              //       node: ["Table_top_reverse", "Table_top"],
              //     },
              //     {
              //       type: hide,
              //       node: [
              //         "Table_top_reverse",
              //         "FlipU_radious",
              //         "FlipU_raser_radious",
              //         "FlipU_vertibrae_radious",
              //         "FlipU_raser",
              //         "FlipU_vertibrae",
              //         "FlipU_reverse",
              //         "FlipU_raser_reverse",
              //         "FlipU_vertibrae_reverse",
              //         "Table_top",
              //         "FlipU",
              //       ],
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Wire Management",
    multiSelect: true,
    optional: false,
    default: "FlipUp",
    options: [
      {
        name: "SHARING",
        required: false,

        options: [
          {
            name: "Raceway",
            required: false,

            thumbnail: "/assets/components/ColorsSharing.png",

            rules: [
              {
                type: hide,
                node: ["FlipUp", "gorment", "Raceway", "midscreen"],
              },
            ],
            options: [
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                default: true,
                enableOptions: ["Mid Prelam", "Mid Metal"],

                rules: [
                  {
                    type: show,

                    node: ["FlipUp", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,

                    node: [
                      "Table_top",
                      "gorment2",
                      "gorment",
                      "gorment@work",
                      "mid_leg_gorment",
                      "gorment2@work",
                      "midscreen",
                      "mainscreen",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Eco", "Noah", "Scope"],
                      },
                    ],
                    node: [
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "gorment@work",
                      "gorment2@work",
                    ],
                  },

                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["FlipUp", "gorment2@work", "connecter"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["FlipUp@work"],
                  },
                ],
              },

              {
                name: "Gromet with Below raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                disableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment2",
                      "FlipUp@work",
                      "connecter",

                      "midscreen",
                      "mainscreen",
                    ],
                  },
                  {
                    type: show,
                    node: [
                      "gorment",
                      "Table_top",
                      "mid_leg_gorment",
                      "mid_leg",
                    ],
                  },

                  {
                    type: hide,
                    node: [
                      "prelammid",
                      "prelammid@work",
                      "metalmodestymid",
                      "metalmodestymid@work",
                      "connecter",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment", "gorment2@work"],
                  },
                  // {
                  //   type: applyMaterial,
                  //   node: ["GORMET"],
                  //   condition: [
                  //     {
                  //       feature: "Laminate",
                  //       values: [
                  //         "Mangfall Beech",
                  //         "Acacia",
                  //         "Urban Teak",
                  //         "SILVER GREY DIFFUSE",
                  //         "Cairo Walnut BaseColor",
                  //         "Maldau Acacia BaseColor",
                  //         "plain DefaultMaterial BaseColor",
                  //         "Thansau Maple BaseColor",
                  //       ],
                  //     },
                  //   ],
                  //   textureUrl:
                  //     "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
                  // },

                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment@work"],
                  },
                ],
              },
              {
                name: "Gromet With above raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",

                enableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "FlipUp@work",
                      "gorment@work",
                      "mid_leg_gorment",
                      "mainscreen",
                    ],
                  },
                  {
                    type: show,
                    node: ["gorment2", "connecter", "Table_top"],
                  },
                  {
                    type: show,
                    node: ["mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: [
                      "gorment2",
                      "Flipup@work",
                      "gorment@work",
                      "connecter",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment2@work"],
                  },
                ],
              },
              {
                name: "Gromet With single raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",

                enableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "FlipUp@work",
                      "gorment@work",
                      "mid_leg_gorment",
                      "mainscreen",
                      "gorment2",
                      "single",
                    ],
                  },
                  {
                    type: show,
                    node: ["single", "connecter", "Table_top"],
                  },
                ],
              },
            ],
          },
          // {
          //   name: "Dimensions",
          //   multiSelect: true,
          //   options: [
          //     {
          //       name: " 300 x 145mm ",
          //       required: false,
          //     },
          //     {
          //       name: " 400 x 145mm ",
          //       required: false,
          //     },
          //     {
          //       name: "450 x 145mm",
          //       required: false,
          //     },
          //     {
          //       name: "500 x 145mm",
          //       required: false,
          //     },
          //   ],
          // },
        ],
      },

      {
        name: "NON SHARING",
        required: false,

        options: [
          {
            name: "Raceway",
            required: false,

            thumbnail: "/assets/components/ColorsSharing.png",

            rules: [
              {
                type: hide,
                node: ["FlipUp", "gorment", "Raceway", "midscreen"],
              },
            ],
            options: [
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                default: true,
                enableOptions: ["Mid Prelam", "Mid Metal"],

                rules: [
                  {
                    type: show,

                    node: ["FlipUp", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,

                    node: [
                      "Table_top",
                      "gorment2",
                      "gorment",
                      "gorment@work",
                      "mid_leg_gorment",
                      "gorment2@work",
                      "midscreen",
                      "mainscreen",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Eco", "Noah", "Scope"],
                      },
                    ],
                    node: [
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "gorment@work",
                      "gorment2@work",
                    ],
                  },

                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["FlipUp", "gorment2@work", "connecter"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["FlipUp@work"],
                  },
                ],
              },

              {
                name: "Gromet with Below raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                disableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment2",
                      "FlipUp@work",
                      "connecter",

                      "midscreen",
                      "mainscreen",
                    ],
                  },
                  {
                    type: show,
                    node: [
                      "gorment",
                      "Table_top",
                      "mid_leg_gorment",
                      "mid_leg",
                    ],
                  },

                  {
                    type: hide,
                    node: [
                      "prelammid",
                      "prelammid@work",
                      "metalmodestymid",
                      "metalmodestymid@work",
                      "connecter",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment", "gorment2@work"],
                  },
                  // {
                  //   type: applyMaterial,
                  //   node: ["GORMET"],
                  //   condition: [
                  //     {
                  //       feature: "Laminate",
                  //       values: [
                  //         "Mangfall Beech",
                  //         "Acacia",
                  //         "Urban Teak",
                  //         "SILVER GREY DIFFUSE",
                  //         "Cairo Walnut BaseColor",
                  //         "Maldau Acacia BaseColor",
                  //         "plain DefaultMaterial BaseColor",
                  //         "Thansau Maple BaseColor",
                  //       ],
                  //     },
                  //   ],
                  //   textureUrl:
                  //     "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
                  // },

                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment@work"],
                  },
                ],
              },
              {
                name: "Gromet With above raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",

                enableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "FlipUp@work",
                      "gorment@work",
                      "mid_leg_gorment",
                      "mainscreen",
                    ],
                  },
                  {
                    type: show,
                    node: ["gorment2", "connecter", "Table_top"],
                  },
                  {
                    type: show,
                    node: ["mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: [
                      "gorment2",
                      "Flipup@work",
                      "gorment@work",
                      "connecter",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment2@work"],
                  },
                ],
              },
              {
                name: "Gromet With single raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",

                enableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "FlipUp@work",
                      "gorment@work",
                      "mid_leg_gorment",
                      "mainscreen",
                      "gorment2",
                      "single",
                    ],
                  },
                  {
                    type: show,
                    node: ["single", "connecter", "Table_top"],
                  },
                ],
              },
            ],
          },
          // {
          //   name: "Dimensions",
          //   multiSelect: true,
          //   options: [
          //     {
          //       name: " 300 x 145mm ",
          //       required: false,
          //     },
          //     {
          //       name: " 400 x 145mm ",
          //       required: false,
          //     },
          //     {
          //       name: "450 x 145mm",
          //       required: false,
          //     },
          //     {
          //       name: "500 x 145mm",
          //       required: false,
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
  // {
  //   name: "Prelam leg laminate",
  //   multiSelect: false,
  //   optional: false,
  //   options: [
  //     {
  //       name: "SHARING",
  //       required: false,
  //       options: [
  //         {
  //           name: "Mangfall Beech",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Acacia",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Urban Teak",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "SILVER GREY DIFFUSE",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Off White diffuse",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Graphite Grey diffuse",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Cairo Walnut BaseColor",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Maldau Acacia BaseColor",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Fusion Maple",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Thansau Maple BaseColor",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "NON SHARING",
  //       required: false,
  //       options: [
  //         {
  //           name: "Mangfall Beech",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Acacia",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Urban Teak",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "SILVER GREY DIFFUSE",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Off White diffuse",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Graphite Grey diffuse",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Cairo Walnut BaseColor",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Maldau Acacia BaseColor",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Fusion Maple",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Thansau Maple BaseColor",
  //           thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
  //           rules: [
  //             {
  //               type: "applyMaterial",
  //               node: "Wood1",
  //               textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    name: "Intermediate Panel",
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Middle Panel",
            required: false,
            options: [
              {
                name: "None",
                required: false,

                disableFeatures: [
                  "Wood Option",
                  "Return  Pannel Dimension",
                  "Metal Option",
                  "Return Frame Pannel Dimension",
                ],
                rules: [
                  {
                    type: hide,
                    node: [
                      "returnglass001",
                      "returnfabric001",
                      "wooden_return001",
                      "returnpanelsmetal001",
                      "alumglass_right001",
                      "alumfullfabric_right001",
                      "alumfulllaminate_right001",
                      "returnpanelsmetal001",
                      "midscreenmetal",
                      "midscreenwood",
                      "mid_leg"
                    ],
                  },
                ],
              },
              {
                name: "29mm Return Frame",
                required: false,
                enableFeatures: [
                  "Metal Option",
                  "Return Frame Pannel Dimension",
                ],
                disableFeatures: ["Wood Option", "Return  Pannel Dimension", "ATT"],
                rules: [
                  {
                    type: show,
                    node: [
                      "returnpanelsmetal001",
                      "alumglass_right001",
                      "midscreenwood",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "returnglass001",
                      "returnfabric001",
                      "wooden_return001",
                      "mid_leg",
                    ],
                  },
                ],
              },
              {
                name: "50mm Return Frame",
                required: false,
                enableFeatures: [
                  "Metal Option",
                  "Return Frame Pannel Dimension",
                ],
                disableFeatures: ["Wood Option", "Return  Pannel Dimension", "ATT"],
                rules: [
                  {
                    type: show,
                    node: [
                      "returnpanelsmetal001",
                      "alumglass_right001",
                      "midscreenwood",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "returnglass001",
                      "returnfabric001",
                      "wooden_return001",
                      "mid_leg",
                    ],
                  },
                ],
              },
              {
                name: "Prelam",
                required: false,
                disableFeatures: [
                  "Metal Option",
                  "Wood Option",
                  "Return Frame Pannel Dimension",
                ],
                enableFeatures: ["Intermediate Panels (S)"],
                rules: [
                  {
                    type: "replacePart",
                    node: [
                      "mid_leg",
                      "mid_leg1",
                      ,
                      "mid_leg10",
                      "mid_leg11",
                      "mid_leg12",
                      "mid_leg13",
                      "mid_leg14",
                      "mid_leg15",
                      "mid_leg16",
                      "mid_leg17",
                      "mid_leg18",
                      "mid_leg19",
                    ],
                    pid: "https://content.helloviewer.io/uploads/0a8e3cf8-61f8-4167-afdc-a868e0ed01f0/25_6_2024/1721913216694/midleggamble.glb",
                  },
                  {
                    type: show,
                    node: [ "mid_leg"],    //"returnfabric001"
                  },
                  // {
                  //   type: hide,
                  //   node: [
                  //     "returnglass001",
                  //     "returnpanelsmetal001",
                  //     "wooden_return001",
                  //     "alumglass_right001",
                  //     "midscreenmetal",
                  //     "midscreenmetal",
                  //     "midscreenwood",
                  //   ],
                  // },
                ],
              },
              {
                name: "Prelam with On Desk Glass",
                required: false,
                disableFeatures: [
                  "Metal Option",
                  "Wood Option",
                  "Return Frame Pannel Dimension",
                ],
                enableFeatures: ["Intermediate Panels (S)"],
                rules: [
                  {
                    type: "replacePart",
                    node: [
                      "mid_leg",
                      "mid_leg1",
                      ,
                      "mid_leg10",
                      "mid_leg11",
                      "mid_leg12",
                      "mid_leg13",
                      "mid_leg14",
                      "mid_leg15",
                      "mid_leg16",
                      "mid_leg17",
                      "mid_leg18",
                      "mid_leg19",
                    ],
                    pid: "https://content.helloviewer.io/uploads/0a8e3cf8-61f8-4167-afdc-a868e0ed01f0/25_6_2024/1721913216694/midleggamble.glb",
                  },
                  {
                    type: show,
                    node: [ "mid_leg", "returnfabric001"],    //"returnfabric001"
                  },
                  {
                    type: hide,
                    node: [
                      "returnglass001",
                      "returnpanelsmetal001",
                      "wooden_return001",
                      "alumglass_right001",
                      "midscreenmetal",
                      "midscreenmetal",
                      "midscreenwood",
                    ],
                  },
                ],
              },
              {
                name: "Prelam with On Desk Fabric",
                required: false,
                disableFeatures: [
                  "Metal Option",
                  "Wood Option",
                  "Return Frame Pannel Dimension",
                ],
                enableFeatures: ["Intermediate Panels (S)"],
                rules: [
                  {
                    type: "replacePart",
                    node: [
                      "mid_leg",
                      "mid_leg1",
                      ,
                      "mid_leg10",
                      "mid_leg11",
                      "mid_leg12",
                      "mid_leg13",
                      "mid_leg14",
                      "mid_leg15",
                      "mid_leg16",
                      "mid_leg17",
                      "mid_leg18",
                      "mid_leg19",
                    ],
                    pid: "https://content.helloviewer.io/uploads/0a8e3cf8-61f8-4167-afdc-a868e0ed01f0/25_6_2024/1721913216694/midleggamble.glb",
                  },
                  {
                    type: show,
                    node: [ "mid_leg", "returnglass001"],    //"returnfabric001"
                  },
                  {
                    type: hide,
                    node: [
                      "returnfabric001",
                      "returnpanelsmetal001",
                      "wooden_return001",
                      "alumglass_right001",
                      "midscreenmetal",
                      "midscreenmetal",
                      "midscreenwood",
                    ],
                  },
                ],
              },
              // {
              //   name: "On Desk Fabric Magnetic",
              //   required: false,
              //   disableFeatures: [
              //     "Metal Option",
              //     "Wood Option",
              //     "Return Frame Pannel Dimension",
              //   ],
              //   enableFeatures: ["Return  Pannel Dimension"],
              //   rules: [
              //     {
              //       type: show,
              //       node: ["returnfabric001", "mid_leg"],
              //     },
              //     {
              //       type: hide,
              //       node: [
              //         "returnglass001",
              //         "returnpanelsmetal001",
              //         "wooden_return001",
              //         "alumglass_right001",
              //         "midscreenmetal",
              //         "midscreenmetal",
              //         "midscreenwood",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "On Desk Glass",
              //   required: false,
              //   disableFeatures: [
              //     "Metal Option",
              //     "Wood Option",
              //     "Return Frame Pannel Dimension",
              //   ],
              //   enableFeatures: ["Return  Pannel Dimension"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: [
              //         "mid_leg",
              //         "mid_leg1",
              //         ,
              //         "mid_leg10",
              //         "mid_leg11",
              //         "mid_leg12",
              //         "mid_leg13",
              //         "mid_leg14",
              //         "mid_leg15",
              //         "mid_leg16",
              //         "mid_leg17",
              //         "mid_leg18",
              //         "mid_leg19",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/0a8e3cf8-61f8-4167-afdc-a868e0ed01f0/25_6_2024/1721913216694/midleggamble.glb",
              //     },
              //     {
              //       type: show,
              //       node: ["returnglass001", "mid_leg"],
              //     },
              //     {
              //       type: hide,
              //       node: [
              //         "returnfabric001",
              //         "returnpanelsmetal001",
              //         "wooden_return001",
              //         "alumglass_right001",
              //         "midscreenmetal",
              //         "midscreenmetal",
              //         "midscreenwood",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "Wood",
              //   required: false,
              //   disableFeatures: [
              //     "Metal Option",
              //     "Return  Pannel Dimension",
              //     "Return Frame Pannel Dimension",
              //   ],
              //   enableFeatures: ["Wood Option"],
              //   rules: [
              //     {
              //       type: show,
              //       node: ["wooden_return001"],
              //     },
              //     {
              //       type: hide,
              //       node: [
              //         "returnglass001",
              //         "returnfabric001",
              //         "returnpanelsmetal001",
              //         "alumglass_right001",
              //         "mid_leg",
              //         "midscreenmetal",
              //         "midscreenwood",
              //       ],
              //     },
              //   ],
              // },
            ],
          },
          // {
          //   name: "Return PaneL",
          //   required: false,
          //   options: [
          //     {
          //       name: "None",
          //       required: false,

          //       disableFeatures: [
          //         "Wood Option",
          //         "Return  Pannel Dimension",
          //         "Metal Option",
          //         "Return Frame Pannel Dimension",
          //       ],
          //       rules: [
          //         {
          //           type: hide,
          //           node: [
          //             "returnglass001",
          //             "returnfabric001",
          //             "wooden_return001",
          //             "returnpanelsmetal001",
          //             "alumglass_right001",
          //             "alumfullfabric_right001",
          //             "alumfulllaminate_right001",
          //             "returnpanelsmetal001",
          //             "midscreenmetal",
          //             "midscreenwood",
          //             "mid_leg"
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "On Desk Fabric",
          //       required: false,
          //       disableFeatures: [
          //         "Metal Option",
          //         "Wood Option",
          //         "Return Frame Pannel Dimension",
          //       ],
          //       enableFeatures: ["Return  Pannel Dimension"],
          //       rules: [
          //         {
          //           type: show,
          //           node: [ "returnfabric001"],    //"returnfabric001"
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "returnglass001",
          //             "returnpanelsmetal001",
          //             "wooden_return001",
          //             "alumglass_right001",
          //             "midscreenmetal",
          //             "midscreenmetal",
          //             "midscreenwood",
          //           ],
          //         },
          //       ],
          //     },
          //     // {
          //     //   name: "On Desk Fabric Magnetic",
          //     //   required: false,
          //     //   disableFeatures: [
          //     //     "Metal Option",
          //     //     "Wood Option",
          //     //     "Return Frame Pannel Dimension",
          //     //   ],
          //     //   enableFeatures: ["Return  Pannel Dimension"],
          //     //   rules: [
          //     //     {
          //     //       type: show,
          //     //       node: ["returnfabric001"],
          //     //     },
          //     //     // {
          //     //     //   type: hide,
          //     //     //   node: [
          //     //     //     "returnglass001",
          //     //     //     "returnpanelsmetal001",
          //     //     //     "wooden_return001",
          //     //     //     "alumglass_right001",
          //     //     //     "midscreenmetal",
          //     //     //     "midscreenmetal",
          //     //     //     "midscreenwood",
          //     //     //   ],
          //     //     // },
          //     //   ],
          //     // },
          //     {
          //       name: "On Desk Glass",
          //       required: false,
          //       disableFeatures: [
          //         "Metal Option",
          //         "Wood Option",
          //         "Return Frame Pannel Dimension",
          //       ],
          //       enableFeatures: ["Return  Pannel Dimension"],
          //       rules: [
          //         {
          //           type: show,
          //           node: ["returnglass001"],
          //         },
          //         // {
          //         //   type: hide,
          //         //   node: [
          //         //     "returnfabric001",
          //         //     "returnpanelsmetal001",
          //         //     "wooden_return001",
          //         //     "alumglass_right001",
          //         //     "midscreenmetal",
          //         //     "midscreenmetal",
          //         //     "midscreenwood",
          //         //   ],
          //         // },
          //       ],
          //     },
              
          //   ],
          // },


        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          // {
          //   name: "Return Panel",
          //   required: false,
          //   options: [
          //     {
          //       name: "None",
          //       required: false,

          //       disableFeatures: [
          //         "Wood Option",
          //         "Return  Pannel Dimension",
          //         "Metal Option",
          //         "Return Frame Pannel Dimension",
          //       ],
          //       rules: [
          //         {
          //           type: hide,
          //           node: [
          //             "returnglass",
          //             "returnfabric",
          //             "wooden_return",
          //             "returnpanelsmetal",
          //             "alumglass_right",
          //             "alumfullfabric_right",
          //             "alumfulllaminate_right",
          //             "returnpanelsmetal",
          //             "sidescreenwood",
          //             "sidescreenmetal",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Return Frame",
          //       required: false,
          //       enableFeatures: [
          //         "Metal Option",
          //         "Return Frame Pannel Dimension",
          //       ],
          //       disableFeatures: ["Wood Option", "Return  Pannel Dimension"],
          //       rules: [
          //         {
          //           type: show,
          //           node: [
          //             "returnpanelsmetal",
          //             "alumglass_right",
          //             "sidescreenwood",
          //           ],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "returnglass",
          //             "returnfabric",
          //             "wooden_return",
          //             "left_leg",
          //             "right_leg",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "On Desk Fabric",
          //       required: false,
          //       disableFeatures: [
          //         "Metal Option",
          //         "Wood Option",
          //         "Return Frame Pannel Dimension",
          //       ],
          //       enableFeatures: ["Return  Pannel Dimension"],
          //       rules: [
          //         {
          //           type: show,
          //           node: ["returnfabric", "left_leg", "right_leg"],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "returnglass",
          //             "returnpanelsmetal",
          //             "wooden_return",
          //             "alumglass_right",
          //             "sidescreenmetal",
          //             "sidescreenwood",
          //             "sidescreenmetal",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "On Desk Fabric Magnetic ",
          //       required: false,
          //       disableFeatures: [
          //         "Metal Option",
          //         "Wood Option",
          //         "Return Frame Pannel Dimension",
          //       ],
          //       enableFeatures: ["Return  Pannel Dimension"],
          //       rules: [
          //         {
          //           type: show,
          //           node: ["returnfabric", "left_leg", "right_leg"],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "returnglass",
          //             "returnpanelsmetal",
          //             "wooden_return",
          //             "alumglass_right",
          //             "sidescreenmetal",
          //             "sidescreenwood",
          //             "sidescreenmetal",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "On Desk Glass",
          //       required: false,
          //       disableFeatures: [
          //         "Metal Option",
          //         "Wood Option",
          //         "Return Frame Pannel Dimension",
          //       ],
          //       enableFeatures: ["Return  Pannel Dimension"],
          //       rules: [
          //         {
          //           type: show,
          //           node: ["returnglass", "left_leg", "right_leg"],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "returnfabric",
          //             "returnpanelsmetal",
          //             "wooden_return",
          //             "alumglass_right",
          //             "sidescreenmetal",
          //             "sidescreenwood",
          //             "sidescreenmetal",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Wood",
          //       required: false,
          //       disableFeatures: [
          //         "Metal Option",
          //         "Return  Pannel Dimension",
          //         "Return Frame Pannel Dimension",
          //       ],
          //       enableFeatures: ["Wood Option"],
          //       rules: [
          //         {
          //           type: show,
          //           node: ["wooden_return"],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "returnglass",
          //             "returnfabric",
          //             "returnpanelsmetal",
          //             "alumglass_right",
          //             "left_leg",
          //             "right_leg",
          //             "sidescreenmetal",
          //             "sidescreenwood",
          //             "sidescreenmetal",
          //           ],
          //         },
          //       ],
          //     },
          //   ],
          // },
          {
            name: "Middle Panel",
            required: false,
            options: [
              {
                name: "None",
                required: false,

                disableFeatures: [
                  "Wood Option",
                  "Return  Pannel Dimension",
                  "Metal Option",
                  "Return Frame Pannel Dimension",
                ],
                rules: [
                  {
                    type: hide,
                    node: [
                      "returnglass001",
                      "returnfabric001",
                      "wooden_return001",
                      "returnpanelsmetal001",
                      "alumglass_right001",
                      "alumfullfabric_right001",
                      "alumfulllaminate_right001",
                      "returnpanelsmetal001",
                      "midscreenmetal",
                      "midscreenwood",
                    ],
                  },
                ],
              },
              {
                name: "29mm Return Frame",
                required: false,
                enableFeatures: [
                  "Metal Option",
                  "Return Frame Pannel Dimension",
                ],
                disableFeatures: ["Wood Option", "Return  Pannel Dimension"],
                rules: [
                  {
                    type: show,
                    node: [
                      "returnpanelsmetal001",
                      "alumglass_right001",
                      "midscreenwood",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "returnglass001",
                      "returnfabric001",
                      "wooden_return001",
                      "mid_leg",
                    ],
                  },
                ],
              },
              {
                name: "50mm Return Frame",
                required: false,
                enableFeatures: [
                  "Metal Option",
                  "Return Frame Pannel Dimension",
                ],
                disableFeatures: ["Wood Option", "Return  Pannel Dimension"],
                rules: [
                  {
                    type: show,
                    node: [
                      "returnpanelsmetal001",
                      "alumglass_right001",
                      "midscreenwood",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "returnglass001",
                      "returnfabric001",
                      "wooden_return001",
                      "mid_leg",
                    ],
                  },
                ],
              },
              {
                name: "Prelam",
                required: false,
                disableFeatures: [
                  "Metal Option",
                  "Wood Option",
                  "Return Frame Pannel Dimension",
                ],
                enableFeatures: ["Return  Pannel Dimension"],
                rules: [
                  {
                    type: "replacePart",
                    node: [
                      "mid_leg",
                      "mid_leg1",
                      ,
                      "mid_leg10",
                      "mid_leg11",
                      "mid_leg12",
                      "mid_leg13",
                      "mid_leg14",
                      "mid_leg15",
                      "mid_leg16",
                      "mid_leg17",
                      "mid_leg18",
                      "mid_leg19",
                    ],
                    pid: "https://content.helloviewer.io/uploads/59663df0-1dce-4732-a5b3-fc28eb55280f/26_6_2024/1721979062801/gablenonsahringmid.glb",
                  },
                  {
                    type: show,
                    node: [ "mid_leg"],
                  },
                  {
                    type: hide,
                    node: [
                      "returnglass001",
                      "returnpanelsmetal001",
                      "wooden_return001",
                      "alumglass_right001",
                      "midscreenmetal",
                      "midscreenmetal",
                      "midscreenwood",
                    ],
                  },
                ],
              },
              {
                name: "Prelam with On Desk Glass",
                required: false,
                disableFeatures: [
                  "Metal Option",
                  "Wood Option",
                  "Return Frame Pannel Dimension",
                ],
                enableFeatures: ["Return  Pannel Dimension"],
                rules: [
                  {
                    type: "replacePart",
                    node: [
                      "mid_leg",
                      "mid_leg1",
                      ,
                      "mid_leg10",
                      "mid_leg11",
                      "mid_leg12",
                      "mid_leg13",
                      "mid_leg14",
                      "mid_leg15",
                      "mid_leg16",
                      "mid_leg17",
                      "mid_leg18",
                      "mid_leg19",
                    ],
                    pid: "https://content.helloviewer.io/uploads/59663df0-1dce-4732-a5b3-fc28eb55280f/26_6_2024/1721979062801/gablenonsahringmid.glb",
                  },
                  {
                    type: show,
                    node: ["returnfabric001", "mid_leg"],
                  },
                  {
                    type: hide,
                    node: [
                      "returnglass001",
                      "returnpanelsmetal001",
                      "wooden_return001",
                      "alumglass_right001",
                      "midscreenmetal",
                      "midscreenmetal",
                      "midscreenwood",
                    ],
                  },
                ],
              },
              // {
              //   name: "On Desk Fabric Magnetic",
              //   required: false,
              //   disableFeatures: [
              //     "Metal Option",
              //     "Wood Option",
              //     "Return Frame Pannel Dimension",
              //   ],
              //   enableFeatures: ["Return  Pannel Dimension"],
              //   rules: [
              //     {
              //       type: show,
              //       node: ["returnfabric001", "mid_leg"],
              //     },
              //     {
              //       type: hide,
              //       node: [
              //         "returnglass001",
              //         "returnpanelsmetal001",
              //         "wooden_return001",
              //         "alumglass_right001",
              //         "midscreenmetal",
              //         "midscreenmetal",
              //         "midscreenwood",
              //       ],
              //     },
              //   ],
              // },
              {
                name: "Prelam with On Desk Fabric",
                required: false,
                disableFeatures: [
                  "Metal Option",
                  "Wood Option",
                  "Return Frame Pannel Dimension",
                ],
                enableFeatures: ["Return  Pannel Dimension"],
                rules: [
                  {
                    type: "replacePart",
                    node: [
                      "mid_leg",
                      "mid_leg1",
                      ,
                      "mid_leg10",
                      "mid_leg11",
                      "mid_leg12",
                      "mid_leg13",
                      "mid_leg14",
                      "mid_leg15",
                      "mid_leg16",
                      "mid_leg17",
                      "mid_leg18",
                      "mid_leg19",
                    ],
                    pid: "https://content.helloviewer.io/uploads/59663df0-1dce-4732-a5b3-fc28eb55280f/26_6_2024/1721979062801/gablenonsahringmid.glb",
                  },
                  {
                    type: show,
                    node: ["returnglass001", "mid_leg"],
                  },
                  {
                    type: hide,
                    node: [
                      "returnfabric001",
                      "returnpanelsmetal001",
                      "wooden_return001",
                      "alumglass_right001",
                      "midscreenmetal",
                      "midscreenmetal",
                      "midscreenwood",
                    ],
                  },
                ],
              },
              // {
              //   name: "Wood",
              //   required: false,
              //   disableFeatures: [
              //     "Metal Option",
              //     "Return  Pannel Dimension",
              //     "Return Frame Pannel Dimension",
              //   ],
              //   enableFeatures: ["Wood Option"],
              //   rules: [
              //     {
              //       type: show,
              //       node: ["wooden_return001"],
              //     },
              //     {
              //       type: hide,
              //       node: [
              //         "returnglass001",
              //         "returnfabric001",
              //         "returnpanelsmetal001",
              //         "alumglass_right001",
              //         "mid_leg",
              //         "midscreenmetal",
              //         "midscreenwood",
              //       ],
              //     },
              //   ],
              // },
            ],
          },
          // {
          //   name: "Return Frame Pannel Dimension",
          //   required: false,
          //   options: [
          //     {
          //       name: "29 mm",
          //       required: false,
          //       rules: [
          //         {
          //           type: show,
          //           node: ["returnpanelsmetal"],
          //         },
          //         {
          //           type: hide,
          //           node: ["returnpanelsmetal55"],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Middle Panel",
          //               values: "Return Frame",
          //             },
          //           ],
          //           node: ["returnpanelsmetals55"],
          //         },
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Middle Panel",
          //               values: "Return Frame",
          //             },
          //           ],
          //           node: ["returnpanelsmetal.001"],
          //         },
          //       ],
          //     },
          //     {
          //       name: "50 mm",
          //       required: false,
          //       rules: [
          //         {
          //           type: hide,
          //           node: ["returnpanelsmetal"],
          //         },
          //         {
          //           type: show,
          //           node: ["returnpanelsmetal55"],
          //         },
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Middle Panel",
          //               values: "Return Frame",
          //             },
          //           ],
          //           node: ["returnpanelsmetals55"],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Middle Panel",
          //               values: "Return Frame",
          //             },
          //           ],
          //           node: ["returnpanelsmetal.001"],
          //         },
          //       ],
          //     },
          //   ],
          // },
          // {
          //   name: "Return  Pannel Dimension",
          //   required: false,
          //   options: [
          //     {
          //       name: "150 mm",
          //       required: false,
          //     },
          //     {
          //       name: "300 mm",
          //       required: false,
          //     },
          //     {
          //       name: "450 mm",
          //       required: false,
          //     },
          //   ],
          // },
          // {
          //   name: "Wood Option",
          //   options: [
          //     {
          //       name: "Mangfall Beech",
          //       thumbnail:
          //         "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg",
          //       rules: [
          //         {
          //           type: "applyMaterial",
          //           node: ["Wood2"],
          //           textureUrl:
          //             "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg",
          //         },
          //       ],
          //     },
          //     {
          //       name: "Acacia",
          //       thumbnail:
          //         "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg",
          //       rules: [
          //         {
          //           type: "applyMaterial",
          //           node: ["Wood2"],
          //           textureUrl:
          //             "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg",
          //         },
          //       ],
          //     },
          //     {
          //       name: "Urban Teak",
          //       thumbnail:
          //         "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg",
          //       rules: [
          //         {
          //           type: "applyMaterial",
          //           node: ["Wood2"],
          //           textureUrl:
          //             "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg",
          //         },
          //       ],
          //     },
          //     {
          //       name: "SILVER GREY DIFFUSE",
          //       thumbnail:
          //         "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
          //       rules: [
          //         {
          //           type: "applyMaterial",
          //           node: ["Wood2"],
          //           textureUrl:
          //             "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
          //         },
          //       ],
          //     },
          //     {
          //       name: "Off White diffuse",
          //       thumbnail:
          //         "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
          //       rules: [
          //         {
          //           type: "applyMaterial",
          //           node: ["Wood2"],
          //           textureUrl:
          //             "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
          //         },
          //       ],
          //     },
          //     {
          //       name: "Graphite Grey diffuse",
          //       thumbnail:
          //         "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
          //       rules: [
          //         {
          //           type: "applyMaterial",
          //           node: ["Wood2"],
          //           textureUrl:
          //             "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
          //         },
          //       ],
          //     },
          //     {
          //       name: "Cairo Walnut BaseColor",
          //       thumbnail:
          //         "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png",
          //       rules: [
          //         {
          //           type: "applyMaterial",
          //           node: ["Wood2"],
          //           textureUrl:
          //             "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png",
          //         },
          //       ],
          //     },
          //     {
          //       name: "Maldau Acacia BaseColor",
          //       thumbnail:
          //         "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png",
          //       rules: [
          //         {
          //           type: "applyMaterial",
          //           node: ["Wood2"],
          //           textureUrl:
          //             "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png",
          //         },
          //       ],
          //     },
          //     {
          //       name: "plain DefaultMaterial BaseColor",
          //       thumbnail:
          //         "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png",
          //       rules: [
          //         {
          //           type: "applyMaterial",
          //           node: ["Wood2"],
          //           textureUrl:
          //             "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png",
          //         },
          //       ],
          //     },
          //     {
          //       name: "Thansau Maple BaseColor",
          //       thumbnail:
          //         "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png",
          //       rules: [
          //         {
          //           type: "applyMaterial",
          //           node: ["Wood2"],
          //           textureUrl:
          //             "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png",
          //         },
          //       ],
          //     },
          //   ],
          // },
          // {
          //   name: "Metal Option",
          //   required: false,
          //   options: [
          //     {
          //       name: "Glass",
          //       rules: [
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Return Panels",
          //               values: ["Metal"],
          //             },
          //           ],
          //           node: ["alumglass_right"],
          //         },
          //         {
          //           type: hide,
          //           node: ["alumfulllaminate_right", "alumfullfabric_right"],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Return Panels",
          //               values: ["Wood", "On Desk Fabric", "On Desk Glass"],
          //             },
          //           ],
          //           node: [
          //             "alumglass_right",
          //             "alumfulllaminate_right",
          //             "alumfullfabric_right",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Laminate",
          //       rules: [
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Return Panels",
          //               values: ["Metal"],
          //             },
          //           ],
          //           node: ["alumfulllaminate_right"],
          //         },
          //         {
          //           type: hide,
          //           node: ["alumglass_right", "alumfullfabric_right"],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Return Panels",
          //               values: ["Wood", "On Desk Fabric", "On Desk Glass"],
          //             },
          //           ],
          //           node: [
          //             "alumglass_right",
          //             "alumfulllaminate_right",
          //             "alumfullfabric_right",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Fabric",
          //       rules: [
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Return Panels",
          //               values: ["Metal"],
          //             },
          //           ],
          //           node: ["alumfullfabric_right"],
          //         },
          //         {
          //           type: hide,
          //           node: ["alumglass_right", "alumfulllaminate_right"],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Return Panels",
          //               values: ["Wood", "On Desk Fabric", "On Desk Glass"],
          //             },
          //           ],
          //           node: [
          //             "alumglass_right",
          //             "alumfulllaminate_right",
          //             "alumfullfabric_right",
          //           ],
          //         },
          //       ],
          //     },
          //   ],
          // },
          // {
          //   name: "Main Screens",
          //   required: false,
          //   options: [
          //     {
          //       name: "Fabric Magnetic",
          //       required: false,
          //       rules: [
          //         {
          //           type: show,
          //           node: ["alumfullfabric_mid"],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "glasshalfandhalflam_mid",
          //             "alumglass_mid",
          //             "alumfulllaminate_mid",
          //             "alumhalfandhalf_mid",
          //             "glasshalfandhalflam_fabric",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Only Fabric",
          //       required: false,
          //       rules: [
          //         {
          //           type: show,
          //           node: ["alumfullfabric_mid"],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "glasshalfandhalflam_mid",
          //             "alumhalfandhalf_mid",
          //             "alumglass_mid",
          //             "alumfulllaminate_mid",
          //             "glasshalfandhalflam_fabric",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Only Glass",
          //       required: false,
          //       rules: [
          //         {
          //           type: show,
          //           node: ["alumglass_mid"],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "glasshalfandhalflam_mid",
          //             "alumhalfandhalf_mid",
          //             "alumfulllaminate_mid",
          //             "alumfullfabric_mid",
          //             "glasshalfandhalflam_fabric",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Only Pinup",
          //       required: false,
          //       rules: [
          //         {
          //           type: show,
          //           node: ["alumfullfabric_mid"],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "glasshalfandhalflam_mid",
          //             "alumhalfandhalf_mid",
          //             "alumglass_mid",
          //             "alumfulllaminate_mid",
          //             "glasshalfandhalflam_fabric",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Only Liminate Marker",
          //       required: false,
          //       rules: [
          //         {
          //           type: show,
          //           node: ["alumfulllaminate_mid"],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "glasshalfandhalflam_mid",
          //             "alumhalfandhalf_mid",
          //             "alumglass_mid",
          //             "alumfullfabric_mid",
          //             "glasshalfandhalflam_fabric",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Only Magnetic Marker",
          //       required: false,
          //       rules: [
          //         {
          //           type: show,
          //           node: ["alumfulllaminate_mid"],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "glasshalfandhalflam_mid",
          //             "alumhalfandhalf_mid",
          //             "alumglass_mid",
          //             "alumfullfabric_mid",
          //             "glasshalfandhalflam_fabric",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Fabric+Fabric Pinup",
          //       required: false,
          //       rules: [
          //         {
          //           type: show,
          //           node: ["alumfullfabric_mid"],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "glasshalfandhalflam_mid",
          //             "alumhalfandhalf_mid",
          //             "alumglass_mid",
          //             "alumfulllaminate_mid",
          //             "glasshalfandhalflam_fabric",
          //           ],
          //         },
          //       ],
          //     },

          //     {
          //       name: "Fabric+Laminate Marker",
          //       required: false,
          //       rules: [
          //         {
          //           type: show,
          //           node: ["alumhalfandhalf_mid"],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "glasshalfandhalflam_mid",
          //             "alumglass_mid",
          //             "alumfulllaminate_mid",
          //             "alumfullfabric_mid",
          //             "glasshalfandhalflam_fabric",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Half Glass+ Half Fabric ",
          //       required: false,
          //       rules: [
          //         {
          //           type: show,
          //           node: ["glasshalfandhalflam_fabric"],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "alumhalfandhalf_mid",
          //             "alumglass_mid",
          //             "alumfulllaminate_mid",
          //             "alumfullfabric_mid",
          //             "glasshalfandhalflam_mid",
          //           ],
          //         },
          //       ],
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
  // {
  //   name: "Below Table TableTop Tile",
  //   optional: false,
  //   options: [
  //     {
  //       name: "SHARING",
  //       required: false,

  //       options: [
  //         {
  //           name: "Below Table TableTop Tile",
  //           optional: false,
  //           options: [
  //             {
  //               name: "Metal",

  //               rules: [
  //                 {
  //                   type: show,
  //                   node: "mainscreenmetal",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: "mainscreenwood",
  //                 },
  //                 {
  //                   type: show,
  //                   condition: [
  //                     {
  //                       feature: "Return Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["sidescreenmetal"],
  //                 },
  //                 {
  //                   type: hide,
  //                   condition: [
  //                     {
  //                       feature: "Return Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["sidescreenwood"],
  //                 },
  //                 {
  //                   type: show,
  //                   condition: [
  //                     {
  //                       feature: "Middle Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["midscreenmetal"],
  //                 },
  //                 {
  //                   type: hide,
  //                   condition: [
  //                     {
  //                       feature: "Middle Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["midscreenwood"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Wood",

  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: "mainscreenmetal",
  //                 },
  //                 {
  //                   type: show,
  //                   node: "mainscreenwood",
  //                 },
  //                 {
  //                   type: hide,
  //                   condition: [
  //                     {
  //                       feature: "Return Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["sidescreenmetal"],
  //                 },
  //                 {
  //                   type: show,
  //                   condition: [
  //                     {
  //                       feature: "Return Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["sidescreenwood"],
  //                 },
  //                 {
  //                   type: hide,
  //                   condition: [
  //                     {
  //                       feature: "Middle Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["midscreenmetal"],
  //                 },
  //                 {
  //                   type: show,
  //                   condition: [
  //                     {
  //                       feature: "Middle Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["midscreenwood"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Below Table TableTop Tile Colors",
  //           options: [
  //             {
  //               name: "Mangfall Beech",
  //               thumbnail:
  //                 "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Acacia",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "SILVER GREY DIFFUSE",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Off White diffuse",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Graphite Grey diffuse",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Cairo Walnut BaseColor",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Maldau Acacia BaseColor",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Fusion Maple",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple BaseColor",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },

  //     {
  //       name: "NON SHARING",
  //       required: false,

  //       options: [
  //         {
  //           name: "Below Table TableTop Tile",
  //           optional: false,
  //           options: [
  //             {
  //               name: "Metal",

  //               rules: [
  //                 {
  //                   type: show,
  //                   node: "mainscreenmetal",
  //                 },
  //                 {
  //                   type: hide,
  //                   node: "mainscreenwood",
  //                 },
  //                 {
  //                   type: show,
  //                   condition: [
  //                     {
  //                       feature: "Return Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["sidescreenmetal"],
  //                 },
  //                 {
  //                   type: hide,
  //                   condition: [
  //                     {
  //                       feature: "Return Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["sidescreenwood"],
  //                 },
  //                 {
  //                   type: show,
  //                   condition: [
  //                     {
  //                       feature: "Middle Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["midscreenmetal"],
  //                 },
  //                 {
  //                   type: hide,
  //                   condition: [
  //                     {
  //                       feature: "Middle Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["midscreenwood"],
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Wood",

  //               rules: [
  //                 {
  //                   type: hide,
  //                   node: "mainscreenmetal",
  //                 },
  //                 {
  //                   type: show,
  //                   node: "mainscreenwood",
  //                 },
  //                 {
  //                   type: hide,
  //                   condition: [
  //                     {
  //                       feature: "Return Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["sidescreenmetal"],
  //                 },
  //                 {
  //                   type: show,
  //                   condition: [
  //                     {
  //                       feature: "Return Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["sidescreenwood"],
  //                 },
  //                 {
  //                   type: hide,
  //                   condition: [
  //                     {
  //                       feature: "Middle Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["midscreenmetal"],
  //                 },
  //                 {
  //                   type: show,
  //                   condition: [
  //                     {
  //                       feature: "Middle Panel",
  //                       values: "Return Frame",
  //                     },
  //                   ],
  //                   node: ["midscreenwood"],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Below Table TableTop Tile Colors",
  //           options: [
  //             {
  //               name: "Mangfall Beech",
  //               thumbnail:
  //                 "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Acacia",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Urban Teak",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "SILVER GREY DIFFUSE",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Off White diffuse",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Graphite Grey diffuse",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Cairo Walnut BaseColor",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Maldau Acacia BaseColor",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Fusion Maple",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Thansau Maple BaseColor",
  //               thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "Wood_bottom",
  //                   textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   name: "Privacy Color Options",
  //   options: [
  //     {
  //       name: "SHARING",
  //       options: [
  //         {
  //           name: "Fabric's Color",
  //           options: [
  //             {
  //               name: "Tangerine",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Acid",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Acorn",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Amethyst",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Apollo",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Azure",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
  //                 },
  //               ],
  //             },

  //             {
  //               name: "Blush",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Brown",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Burnt Orange",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Corn Flower",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dolphin",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Magenta",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Med Blue",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
  //                 },
  //               ],
  //             },

  //             {
  //               name: "Pink",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Purple",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Raspberry",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Ruby",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Salmon",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sangria",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Shimmer",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
  //                 },
  //               ],
  //             },

  //             {
  //               name: "Electric",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Frengia",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Baby Blue",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Glass's Color",
  //           options: [
  //             {
  //               name: "SKY BLUE",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "SOLAR YELLOW",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "OCEAN GREEN",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "ORANGE MIST",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "FLAME RED",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "CASIS PURPLE",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "AZURE BLUE",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "APPLE GREEN",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "SUNNY ORANGE",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "SUPER WHITE",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "GREEN LIME",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "NON SHARING",
  //       options: [
  //         {
  //           name: "Fabric's Color",
  //           options: [
  //             {
  //               name: "Tangerine",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Acid",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Acorn",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Amethyst",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Apollo",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Azure",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
  //                 },
  //               ],
  //             },

  //             {
  //               name: "Blush",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Brown",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Burnt Orange",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Corn Flower",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Dolphin",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Magenta",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Med Blue",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
  //                 },
  //               ],
  //             },

  //             {
  //               name: "Pink",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Purple",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Raspberry",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Ruby",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Salmon",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Sangria",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Shimmer",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
  //                 },
  //               ],
  //             },

  //             {
  //               name: "Electric",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Frengia",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "Baby Blue",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: "FABRIC_return",
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           name: "Glass's Color",
  //           options: [
  //             {
  //               name: "SKY BLUE",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "SOLAR YELLOW",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "OCEAN GREEN",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "ORANGE MIST",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "FLAME RED",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "CASIS PURPLE",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "AZURE BLUE",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "APPLE GREEN",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "SUNNY ORANGE",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "SUPER WHITE",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
  //                 },
  //               ],
  //             },
  //             {
  //               name: "GREEN LIME",
  //               thumbnail:
  //                 "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
  //               rules: [
  //                 {
  //                   type: "applyMaterial",
  //                   node: ["GLASS_return"],
  //                   textureUrl:
  //                     "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood", "Wood1", "Wood2", "Wood_bottom"],
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Glass Color",
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "WHITE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["Glass", "GLASS"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "WHITE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["Glass", "GLASS"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fabric Color",
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Tangerine",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
              },
            ],
          },
          {
            name: "Acid",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
              },
            ],
          },
          {
            name: "Acorn",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
              },
            ],
          },
          {
            name: "Amethyst",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
              },
            ],
          },
          {
            name: "Apollo",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
              },
            ],
          },
          {
            name: "Azure",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
              },
            ],
          },

          {
            name: "Blush",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
              },
            ],
          },
          {
            name: "Brown",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
              },
            ],
          },
          {
            name: "Burnt Orange",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
              },
            ],
          },
          {
            name: "Corn Flower",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
              },
            ],
          },
          {
            name: "Magenta",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
              },
            ],
          },

          {
            name: "Pink",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
              },
            ],
          },
          {
            name: "Purple",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
              },
            ],
          },
          {
            name: "Raspberry",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
              },
            ],
          },
          {
            name: "Ruby",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
              },
            ],
          },
          {
            name: "Salmon",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
              },
            ],
          },
          {
            name: "Shimmer",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
              },
            ],
          },

          {
            name: "Electric",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
              },
            ],
          },
          {
            name: "Frengia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
              },
            ],
          },
          {
            name: "Baby Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Tangerine",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
              },
            ],
          },
          {
            name: "Acid",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
              },
            ],
          },
          {
            name: "Acorn",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
              },
            ],
          },
          {
            name: "Amethyst",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
              },
            ],
          },
          {
            name: "Apollo",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
              },
            ],
          },
          {
            name: "Azure",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
              },
            ],
          },

          {
            name: "Blush",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
              },
            ],
          },
          {
            name: "Brown",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
              },
            ],
          },
          {
            name: "Burnt Orange",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
              },
            ],
          },
          {
            name: "Corn Flower",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
              },
            ],
          },
          {
            name: "Magenta",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
              },
            ],
          },

          {
            name: "Pink",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
              },
            ],
          },
          {
            name: "Purple",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
              },
            ],
          },
          {
            name: "Raspberry",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
              },
            ],
          },
          {
            name: "Ruby",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
              },
            ],
          },
          {
            name: "Salmon",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
              },
            ],
          },
          {
            name: "Shimmer",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
              },
            ],
          },

          {
            name: "Electric",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
              },
            ],
          },
          {
            name: "Frengia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
              },
            ],
          },
          {
            name: "Baby Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["FABRIC", "GLASS_return"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Accessories",

    options: [
      {
        name: "SHARING",
        required: false,
        multiSelect: false,
        options: [
          {
            name: "Keyboard Trays (S)",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray", "Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard Tray",
                thumbnail: "assets/system/thumbnails/Parts/keyboardtray.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray"],
                  },
                  {
                    type: "show",
                    node: ["Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard and Mouse Tray",
                thumbnail: "assets/system/thumbnails/Parts/keyandmousetray.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key_board_tray"],
                  },
                  {
                    type: "show",
                    node: ["Key&mousetray"],
                  },
                ],
              },
            ],
          },
          {
            name: "CPU Stands (S)",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand", "CPU_stand"],
                  },
                ],
              },
              {
                name: "CPU Stand",
                thumbnail: "assets/system/thumbnails/Parts/cpustand.png",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand"],
                  },
                  {
                    type: "show",
                    node: ["CPU_stand"],
                  },
                ],
              },
              {
                name: "Hanging CPU Stand",
                thumbnail:
                  "assets/system/thumbnails/Parts/hangingcpustand .png",
                rules: [
                  {
                    type: "hide",
                    node: ["CPU_stand"],
                  },
                  {
                    type: "show",
                    node: ["hangingcpustand"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        multiSelect: false,
        options: [
          {
            name: "Keyboard Trays (NS)",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray", "Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard Tray",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray"],
                  },
                  {
                    type: "show",
                    node: ["Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard and Mouse Tray",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key_board_tray"],
                  },
                  {
                    type: "show",
                    node: ["Key&mousetray"],
                  },
                ],
              },
            ],
          },
          {
            name: "CPU Stands (NS)",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand", "CPU_stand"],
                  },
                ],
              },
              {
                name: "CPU Stand",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand"],
                  },
                  {
                    type: "show",
                    node: ["CPU_stand"],
                  },
                ],
              },
              {
                name: "Hanging CPU Stand",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["CPU_stand"],
                  },
                  {
                    type: "show",
                    node: ["hangingcpustand"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;